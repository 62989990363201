import React, { FunctionComponent } from "react";

import AddIcon from "@mui/icons-material/Add";
import CloudDownload from "@mui/icons-material/CloudDownload";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { useCookies } from "react-cookie";
import {
  formatNumber,
  getBloombergTaskUrlText,
  getDefaultRowsPerPage,
  getQueryVariablesBase,
  isAllowDataExport,
  isAllowEdit,
  isAllowImport,
  isFloat,
} from "../../utils/helper";
import {
  IComparableCompaniesTableData,
  IEditMode,
  IIntangibleAssetsTableData,
  IObjectColumn,
  IReactTableProps,
  ITableType,
  IValueDisplayMode,
} from "../../utils/interface";
import AlertDialog from "../AlertDialog";
import DataNotFound from "../DataNotFound";
import Form from "../Form";
import HeaderTabs from "../HeaderTabs";
import Loading from "../Loading";
import SearchBox from "../SearchBox";
import TableSelect from "../TableSelect";
import { View } from "../View";
import EnhancedTableHead from "./EnhancedTableHead";
import { useStyles } from "./style";

// EDIT
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  ComparableCompany,
  ComparableCompanyUpdateOneInput,
  GetLastUpdateDocument,
  GetManyComparableCompaniesDocument,
  GetManyComparablesDocument,
  GetManyIntangibleAssetsDocument,
  GetManyProprietariesDocument,
  GetManyResearchReportsDocument,
  GetManyResearchesDocument,
  GetManyRoyaltyRatesDocument,
  IntangibleAsset,
  IntangibleAssetUpdateOneInput,
  useCreateOneComparableMutation,
  useCreateOneProprietaryMutation,
  useCreateOneResearchMutation,
  useCreateOneResearchReportMutation,
  useCreateOneRoyaltyRateMutation,
  useDeleteOneComparableMutation,
  useDeleteOneProprietaryMutation,
  useDeleteOneResearchMutation,
  useDeleteOneResearchReportMutation,
  useDeleteOneRoyaltyRateMutation,
  useEffectiveInterestRatesLazyQuery,
  useExportManyBloombergBondYieldsLazyQuery,
  useExportManyBloombergCpiAveragesLazyQuery,
  useExportManyBloombergCpisLazyQuery,
  useExportManyBloombergExchangeRatesLazyQuery,
  useExportManyBloombergIndexesLendingRatesLazyQuery,
  useExportManyBloombergIndexesRiskFreeRatesLazyQuery,
  useGetLastUpdateQuery,
  useGetManyBloombergBondYieldsLazyQuery,
  useGetManyBloombergCpiAveragesLazyQuery,
  useGetManyBloombergCpisLazyQuery,
  useGetManyBloombergExchangeRatesLazyQuery,
  useGetManyBloombergIndexesLendingRatesLazyQuery,
  useGetManyBloombergIndexesRiskFreeRatesLazyQuery,
  useGetManyComparableCompaniesLazyQuery,
  useGetManyComparablesLazyQuery,
  useGetManyIntangibleAssetsLazyQuery,
  useGetManyProprietariesLazyQuery,
  useGetManyResearchReportsLazyQuery,
  useGetManyResearchesLazyQuery,
  useGetManyRoyaltyRatesLazyQuery,
  useIndustrialFactorsLazyQuery,
  useMarcoEconFactorsLazyQuery,
  useRecoveryRatesLazyQuery,
  useUpdateOneComparableMutation,
  useUpdateOneProprietaryMutation,
  useUpdateOneResearchMutation,
  useUpdateOneResearchReportMutation,
  useUpdateOneRoyaltyRateMutation,
  useUpsertAndDeleteManyComparableCompaniesMutation,
  useUpsertAndDeleteManyIntangibleAssetsMutation,
} from "../../generated/graphql";

import { Alert, Chip, CircularProgress, Link } from "@mui/material";
import { IndustryList } from "components/Form/industry-options";
import UploadFile from "components/Form/upload";
import { ExtraFilterSection } from "components/ReactTable/BloombergIndexExtraFilter";
import { ReactTableSnackbar } from "components/ReactTableSnackbar";
import moment from "moment";
import { SERVER, toUrlDate } from "utils/const";
import { CellShowMore } from "./CellShowMore";
import TableBodyContent from "./TableBodyContent";
import {
  useExportFile,
  useGetData,
  useInitEmptyColumnsValue,
  useLastUpdate,
  useLoading,
  useSetData,
  useSetSearchFields,
} from "./hooks";
import { reducer } from "./reducer";
import { defaultValuationDateFilters, initState } from "./reducer/init-state";

export const ReactTable: FunctionComponent<IReactTableProps> = ({ columns }) => {
  const classes = useStyles();
  const { loading: lastUpdateLoading, data: lastUpdatehData } = useGetLastUpdateQuery();

  // query

  const [getResearch, { loading: researchLoading, data: researchData }] = useGetManyResearchesLazyQuery();
  const [getComparable, { loading: comparableLoading, data: comparableData }] = useGetManyComparablesLazyQuery();
  const [getRoyaltyRate, { loading: royaltyRateLoading, data: royaltyRateData }] = useGetManyRoyaltyRatesLazyQuery();
  const [getProprietary, { loading: proprietaryLoading, data: proprietaryData }] = useGetManyProprietariesLazyQuery();
  const [getMarcoEconFactor, { loading: marcoEconFactorLoading, data: marcoEconFactorData }] =
    useMarcoEconFactorsLazyQuery();
  const [getIndustrialFactor, { loading: IndustrialFactorLoading, data: IndustrialFactorData }] =
    useIndustrialFactorsLazyQuery();
  const [getRecoveryRates, { loading: RecoveryRatesLoading, data: RecoveryRatesData }] = useRecoveryRatesLazyQuery();
  const [getEffectiveInterestRates, { loading: EffectiveInterestRatesLoading, data: EffectiveInterestRatesData }] =
    useEffectiveInterestRatesLazyQuery();
  const [
    getBloombergIndexesLendingRates,
    { loading: BloombergIndexesLendingRatesLoading, data: BloombergIndexesLendingRatesData },
  ] = useGetManyBloombergIndexesLendingRatesLazyQuery();
  const [
    getBloombergIndexesRiskFreeRates,
    { loading: BloombergIndexesRiskFreeRatesLoading, data: BloombergIndexesRiskFreeRatesData },
  ] = useGetManyBloombergIndexesRiskFreeRatesLazyQuery();
  const [getBloombergCpis, { loading: BloombergCpisLoading, data: BloombergCpisData }] =
    useGetManyBloombergCpisLazyQuery();
  const [getBloombergCpiAverages, { loading: BloombergCpiAveragesLoading, data: BloombergCpiAveragesData }] =
    useGetManyBloombergCpiAveragesLazyQuery();
  const [getBloombergExchangeRates, { loading: BloombergExchangeRatesLoading, data: BloombergExchangeRatesData }] =
    useGetManyBloombergExchangeRatesLazyQuery();
  const [getBloombergBondYields, { loading: BloombergBondYieldsLoading, data: BloombergBondYieldsData }] =
    useGetManyBloombergBondYieldsLazyQuery();
  const [getResearchReports, { loading: ResearchReportsLoading, data: ResearchReportsData }] =
    useGetManyResearchReportsLazyQuery();
  const [
    exportBloombergIndexesLendingRates,
    {
      loading: ExportBloombergIndexesLendingRatesLoading,
      data: ExportBloombergIndexesLendingRatesData,
      error: ExportBloombergIndexesLendingRatesError,
    },
  ] = useExportManyBloombergIndexesLendingRatesLazyQuery();
  const [
    exportBloombergIndexesRiskFreeRates,
    {
      loading: ExportBloombergIndexesRiskFreeRatesLoading,
      data: ExportBloombergIndexesRiskFreeRatesData,
      error: ExportBloombergIndexesRiskFreeRatesError,
    },
  ] = useExportManyBloombergIndexesRiskFreeRatesLazyQuery();
  const [
    exportBloombergCpis,
    { loading: ExportBloombergCpisLoading, data: ExportBloombergCpisData, error: ExportBloombergCpisError },
  ] = useExportManyBloombergCpisLazyQuery();

  const [
    exportBloombergCpiAverages,
    {
      loading: ExportBloombergCpiAveragesLoading,
      data: ExportBloombergCpiAveragesData,
      error: ExportBloombergCpiAveragesError,
    },
  ] = useExportManyBloombergCpiAveragesLazyQuery();
  const [
    exportBloombergExchangeRates,
    {
      loading: ExportBloombergExchangeRatesLoading,
      data: ExportBloombergExchangeRatesData,
      error: ExportBloombergExchangeRatesError,
    },
  ] = useExportManyBloombergExchangeRatesLazyQuery();
  const [
    exportBloombergBondYields,
    {
      loading: ExportBloombergBondYieldsLoading,
      data: ExportBloombergBondYieldsData,
      error: ExportBloombergBondYieldsError,
    },
  ] = useExportManyBloombergBondYieldsLazyQuery();

  const tableRef = React.useRef<HTMLDivElement>(null);
  const [tableType, setTableType] = React.useState<ITableType>(ITableType.CIRCULAR_RESEARCH);
  const searchRef = React.useRef<HTMLInputElement>(null);

  const tableTypeArray = Object.keys(ITableType).filter((key) => !isNaN(Number(ITableType[key])));
  const viewFieldsObj: IObjectColumn = {};
  const [cookies, setCookie] = useCookies([]);
  tableTypeArray.forEach((item) => (viewFieldsObj[item] = cookies[item]));
  const [viewFieldsArray, setViewFieldsArray] = React.useState<IObjectColumn>(viewFieldsObj);

  //reducer
  const [state, dispatch] = React.useReducer(reducer, initState);

  // hooks
  const lastUpdate = useLastUpdate({ lastUpdateLoading, lastUpdatehData });
  const loading = useLoading({
    researchLoading,
    comparableLoading,
    royaltyRateLoading,
    proprietaryLoading,
    marcoEconFactorLoading,
    IndustrialFactorLoading,
    RecoveryRatesLoading,
    EffectiveInterestRatesLoading,
    BloombergIndexesLendingRatesLoading,
    BloombergIndexesRiskFreeRatesLoading,
    BloombergCpisLoading,
    BloombergCpiAveragesLoading,
    BloombergExchangeRatesLoading,
    BloombergBondYieldsLoading,
  });
  useSetSearchFields({ columns, tableType, dispatch });
  useInitEmptyColumnsValue({ columns, tableType, dispatch });
  useGetData({
    table: state.table,
    selectedSearchField: state.search.selectedSearchField,
    tableType,
    researchData,
    getResearch,
    comparableData,
    getComparable,
    extraFilter: state.search.extraFilter,
    keyword: state.search.keyword,
    columns,
    getRoyaltyRate,
    getProprietary,
    getMarcoEconFactor,
    getRecoveryRates,
    getIndustrialFactor,
    getEffectiveInterestRates,
    getBloombergIndexesLendingRates,
    getBloombergIndexesRiskFreeRates,
    getBloombergCpis,
    getBloombergCpiAverages,
    getBloombergExchangeRates,
    getBloombergBondYields,
    getResearchReports,
  });
  useSetData({
    marcoEconFactorLoading,
    marcoEconFactorData,
    IndustrialFactorLoading,
    IndustrialFactorData,
    RecoveryRatesLoading,
    RecoveryRatesData,
    royaltyRateLoading,
    royaltyRateData,
    tableType,
    comparableLoading,
    comparableData,
    researchLoading,
    researchData,
    dispatch,
    proprietaryLoading,
    proprietaryData,
    EffectiveInterestRatesLoading,
    EffectiveInterestRatesData,
    BloombergIndexesLendingRatesLoading,
    BloombergIndexesLendingRatesData,
    BloombergIndexesRiskFreeRatesLoading,
    BloombergIndexesRiskFreeRatesData,
    BloombergCpisLoading,
    BloombergCpisData,
    BloombergCpiAveragesLoading,
    BloombergCpiAveragesData,
    BloombergExchangeRatesLoading,
    BloombergExchangeRatesData,
    BloombergBondYieldsLoading,
    BloombergBondYieldsData,
    ResearchReportsLoading,
    ResearchReportsData,
  });

  // proprietary ppa - companies
  const comparableCompaniesQueryVariables = {
    parentId: state.form.editId,
  };
  const comparableCompaniesRefetchQueries = [
    {
      query: GetManyComparableCompaniesDocument,
      variables: {
        ...comparableCompaniesQueryVariables,
      },
    },
  ];
  const intangibleAssetsQueryVariables = {
    parentId: state.form.editId,
  };
  const intangibleAssetsRefetchQueries = [
    {
      query: GetManyIntangibleAssetsDocument,
      variables: {
        ...intangibleAssetsQueryVariables,
      },
    },
  ];
  const [getComparableCompanies, { loading: comparableCompaniesLoading, data: comparableCompaniesData }] =
    useGetManyComparableCompaniesLazyQuery({
      fetchPolicy: "network-only",
    });
  const [getIntangibleAssets, { loading: intangibleAssetsListLoading, data: intangibleAssetsListData }] =
    useGetManyIntangibleAssetsLazyQuery({
      fetchPolicy: "network-only",
    });
  const [comparableCompanies, setComparableCompanies] = React.useState<ComparableCompanyUpdateOneInput[]>(null);
  const [intangibleAssetsList, setIntangibleAssetsList] = React.useState<IntangibleAssetUpdateOneInput[]>(null);
  const handleUpdateComparableCompanies = React.useCallback(
    (data: IComparableCompaniesTableData[]) => {
      const comparableCompaniesData: ComparableCompany[] = data.map((item) => {
        return {
          id: item.id,
          code: item.code,
          company: item.company,
          parentId: item.parentId,
        };
      });
      setComparableCompanies(comparableCompaniesData);
    },
    [setComparableCompanies]
  );
  const handleUpdateIntangibleAssetsList = React.useCallback(
    (data: IIntangibleAssetsTableData[]) => {
      const intangibleAssetsListData: IntangibleAsset[] = data.map((item) => {
        return {
          id: item.id,
          type: item.type,
          amount: Number(item.amount),
          rul: item.rul,
          parentId: item.parentId,
        };
      });
      setIntangibleAssetsList(intangibleAssetsListData);
    },
    [setIntangibleAssetsList]
  );
  const [formSubmit, setFormSubmit] = React.useState(false);

  // RefefetchQueries
  const refetchVariablesBase = getQueryVariablesBase({
    keyword: state.search.keyword,
    rowsPerPage: state.table.rowsPerPage,
    page: state.table.page,
    orderBy: state.table.orderBy,
    order: state.table.order,
    columnsData: columns[tableType].data,
    selectedFields: state.search.selectedSearchField,
    extraFilter: state.search.extraFilter,
  });

  const researchRefetchQueries = [
    {
      query: GetManyResearchesDocument,
      variables: {
        ...refetchVariablesBase,
        year: state.table.year,
      },
    },
    {
      query: GetLastUpdateDocument,
    },
  ];

  const comparableRefefetchQueries = [
    {
      query: GetManyComparablesDocument,
      variables: {
        ...refetchVariablesBase,
      },
    },
    {
      query: GetLastUpdateDocument,
    },
  ];

  const royaltyRefefetchQueries = [
    {
      query: GetManyRoyaltyRatesDocument,
      variables: {
        ...refetchVariablesBase,
      },
    },
    {
      query: GetLastUpdateDocument,
    },
  ];

  const proprietaryRefefetchQueries = [
    {
      query: GetManyProprietariesDocument,
      variables: {
        ...refetchVariablesBase,
      },
    },
    {
      query: GetLastUpdateDocument,
    },
  ];

  const researchReportRefefetchQueries = [
    {
      query: GetManyResearchReportsDocument,
      variables: {
        ...refetchVariablesBase,
      },
    },
    {
      query: GetLastUpdateDocument,
    },
  ];

  React.useEffect(
    function initComparableCompaniesData() {
      if (tableType === ITableType.PROPRIETARY_PPA) {
        let data: ComparableCompany[];
        if (
          !comparableCompaniesLoading &&
          comparableCompaniesData &&
          comparableCompaniesData.getManyComparableCompanies
        ) {
          data = comparableCompaniesData.getManyComparableCompanies.map((item) => {
            return {
              id: item.id,
              code: item.code,
              company: item.company,
              parentId: item.parentId,
            };
          });
          setComparableCompanies(data);
        }
      }
    },
    [tableType, comparableCompaniesLoading, comparableCompaniesData]
  );

  React.useEffect(
    function initIntangibleAssetsData() {
      if (tableType === ITableType.PROPRIETARY_PPA || tableType === ITableType.COMPARABLE_PPA) {
        let data: IntangibleAsset[];
        if (
          !intangibleAssetsListLoading &&
          intangibleAssetsListData &&
          intangibleAssetsListData.getManyIntangibleAssets
        ) {
          data = intangibleAssetsListData.getManyIntangibleAssets.map((item) => {
            return {
              id: item.id,
              type: item.type,
              amount: item.amount,
              rul: item.rul,
              parentId: item.parentId,
            };
          });
          setIntangibleAssetsList(data);
        }
      }
    },
    [tableType, intangibleAssetsListLoading, intangibleAssetsListData]
  );

  // RESEARCH
  const [deleteResearch] = useDeleteOneResearchMutation({
    refetchQueries: researchRefetchQueries,
    awaitRefetchQueries: true,
  });

  // COMPARABLE
  const [deleteComparable] = useDeleteOneComparableMutation({
    refetchQueries: comparableRefefetchQueries,
    awaitRefetchQueries: true,
  });

  // ROYALTY RATE
  const [deleteRoyaltyRate] = useDeleteOneRoyaltyRateMutation({
    refetchQueries: royaltyRefefetchQueries,
    awaitRefetchQueries: true,
  });

  // PROPRIETARY
  const [deleteProprietary] = useDeleteOneProprietaryMutation({
    refetchQueries: proprietaryRefefetchQueries,
    awaitRefetchQueries: true,
  });

  // RESEARCH REPORT
  const [deleteResearchReport] = useDeleteOneResearchReportMutation({
    refetchQueries: researchReportRefefetchQueries,
    awaitRefetchQueries: true,
  });

  const formRef = React.useRef<any>(null);
  const [updateComparableCompanies] = useUpsertAndDeleteManyComparableCompaniesMutation({
    refetchQueries: comparableCompaniesRefetchQueries,
    awaitRefetchQueries: true,
  });

  const [updateIntangibleAssets] = useUpsertAndDeleteManyIntangibleAssetsMutation({
    refetchQueries: intangibleAssetsRefetchQueries,
    awaitRefetchQueries: true,
  });

  const handleBeforeDelete = React.useCallback(
    (id) => {
      dispatch({
        type: "setDeleteId",
        payload: {
          form: {
            deleteId: id,
          },
        },
      });
    },
    [dispatch]
  );

  type downloadScreenshotClickedArgs = {
    tableType: ITableType;
    screenshotId?: string;
    index?: string;
    dataDate?: string;
  };

  const downloadScreenshotClicked = ({ tableType, screenshotId, index, dataDate }: downloadScreenshotClickedArgs) => {
    const bloombergTaskUrlText = getBloombergTaskUrlText(tableType);
    if (!bloombergTaskUrlText) return;
    if (
      (tableType === ITableType.BLOOMBERG_INDEXES_LENDING_RATE ||
        tableType === ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE) &&
      index &&
      dataDate
    ) {
      const dataDateText = toUrlDate(moment(state.search.extraFilter?.valuationDateFilter));
      window.open(
        `${SERVER.BACKEND_HOST}/api/bloomberg/${bloombergTaskUrlText}/screenshot/${index}/${dataDateText}`,
        "_blank"
      );
    } else if (screenshotId)
      window.open(`${SERVER.BACKEND_HOST}/api/bloomberg/${bloombergTaskUrlText}/screenshot/${screenshotId}`, "_blank");
  };

  const exportTable = () => {
    switch (tableType) {
      case ITableType.BLOOMBERG_INDEXES_LENDING_RATE:
        exportBloombergIndexesLendingRates({
          variables: {
            ...refetchVariablesBase,
          },
        });
        break;
      case ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE:
        exportBloombergIndexesRiskFreeRates({
          variables: {
            ...refetchVariablesBase,
          },
        });
        break;
      case ITableType.BLOOMBERG_CPI:
        exportBloombergCpis({
          variables: {
            ...refetchVariablesBase,
          },
        });
        break;
      case ITableType.BLOOMBERG_CPI_AVERAGE:
        exportBloombergCpiAverages({
          variables: {
            ...refetchVariablesBase,
          },
        });
        break;
      case ITableType.BLOOMBERG_EXCHANGE_RATE:
        exportBloombergExchangeRates({
          variables: {
            ...refetchVariablesBase,
          },
        });
        break;
      case ITableType.BLOOMBERG_BOND_YIELD:
        exportBloombergBondYields({
          variables: {
            ...refetchVariablesBase,
          },
        });
        break;
      default:
        return;
    }
    dispatch({
      type: "startExport",
    });
  };

  useExportFile({
    state,
    dispatch,
    tableType,
    ExportBloombergIndexesLendingRatesData,
    ExportBloombergIndexesLendingRatesLoading,
    ExportBloombergIndexesLendingRatesError,
    ExportBloombergIndexesRiskFreeRatesData,
    ExportBloombergIndexesRiskFreeRatesLoading,
    ExportBloombergIndexesRiskFreeRatesError,
    ExportBloombergCpisData,
    ExportBloombergCpisLoading,
    ExportBloombergCpisError,
    ExportBloombergCpiAveragesData,
    ExportBloombergCpiAveragesLoading,
    ExportBloombergCpiAveragesError,
    ExportBloombergExchangeRatesData,
    ExportBloombergExchangeRatesLoading,
    ExportBloombergExchangeRatesError,
    ExportBloombergBondYieldsData,
    ExportBloombergBondYieldsLoading,
    ExportBloombergBondYieldsError,
  });

  const handleDeleteItem = React.useCallback(
    (isConfirmDelete) => {
      if (isConfirmDelete) {
        if (tableType === ITableType.CIRCULAR_RESEARCH) {
          deleteResearch({
            variables: {
              id: state.form.deleteId,
            },
          });
        } else if (tableType === ITableType.COMPARABLE_PPA) {
          deleteComparable({
            variables: {
              id: state.form.deleteId,
            },
          });
        } else if (tableType === ITableType.PROPRIETARY_PPA) {
          deleteProprietary({
            variables: {
              id: state.form.deleteId,
            },
          });
        } else if (tableType === ITableType.ROYALTY_RATE) {
          deleteRoyaltyRate({
            variables: {
              id: state.form.deleteId,
            },
          });
        } else if (tableType === ITableType.RESEARCH_REPORT) {
          deleteResearchReport({
            variables: {
              id: state.form.deleteId,
            },
          });
        }
      }
      dispatch({
        type: "closeAlertBox",
      });
    },
    [tableType, deleteResearch, deleteComparable, deleteRoyaltyRate, deleteProprietary, state.form.deleteId, dispatch]
  );

  const handleAlertClose = React.useCallback(() => {
    dispatch({
      type: "closeAlertBox",
    });
  }, [dispatch]);

  const handleFormClose = React.useCallback(() => {
    dispatch({
      type: "closeForm",
    });
    setComparableCompanies(null);
  }, [dispatch]);

  const handleExportDialogClose = React.useCallback(() => {
    dispatch({
      type: "closeExport",
    });
  }, [dispatch]);

  const handleImportDialogClose = React.useCallback(() => {
    dispatch({
      type: "closeImport",
    });
  }, [dispatch]);

  // for propritary - comparable companies, after new proprietary item is added,
  // need get companies list based on new created id
  const setProprietaryFormId = React.useCallback(
    (id) => {
      dispatch({
        type: "setProprietaryFormId",
        payload: {
          form: {
            editId: id,
          },
        },
      });
    },
    [dispatch]
  );

  const editRow = React.useCallback(
    (id, value, mode) => {
      if (tableType === ITableType.PROPRIETARY_PPA) {
        if (id !== null) {
          getComparableCompanies({
            variables: {
              parentId: id,
            },
          });
        }
      }

      if (tableType === ITableType.COMPARABLE_PPA || tableType === ITableType.PROPRIETARY_PPA) {
        if (id !== null) {
          getIntangibleAssets({
            variables: {
              parentId: id,
            },
          });
        }
      }

      dispatch({
        type: "editRow",
        payload: {
          form: {
            data: {
              id,
              value,
            },
            editMode: mode,
            editId: id,
          },
        },
      });
    },
    [tableType, dispatch, getComparableCompanies, getIntangibleAssets]
  );

  const importFile = React.useCallback(
    (tableType) => {
      if (tableType === ITableType.PROPRIETARY_PPA || tableType === ITableType.COMPARABLE_PPA) {
        dispatch({
          type: "importFile",
          payload: {
            import: {
              tableType,
            },
          },
        });
      }
    },
    [dispatch]
  );

  const handleTabChange = React.useCallback(
    (value) => {
      dispatch({
        type: "handleTabChange",
        payload: {
          table: {
            year: value,
          },
          search: {
            keyword: "",
          },
        },
      });
      if (searchRef && searchRef.current) {
        searchRef.current.value = "";
      }
      if (tableRef && tableRef.current) {
        tableRef.current.scrollTo(0, 0);
      }
    },
    [tableRef, searchRef, dispatch]
  );

  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, property) => {
      const isAsc = state.table.orderBy === property && state.table.order === "asc";

      dispatch({
        type: "sort",
        payload: {
          table: {
            order: isAsc ? "desc" : "asc",
            orderBy: property,
          },
        },
      });

      if (tableRef && tableRef.current) {
        tableRef.current.scrollTo(tableRef.current.scrollLeft, 0);
        // back to page 1
        dispatch({
          type: "sortToPage1",
          payload: {
            table: {
              order: isAsc ? "desc" : "asc",
              orderBy: property,
            },
          },
        });
      }
    },
    [state.table.orderBy, state.table.order, dispatch, tableRef]
  );

  const handleChangePage = React.useCallback(
    (event: unknown, newPage: number) => {
      if (tableRef && tableRef.current) {
        tableRef.current.scrollTo(0, 0);
        dispatch({
          type: "changePage",
          payload: {
            table: {
              page: newPage,
            },
          },
        });
      }
    },
    [dispatch, tableRef]
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: "changeRowsPerPage",
        payload: {
          table: {
            rowsPerPage: +event.target.value,
          },
        },
      });
    },
    [dispatch]
  );

  const handleSearch = React.useCallback(() => {
    dispatch({
      type: "handleSearch",
      payload: {
        search: {
          selectedSearchField: state.search.tmpSelectedSearchField,
          keyword: state.search.tmpKeyword,
        },
      },
    });
  }, [state.search.tmpKeyword, dispatch, state.search.tmpSelectedSearchField]);

  const keywordChange = React.useCallback(
    (e) => {
      if (e.target.value !== null) {
        dispatch({
          type: "keywordChange",
          payload: {
            search: {
              tmpKeyword: e.target.value,
            },
          },
        });
      }
    },
    [dispatch]
  );

  const keywordKeyDown = React.useCallback(
    (e) => {
      if (e.keyCode === 13) {
        handleSearch();
      }
    },
    [handleSearch]
  );

  const extraFilterChanged = React.useCallback((e) => {
    dispatch({
      type: "setExtraFilter",
      payload: {
        search: {
          extraFilter: e,
        },
      },
    });
  }, []);

  const handleTableSelectChange = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const tableType = event.target.value as ITableType;
      setTableType(tableType);

      const rowsPerPage = getDefaultRowsPerPage(tableType);
      if (rowsPerPage) state.table.rowsPerPage = rowsPerPage;

      let dispatchState: any = {
        type: "handleTableSelectChange",
        payload: {
          table: { rowsPerPage },
        },
      };
      if (tableType in defaultValuationDateFilters)
        dispatchState.payload.search = { extraFilter: { valuationDateFilter: defaultValuationDateFilters[tableType] } };
      dispatch(dispatchState);
    },
    [setTableType, dispatch, state.table.rowsPerPage]
  );

  const handleFieldSelectChange = React.useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      dispatch({
        type: "handleFieldSelectChange",
        payload: {
          search: {
            tmpSelectedSearchField: event.target.value as string[],
          },
        },
      });
    },
    [dispatch]
  );

  const handleSearchFieldsClear = React.useCallback(() => {
    dispatch({
      type: "handleSearchFieldsClear",
    });
  }, [dispatch]);

  // View
  const handleViewSave = React.useCallback(
    (view) => {
      const tableTypeString = ITableType[Number(tableType)];
      setViewFieldsArray((s) => ({ ...s, [tableTypeString]: view }));
      setCookie(ITableType[Number(tableType)], view);
    },
    [setViewFieldsArray, setCookie, tableType]
  );

  const tableTypeString = ITableType[Number(tableType)];

  const defaultView = viewFieldsArray[tableTypeString] ? viewFieldsArray[tableTypeString] : columns[tableType].data;

  const getCellValue = (type, value, displayMode) => {
    if (displayMode === IValueDisplayMode.LISTING) {
      if (type === "link") {
        return (
          <a target="_blank" rel="noopener noreferrer" href={value}>
            {value}
          </a>
        );
      }
      if (type === "percentage") {
        if (value <= 0) {
          return "N/A";
        } else {
          return value + "%";
        }
      }
      if (type === "number") {
        if (value === null) {
          return "N/A";
        } else {
          return isFloat(value) ? formatNumber(parseFloat(value)) : formatNumber(parseInt(value));
        }
      }
      if (type === "array") {
        if (value === null || !value?.length) {
          return "N/A";
        } else {
          return (
            <>
              <CellShowMore>
                {value.map((v) => (
                  <Chip size="small" className={classes.chip} label={v} />
                ))}
              </CellShowMore>
            </>
          );
        }
      }
      if (type === "industries") {
        if (value === null || !value?.length) {
          return "N/A";
        } else {
          return (
            <>
              {value.map((industryValue) => {
                const industry = IndustryList.find((industry) => `${industry.code} ${industry.name}` === industryValue);
                return <Chip size="small" className={classes.chip} label={`${industry.code} ${industry.name}`} />;
              })}
            </>
          );
        }
      }
      if (type === "listedCompanies") {
        if (value === null || !value?.length) {
          return "N/A";
        } else {
          return (
            <>
              {value
                .map((c) => c.name)
                .map((v) => (
                  <Chip size="small" className={classes.chip} label={v} />
                ))}
            </>
          );
        }
      }
      if (type === "date") {
        if (value === null) {
          return "N/A";
        } else {
          const m = moment(value, SERVER.DATE_FORMAT).isValid() ? moment(value, SERVER.DATE_FORMAT) : moment(value);
          return m.format("YYYY-MM-DD");
        }
      }

      if (value) {
        return value;
      } else {
        return "N/A";
      }
    } else if (displayMode === IValueDisplayMode.EDIT) {
    }
    return value;
  };

  return (
    <>
      <div className={classes.wrap}>
        <ReactTableSnackbar state={state} dispatch={dispatch} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.topMenu}>
              <div className={classes.menuItem}>
                <TableSelect onChange={handleTableSelectChange} value={tableType} />
              </div>
              <div className={classes.menuItem}>
                <View handleSave={handleViewSave} fields={columns[tableType].data} value={defaultView} />
              </div>

              <div className={classes.menuItem}>
                <SearchBox
                  handleSearch={handleSearch}
                  keyword={state.search.keyword}
                  searchRef={searchRef}
                  keywordChange={keywordChange}
                  keywordKeyDown={keywordKeyDown}
                  handleFieldSelectChange={handleFieldSelectChange}
                  allSearchFields={state.search.allSearchFields}
                  tmpSelectedSearchField={state.search.tmpSelectedSearchField}
                  handleSearchFieldsClear={handleSearchFieldsClear}
                />
              </div>
              <div className={classes.menuItem}>
                {lastUpdate && lastUpdate[ITableType[Number(tableType)]] && (
                  <div className={`${classes.lastUpdate} ${classes.menuItem}`}>
                    Last Update : {lastUpdate[ITableType[Number(tableType)]]}
                  </div>
                )}
              </div>

              <div className={`${classes.menuItem} ${classes.addItemContainer}`}>
                {isAllowImport(tableType) && (
                  <Button color="primary" variant="contained" size="small" onClick={() => importFile(tableType)}>
                    <AddIcon fontSize="small" />
                    Import
                  </Button>
                )}

                {isAllowEdit(tableType) && (
                  <Button
                    onClick={() => editRow(null, null, IEditMode.ADD)}
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    <AddIcon fontSize="small" /> Add item
                  </Button>
                )}
                {isAllowDataExport(tableType) && (
                  <Button onClick={() => exportTable()} color="primary" variant="contained" size="small">
                    <CloudDownload fontSize="small" /> Export
                  </Button>
                )}
              </div>
            </Grid>

            <ExtraFilterSection
              tableType={tableType}
              extraFilter={state.search.extraFilter}
              extraFilterChanged={extraFilterChanged}
            />

            <Grid item xs={12} className={classes.dataContentWrap}>
              {tableType === ITableType.CIRCULAR_RESEARCH && (
                <HeaderTabs handleTabChange={handleTabChange} value={state.table.year} />
              )}
              {/* {tableType === ITableType.INDUSTRIAL_FACTOR && (
                <HeaderTabsIndustrialFactorYear
                  handleTabChange={handleIndustrialFactorTabChange}
                  value={state.table.industrialFactorYear}
                />
              )} */}

              <Paper>
                <TableContainer className={classes.tableContainer} ref={tableRef}>
                  {!state.table.isDataNotFound && (
                    <Table stickyHeader size="small" aria-label="sticky table">
                      {columns[tableType] && (
                        <EnhancedTableHead
                          columns={columns[tableType].data}
                          order={state.table.order}
                          orderBy={state.table.orderBy}
                          onRequestSort={handleRequestSort}
                          viewFields={defaultView}
                          tableType={tableType}
                        />
                      )}
                      {state.table.columnData &&
                        typeof state.table.columnData.total !== "undefined" &&
                        columns[tableType] && (
                          <TableBodyContent
                            tableType={tableType}
                            defaultView={defaultView}
                            columns={columns}
                            handleBeforeDelete={handleBeforeDelete}
                            editRow={editRow}
                            columnData={state.table.columnData}
                            getCellValue={getCellValue}
                            downloadScreenshotClicked={downloadScreenshotClicked}
                          />
                        )}
                    </Table>
                  )}
                  {state.table.isDataNotFound && <DataNotFound />}
                </TableContainer>
                {state.table.columnData && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={typeof state.table.columnData.total !== "undefined" ? state.table.columnData.total : 0}
                    rowsPerPage={state.table.rowsPerPage}
                    page={state.table.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>

        <Dialog maxWidth="xl" open={state.form.open} onClose={handleFormClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{IEditMode[Number(state.form.editMode)]}</DialogTitle>
          <DialogContent className={classes.formContent}>
            <Form
              formSubmit={formSubmit}
              setFormSubmit={setFormSubmit}
              setSnackbar={(snackbar) => {
                dispatch({ type: "setSnackbar", payload: { ...state, snackbar } });
              }}
              // initComparableCompanies={initComparableCompanies}
              updateIntangibleAssets={updateIntangibleAssets}
              intangibleAssetsList={intangibleAssetsList}
              comparableCompanies={comparableCompanies}
              handleUpdateComparableCompanies={handleUpdateComparableCompanies}
              handleUpdateIntangibleAssetsList={handleUpdateIntangibleAssetsList}
              updateComparableCompanies={updateComparableCompanies}
              researchRefetchQueries={researchRefetchQueries}
              comparableRefefetchQueries={comparableRefefetchQueries}
              royaltyRefefetchQueries={royaltyRefefetchQueries}
              proprietaryRefefetchQueries={proprietaryRefefetchQueries}
              researchReportRefefetchQueries={researchReportRefefetchQueries}
              form={state.form}
              tableType={tableType}
              year={state.table.year}
              columns={columns}
              setProprietaryFormId={setProprietaryFormId}
              formRef={formRef}
              handleFormClose={handleFormClose}
              useCreateResearchMutation={useCreateOneResearchMutation}
              useUpdateResearchMutation={useUpdateOneResearchMutation}
              useCreateComparableMutation={useCreateOneComparableMutation}
              useUpdateComparableMutation={useUpdateOneComparableMutation}
              useCreateRoyaltyRateMutation={useCreateOneRoyaltyRateMutation}
              useUpdateRoyaltyRateMutation={useUpdateOneRoyaltyRateMutation}
              useCreateProprietaryMutation={useCreateOneProprietaryMutation}
              useUpdateProprietaryMutation={useUpdateOneProprietaryMutation}
              useCreateResearchReportMutation={useCreateOneResearchReportMutation}
              useUpdateResearchReportMutation={useUpdateOneResearchReportMutation}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          maxWidth="sm"
          open={state.import.open}
          onClose={handleImportDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Import {ITableType[state.import.tableType]}</DialogTitle>
          <DialogContent className={classes.exportDialogContent}>
            <UploadFile
              tableType={state.import.tableType}
              comparableRefefetchQueries={comparableRefefetchQueries}
              proprietaryRefefetchQueries={proprietaryRefefetchQueries}
              handleFormClose={handleImportDialogClose}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          maxWidth="sm"
          open={state.export.open}
          onClose={handleExportDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Export{state.export.url?.length > 0 ? " Completed" : ""}</DialogTitle>
          <DialogContent className={classes.exportDialogContent}>
            {state.export.loading && <CircularProgress />}
            {state.export.url?.length > 0 && (
              <Link href={state.export.url} target="_blank">
                Download
              </Link>
            )}
            {state.export.error?.length > 0 && (
              <Alert title="Error" severity="error">
                {state.export.error}
              </Alert>
            )}
          </DialogContent>
        </Dialog>
      </div>
      <AlertDialog
        handleDeleteItem={handleDeleteItem}
        handleAlertClose={handleAlertClose}
        openFromParent={state.table.alertOpen}
      />
      {loading && <Loading />}
    </>
  );
};
