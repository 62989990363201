import moment from "moment";
import { BloombergRiskFreeRateRegions } from "utils/const";
import { timezoneDateTimeToDateString } from "utils/helper";
import { IEditMode, IReactTableState, ITableType } from "../../../utils/interface";

export const initState: IReactTableState = {
  table: {
    page: 0,
    year: new Date().getFullYear().toString(),
    industrialFactorYear: 2020,
    rowsPerPage: 10,
    order: "asc",
    orderBy: "",
    columnData: {},
    isDataNotFound: false,
    alertOpen: false,
  },
  form: {
    initValues: {},
    isSubmitting: false,
    data: {
      id: "",
      value: [],
    },
    editMode: IEditMode.EDIT,
    open: false,
    editId: "",
    deleteId: "",
  },
  search: {
    allSearchFields: [],
    selectedSearchField: [],
    tmpSelectedSearchField: [],
    keyword: "",
    tmpKeyword: "",
    extraFilter: {
      valuationDateFilter: timezoneDateTimeToDateString(new Date()),
      regionFilter: BloombergRiskFreeRateRegions["Hong Kong"],
    },
  },
  export: {
    open: false,
    loading: true,
    url: "",
    error: "",
  },
  import: {
    open: false,
  },
  snackbar: { open: false, message: "success", type: "success" },
};

/**
 * Get the nearest workday starting from n days before today.
 *
 * @param daysBefore n days before today
 * @returns
 */
export const workdayBeforeToday = (daysBefore: number): string => {
  const targetDateMoment = moment().subtract(daysBefore, "days");

  switch (targetDateMoment.day()) {
    case 0:
      // Sunday
      targetDateMoment.subtract(2, "days");
      break;
    case 6:
      // Saturday
      targetDateMoment.subtract(1, "days");
      break;
    default:
      break;
  }

  return timezoneDateTimeToDateString(targetDateMoment.toDate());
};

export const defaultValuationDateFilters: Record<string, string> = {
  [ITableType.BLOOMBERG_BOND_YIELD]: workdayBeforeToday(2),
  [ITableType.BLOOMBERG_INDEXES_LENDING_RATE]: workdayBeforeToday(1),
  [ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE]: workdayBeforeToday(1),
};
