import React from "react";

import { Pages } from "router";
import Layout from "./layout";
import { BrowserRouter as Router } from "react-router-dom";
import AvistaApolloProvider from "provider/apollo";
import { CookiesProvider } from "react-cookie";
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const defaultTheme = createTheme();
const App = () => {
  return (
    <AvistaApolloProvider>
      <CookiesProvider>
        <ThemeProvider theme={defaultTheme}>
          <Helmet>
            <title>Data Query | Avista</title>
          </Helmet>
          <Router>
            <Layout>
              <Pages />
            </Layout>
          </Router>
        </ThemeProvider>
      </CookiesProvider>
    </AvistaApolloProvider>
  );
};
export default App;
