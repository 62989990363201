import { ColumnsData } from "../../generated/graphql";

export const bloombergExchangeRateColumns: ColumnsData[] = [
  { id: "dataDate", label: "Date (YYYY-MM-DD)", type: "date", sortable: true, minWidth: 180 },
  { id: "USDCNY_BGNT_Curncy", label: "RMB/USD", type: "number", sortable: true },
  { id: "USDHKD_BGNT_Curncy", label: "HKD/USD", type: "number", sortable: true },
  { id: "CNYHKD_BGNT_Curncy", label: "HKD/RMB", type: "number", sortable: true },
  { id: "EURHKD_BGNT_Curncy", label: "HKD/EUR", type: "number", sortable: true },
  { id: "GBPHKD_BGNT_Curncy", label: "HKD/GBP", type: "number", sortable: true },
  { id: "SGDHKD_BGNT_Curncy", label: "HKD/SGD", type: "number", sortable: true },
  { id: "JPYHKD_BGNT_Curncy", label: "HKD/JPY (X100)", type: "number", sortable: true },
  { id: "AUDHKD_BGNT_Curncy", label: "HKD/AUD", type: "number", sortable: true },
  { id: "HKDTWD_BGNT_Curncy", label: "TWD/HKD", type: "number", sortable: true },
  { id: "HKDKRW_BGNT_Curncy", label: "KRW/HKD", type: "number", sortable: true },
];
