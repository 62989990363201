import React from "react";

import { AvistaOptionTypeBase, AvistaSelect } from "@avista/avista-select";
import InputLabel from "@mui/material/InputLabel";
import { IEditMode } from "utils/interface";

export type MultiSelectFieldProps = {
  formEditMode: IEditMode;
  defaultValues: AvistaOptionTypeBase[];
  handleUpdate: (values: string[]) => void;
  name: string;
  label: string;
  options: AvistaOptionTypeBase[];
};

export const MultiSelectField: React.FC<MultiSelectFieldProps> = (props) => {
  const { formEditMode, defaultValues, handleUpdate, name, label, options } = props;

  const initValues = formEditMode === IEditMode.ADD ? [] : defaultValues;

  const onChange = (options: AvistaOptionTypeBase[]) => {
    const values = options.map((option) => option.value);
    handleUpdate(values);
  };

  return (
    <>
      <InputLabel id={name + "-label"}>{label}</InputLabel>
      <AvistaSelect
        defaultValue={initValues}
        isMulti
        name={name}
        options={options}
        onChange={onChange}
        variant={formEditMode === IEditMode.ADD ? "new" : "edit"}
        TextFieldProps={{
          sx: {
            "& .MuiInput-input": {
              height: "auto !important",
            },
          },
        }}
        styles={{
          indicatorsContainer: (css) => ({
            ...css,
            position: "absolute",
            right: "0",
            top: "0",
          }),
          menu: (css) => ({
            ...css,
            zIndex: 999,
          }),
          valueContainer: (css) => ({
            ...css,
            paddingLeft: "0",
          }),
        }}
      />
    </>
  );
};
