import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Photo from "@mui/icons-material/Photo";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useStyles } from "./style";
import { ITableType, IEditMode, IValueDisplayMode } from "../../utils/interface";
import {
  getCpiScreenshotId,
  getResearchReportFileUrl,
  isAllowEdit,
  isAllowScreenshotDownloadByCell,
  isAllowScreenshotDownloadByRow,
} from "utils/helper";
import { Chip } from "@mui/material";
import { CellShowMore } from "./CellShowMore";

const TableBodyContent = ({
  getCellValue,
  tableType,
  defaultView,
  columns,
  columnData,
  editRow,
  handleBeforeDelete,
  downloadScreenshotClicked,
}) => {
  const classes = useStyles();
  return (
    <>
      <TableBody>
        {columnData.data?.map((row, index) => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
              {isAllowEdit(tableType) && (
                <TableCell key={"delete"}>
                  <EditIcon
                    fontSize="small"
                    className={classes.editIcon}
                    onClick={() => editRow(row.id, row, IEditMode.EDIT)}
                  />
                  <DeleteIcon
                    onClick={() => handleBeforeDelete(row.id)}
                    fontSize="small"
                    className={classes.deleteIcon}
                  />
                </TableCell>
              )}
              {isAllowScreenshotDownloadByRow(tableType) && (
                <TableCell>
                  {row.screenshotId && (
                    <Photo
                      onClick={() => downloadScreenshotClicked({ tableType, screenshotId: row.screenshotId })}
                      fontSize="small"
                      className={classes.downloadIcon}
                    />
                  )}
                  {row.index && row.dataDate && (
                    <Photo
                      onClick={() => downloadScreenshotClicked({ tableType, index: row.index, dataDate: row.dataDate })}
                      fontSize="small"
                      className={classes.downloadIcon}
                    />
                  )}
                </TableCell>
              )}

              {columns[tableType].data.map((column, columnIndex) => {
                const viewFields = defaultView;
                if (
                  (viewFields && viewFields.length > 0 && viewFields[columnIndex].visible !== false) ||
                  typeof viewFields === "undefined"
                ) {
                  if (!column.hidden) {
                    const value = row[column.id];

                    if (tableType === ITableType.PROPRIETARY_PPA && column.id === "ComparableCompanies") {
                      if (value && value.length > 0) {
                        const companies = value.map((item, index) => (
                          <li key={index}>
                            {item.company} - {item.code}
                          </li>
                        ));
                        return (
                          <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                            <ul className={classes.comparableCompanies}>{companies}</ul>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                            N/A
                          </TableCell>
                        );
                      }
                    }
                    if (
                      (tableType === ITableType.PROPRIETARY_PPA || tableType === ITableType.COMPARABLE_PPA) &&
                      column.id === "IntangibleAssetsList"
                    ) {
                      if (value && value.length > 0) {
                        const intangibleAssets = value.map((item, index) => (
                          <li key={index}>
                            {index + 1}: {item.type} - {item.amount.toLocaleString()} - {item.rul}
                          </li>
                        ));
                        return (
                          <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                            <ul className={classes.comparableCompanies}>{intangibleAssets}</ul>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                            N/A
                          </TableCell>
                        );
                      }
                    }

                    if (
                      (tableType === ITableType.COMPARABLE_PPA && column.id === "DescriptionOfTheTarget") ||
                      (tableType === ITableType.COMPARABLE_PPA && column.id === "Remarks") ||
                      (tableType === ITableType.CIRCULAR_RESEARCH && column.id === "TargetBusiness") ||
                      (tableType === ITableType.CIRCULAR_RESEARCH && column.id === "Remark") ||
                      (tableType === ITableType.CIRCULAR_RESEARCH &&
                        column.id === "SelectedComparableCompaniesStockCode") ||
                      (tableType === ITableType.ROYALTY_RATE && column.id === "CompensationDetail") ||
                      (tableType === ITableType.ROYALTY_RATE && column.id === "LicensedPropertyDescription") ||
                      (tableType === ITableType.RESEARCH_REPORT && column.id === "description")
                    ) {
                      return (
                        <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                          <CellShowMore>{getCellValue(column.type, value, IValueDisplayMode.LISTING)}</CellShowMore>
                        </TableCell>
                      );
                    } else if (isAllowScreenshotDownloadByCell(tableType) && column.id !== "dataDate") {
                      const screenshotId = getCpiScreenshotId(tableType, column.id, row);
                      return (
                        <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                          {getCellValue(column.type, value, IValueDisplayMode.LISTING)}
                          {screenshotId && (
                            <Photo
                              onClick={() => downloadScreenshotClicked({ tableType, screenshotId })}
                              fontSize="small"
                              className={classes.tableCellDownloadIcon}
                            />
                          )}
                        </TableCell>
                      );
                    } else if (isAllowScreenshotDownloadByCell(tableType) && column.id !== "dataDate") {
                      const screenshotId = getCpiScreenshotId(tableType, column.id, row);
                      return (
                        <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                          {getCellValue(column.type, value, IValueDisplayMode.LISTING)}
                          {screenshotId && (
                            <Photo
                              onClick={() => downloadScreenshotClicked({ tableType, screenshotId })}
                              fontSize="small"
                              className={classes.tableCellDownloadIcon}
                            />
                          )}
                        </TableCell>
                      );
                    } else if (tableType === ITableType.RESEARCH_REPORT && column.id === "files") {
                      return (
                        <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                          {value && value.length ? (
                            value.map((v) => (
                              <Chip
                                clickable
                                component="a"
                                href={getResearchReportFileUrl(row.id, v.substring(v.lastIndexOf("\\") + 1))}
                                target="_blank"
                                label={v.substring(v.lastIndexOf("\\") + 1)}
                                className={classes.chip}
                              />
                            ))
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                          {getCellValue(column.type, value, IValueDisplayMode.LISTING)}
                        </TableCell>
                      );
                    }
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};

export default TableBodyContent;
