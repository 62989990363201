import { ColumnsData } from "../generated/graphql";
export const comparablePPA: ColumnsData[] = [
  {
    id: "id",
    label: "Id",
    hidden: true,
  },
  {
    id: "ListCo",
    label: "ListCo",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "StockCode",
    label: "Stock Code",
    sortable: true,
    minWidth: 80,
  },
  {
    id: "Target",
    label: "Target",
    minWidth: 400,
    sortable: true,
  },
  // {
  //   id: "TargetSector",
  //   label: "Target Sector",
  //   minWidth: 150,
  //   sortable: true,
  // },
  // {
  //   id: "TargetIndustry",
  //   label: "Target Industry",
  //   minWidth: 150,
  //   sortable: true,
  // },
  {
    id: "Industry",
    label: "Industry",
    minWidth: 300,
    sortable: true,
  },
  {
    id: "TargetBusiness",
    label: "Target Business",
    minWidth: 400,
  },
  {
    id: "Location",
    label: "Location",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "AcquisitionDate",
    label: "Acquisition date",
    minWidth: 150,
    sortable: true,
    type: "date",
  },
  {
    id: "Currency",
    label: "Currency",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "IntangibleAssets",
    label: "Intangible assets",
    minWidth: 150,
    sortable: true,
    type: "number",
  },
  {
    id: "IntangibleAssetsList",
    label: "Intangible Assets List (Type - Amount - RUL)",
    minWidth: 300,
  },
  {
    id: "GoodwillFull",
    label: "Goodwill (Full)",
    minWidth: 150,
    sortable: true,
    type: "number",
  },
  {
    id: "IAIAGW",
    label: "% of IA / (IA + GW)",
    minWidth: 150,
    sortable: true,
    type: "percentage",
  },
  {
    id: "GWIAGW",
    label: "% of GW / (IA + GW)",
    minWidth: 150,
    sortable: true,
    type: "percentage",
  },
  {
    id: "PurchaseConsiderationRaw",
    label: "Purchase Consideration",
    minWidth: 150,
    sortable: true,
    type: "number",
  },
  {
    id: "EquityinterestAcquired",
    label: "% of Equity Interest Acquired",
    minWidth: 150,
    sortable: true,
    type: "percentage",
  },
  {
    id: "PurchaseConsiderationFull",
    label: "Implied 100% Consideration",
    minWidth: 150,
    sortable: true,
    type: "number",
  },
  {
    id: "Remarks",
    label: "Remarks",
    minWidth: 400,
  },
  {
    id: "PageNo",
    label: "Page No",
    minWidth: 150,
  },
  {
    id: "Link",
    label: "Link",
    minWidth: 150,
  },
  {
    id: "DoneBy",
    label: "Done By",
    minWidth: 150,
    sortable: true,
  },
];
