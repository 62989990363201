import { ColumnsData } from "../../generated/graphql";

export const bloombergCpiColumns: ColumnsData[] = [
  {
    id: "dataDate",
    label: "Date",
    type: "date",
    sortable: true,
  },
  {
    id: "CNCPIYOY_Index",
    label: "China",
    type: "number",
    sortable: true,
  },
  {
    id: "HKCPIY_Index",
    label: "Hong Kong",
    type: "number",
    sortable: true,
  },
  {
    id: "CPI_YOY_Index",
    label: "US",
    type: "number",
    sortable: true,
  },
];
