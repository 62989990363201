import React from "react";

import { ReactTable } from "components/ReactTable";
import { RouteComponentProps } from "react-router-dom";
import { columns } from "../../columns";

export const LandingPage: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <ReactTable columns={columns} />
    </>
  );
};
