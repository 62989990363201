import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import Popover from "@mui/material/Popover";
import FieldSelect from "./FieldSelect";
import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "200px",
    marginRight: "40px",
  },
  searchWrap: {
    padding: "10px",
    width: "450px",
  },
  searchInput: {
    fontSize: "14px",
  },
  searchBtn: {
    marginTop: "15px",
    fontSize: "12px",
  },
}));

const SearchBox = ({
  keywordChange,
  keywordKeyDown,
  handleFieldSelectChange,
  allSearchFields,
  tmpSelectedSearchField,
  handleSearchFieldsClear,
  searchRef,
  keyword,
  handleSearch,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button onClick={handleClick}>
        <SearchIcon style={{ color: "#3f51b5" }} />
        <span>Search</span>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.searchWrap}>
          <FieldSelect
            onChange={handleFieldSelectChange}
            fields={allSearchFields}
            value={tmpSelectedSearchField}
            clear={handleSearchFieldsClear}
          />
          <TextField
            // autoFocus
            autoComplete="off"
            fullWidth
            id="standard-search"
            label="Search field"
            type="search"
            onChange={keywordChange}
            onKeyDown={keywordKeyDown}
            inputRef={searchRef}
            autoFocus={true}
            InputProps={{
              classes: {
                input: classes.searchInput,
              },
            }}
            defaultValue={keyword}
            variant="standard"
          />
          <Button
            className={classes.searchBtn}
            variant="contained"
            color="primary"
            size="small"
            style={{ marginTop: "15px" }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default SearchBox;
