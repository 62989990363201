import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: Date; output: Date; }
  File: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type BloombergBondYieldRow = {
  __typename?: 'BloombergBondYieldRow';
  bbg?: Maybe<Scalars['String']['output']>;
  biscLevel2?: Maybe<Scalars['String']['output']>;
  cpn?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isin: Scalars['String']['output'];
  issueDate: Scalars['String']['output'];
  issuerName: Scalars['String']['output'];
  maturity?: Maybe<Scalars['String']['output']>;
  mtyType?: Maybe<Scalars['String']['output']>;
  screenshotId?: Maybe<Scalars['ID']['output']>;
  series?: Maybe<Scalars['String']['output']>;
  spIssuerRating?: Maybe<Scalars['String']['output']>;
  spRating?: Maybe<Scalars['String']['output']>;
  ticker: Scalars['String']['output'];
  timeToMaturity?: Maybe<Scalars['Float']['output']>;
  valuationDate: Scalars['DateTime']['output'];
  yieldToMaturityMid?: Maybe<Scalars['String']['output']>;
};

export type BloombergBondYields = {
  __typename?: 'BloombergBondYields';
  data?: Maybe<Array<BloombergBondYieldRow>>;
  total: Scalars['Int']['output'];
};

export type BloombergCpiAverageRow = {
  __typename?: 'BloombergCpiAverageRow';
  CNCPIYOY_Index?: Maybe<Scalars['Float']['output']>;
  CNCPIYOY_Index_id?: Maybe<Scalars['String']['output']>;
  CPI_YOY_Index?: Maybe<Scalars['Float']['output']>;
  CPI_YOY_Index_id?: Maybe<Scalars['String']['output']>;
  HKCPIY_Index?: Maybe<Scalars['Float']['output']>;
  HKCPIY_Index_id?: Maybe<Scalars['String']['output']>;
  dataDate: Scalars['DateTime']['output'];
  period: Scalars['String']['output'];
  rangeInYear: Scalars['Int']['output'];
};

export type BloombergCpiAverages = {
  __typename?: 'BloombergCpiAverages';
  data?: Maybe<Array<BloombergCpiAverageRow>>;
  total: Scalars['Int']['output'];
};

export type BloombergCpiRow = {
  __typename?: 'BloombergCpiRow';
  CNCPIYOY_Index?: Maybe<Scalars['Float']['output']>;
  CNCPIYOY_Index_screenshot?: Maybe<Scalars['String']['output']>;
  CPI_YOY_Index?: Maybe<Scalars['Float']['output']>;
  CPI_YOY_Index_screenshot?: Maybe<Scalars['String']['output']>;
  HKCPIY_Index?: Maybe<Scalars['Float']['output']>;
  HKCPIY_Index_screenshot?: Maybe<Scalars['String']['output']>;
  dataDate: Scalars['DateTime']['output'];
};

export type BloombergCpis = {
  __typename?: 'BloombergCpis';
  data?: Maybe<Array<BloombergCpiRow>>;
  total: Scalars['Int']['output'];
};

export type BloombergExchangeRateMissingInfo = {
  __typename?: 'BloombergExchangeRateMissingInfo';
  command: Array<Scalars['String']['output']>;
  date: Array<Scalars['String']['output']>;
  ticker: Scalars['String']['output'];
};

export type BloombergExchangeRateMultiMissingInfo = {
  __typename?: 'BloombergExchangeRateMultiMissingInfo';
  command: Array<Scalars['String']['output']>;
  count: Scalars['Int']['output'];
  date: Array<Scalars['String']['output']>;
};

export type BloombergExchangeRateRow = {
  __typename?: 'BloombergExchangeRateRow';
  AUDHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  AUDHKD_BGNT_Curncy_screenshot?: Maybe<Scalars['String']['output']>;
  CNYHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  CNYHKD_BGNT_Curncy_screenshot?: Maybe<Scalars['String']['output']>;
  EURHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  EURHKD_BGNT_Curncy_screenshot?: Maybe<Scalars['String']['output']>;
  GBPHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  GBPHKD_BGNT_Curncy_screenshot?: Maybe<Scalars['String']['output']>;
  HKDKRW_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  HKDKRW_BGNT_Curncy_screenshot?: Maybe<Scalars['String']['output']>;
  HKDTWD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  HKDTWD_BGNT_Curncy_screenshot?: Maybe<Scalars['String']['output']>;
  JPYHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  JPYHKD_BGNT_Curncy_screenshot?: Maybe<Scalars['String']['output']>;
  SGDHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  SGDHKD_BGNT_Curncy_screenshot?: Maybe<Scalars['String']['output']>;
  USDCNY_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  USDCNY_BGNT_Curncy_screenshot?: Maybe<Scalars['String']['output']>;
  USDHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  USDHKD_BGNT_Curncy_screenshot?: Maybe<Scalars['String']['output']>;
  dataDate: Scalars['DateTime']['output'];
};

export type BloombergExchangeRateValueRow = {
  __typename?: 'BloombergExchangeRateValueRow';
  AUDHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  CNYHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  EURHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  GBPHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  HKDKRW_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  HKDTWD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  JPYHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  SGDHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  USDCNY_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  USDHKD_BGNT_Curncy?: Maybe<Scalars['Float']['output']>;
  dataDate: Scalars['DateTime']['output'];
};

export type BloombergExchangeRates = {
  __typename?: 'BloombergExchangeRates';
  data?: Maybe<Array<BloombergExchangeRateRow>>;
  total: Scalars['Int']['output'];
};

export type BloombergIndexInfo = {
  __typename?: 'BloombergIndexInfo';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  region?: Maybe<Scalars['String']['output']>;
  ticker?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type BloombergIndexRow = {
  __typename?: 'BloombergIndexRow';
  dataDate: Scalars['DateTime']['output'];
  dataValue: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  index?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  ticker: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type BloombergIndexes = {
  __typename?: 'BloombergIndexes';
  data?: Maybe<Array<BloombergIndexRow>>;
  total: Scalars['Int']['output'];
};

export type ColumnsData = {
  align?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  minWidth?: InputMaybe<Scalars['Int']['input']>;
  sortable?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
  width?: InputMaybe<ExcelWidth>;
};

export type ComparableCompaniesListInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
};

export type ComparableCompany = {
  __typename?: 'ComparableCompany';
  code?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type ComparableCompanyCreateOneInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type ComparableCompanyUpdateOneInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type ComparableCreateOneInput = {
  AcquisitionDate?: InputMaybe<Scalars['String']['input']>;
  Currency?: InputMaybe<Scalars['String']['input']>;
  DoneBy?: InputMaybe<Scalars['String']['input']>;
  EquityinterestAcquired?: InputMaybe<Scalars['Float']['input']>;
  GWIAGW?: InputMaybe<Scalars['Float']['input']>;
  GoodwillFull?: InputMaybe<Scalars['Float']['input']>;
  IAIAGW?: InputMaybe<Scalars['Float']['input']>;
  Industry?: InputMaybe<Scalars['String']['input']>;
  IntangibleAssets?: InputMaybe<Scalars['Float']['input']>;
  IntangibleAssetsList?: InputMaybe<Array<InputMaybe<IntangibleAssetCreateOneInput>>>;
  Link?: InputMaybe<Scalars['String']['input']>;
  ListCo?: InputMaybe<Scalars['String']['input']>;
  Location?: InputMaybe<Scalars['String']['input']>;
  PageNo?: InputMaybe<Scalars['String']['input']>;
  PurchaseConsiderationFull?: InputMaybe<Scalars['Float']['input']>;
  PurchaseConsiderationRaw?: InputMaybe<Scalars['Float']['input']>;
  Remarks?: InputMaybe<Scalars['String']['input']>;
  StockCode?: InputMaybe<Scalars['String']['input']>;
  Target?: InputMaybe<Scalars['String']['input']>;
  TargetBusiness?: InputMaybe<Scalars['String']['input']>;
};

export type ComparableRow = {
  __typename?: 'ComparableRow';
  AcquisitionDate?: Maybe<Scalars['String']['output']>;
  Currency?: Maybe<Scalars['String']['output']>;
  DoneBy?: Maybe<Scalars['String']['output']>;
  EquityinterestAcquired?: Maybe<Scalars['Float']['output']>;
  GWIAGW?: Maybe<Scalars['Float']['output']>;
  GoodwillFull?: Maybe<Scalars['Float']['output']>;
  IAIAGW?: Maybe<Scalars['Float']['output']>;
  Industry?: Maybe<Scalars['String']['output']>;
  IntangibleAssets?: Maybe<Scalars['Float']['output']>;
  IntangibleAssetsList?: Maybe<Array<Maybe<IntangibleAsset>>>;
  Link?: Maybe<Scalars['String']['output']>;
  ListCo?: Maybe<Scalars['String']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  PageNo?: Maybe<Scalars['String']['output']>;
  PurchaseConsiderationFull?: Maybe<Scalars['Float']['output']>;
  PurchaseConsiderationRaw?: Maybe<Scalars['Float']['output']>;
  Remarks?: Maybe<Scalars['String']['output']>;
  StockCode?: Maybe<Scalars['String']['output']>;
  Target?: Maybe<Scalars['String']['output']>;
  TargetBusiness?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ComparableUpdateOneInput = {
  AcquisitionDate?: InputMaybe<Scalars['String']['input']>;
  Currency?: InputMaybe<Scalars['String']['input']>;
  DoneBy?: InputMaybe<Scalars['String']['input']>;
  EquityinterestAcquired?: InputMaybe<Scalars['Float']['input']>;
  GWIAGW?: InputMaybe<Scalars['Float']['input']>;
  GoodwillFull?: InputMaybe<Scalars['Float']['input']>;
  IAIAGW?: InputMaybe<Scalars['Float']['input']>;
  Industry?: InputMaybe<Scalars['String']['input']>;
  IntangibleAssets?: InputMaybe<Scalars['Float']['input']>;
  IntangibleAssetsList?: InputMaybe<Array<InputMaybe<IntangibleAssetUpdateOneInput>>>;
  Link?: InputMaybe<Scalars['String']['input']>;
  ListCo?: InputMaybe<Scalars['String']['input']>;
  Location?: InputMaybe<Scalars['String']['input']>;
  PageNo?: InputMaybe<Scalars['String']['input']>;
  PurchaseConsiderationFull?: InputMaybe<Scalars['Float']['input']>;
  PurchaseConsiderationRaw?: InputMaybe<Scalars['Float']['input']>;
  Remarks?: InputMaybe<Scalars['String']['input']>;
  StockCode?: InputMaybe<Scalars['String']['input']>;
  Target?: InputMaybe<Scalars['String']['input']>;
  TargetBusiness?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type Comparables = {
  __typename?: 'Comparables';
  data?: Maybe<Array<ComparableRow>>;
  total: Scalars['Int']['output'];
};

export type EffectiveInterestRate = {
  __typename?: 'EffectiveInterestRate';
  data?: Maybe<Array<EffectiveInterestRateRow>>;
  total: Scalars['Int']['output'];
};

export type EffectiveInterestRateCreateInput = {
  date: Scalars['String']['input'];
  interestRate: Scalars['Float']['input'];
  location: Scalars['String']['input'];
};

export type EffectiveInterestRateInput = {
  date: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  interestRate: Scalars['Float']['input'];
  location: Scalars['String']['input'];
};

export type EffectiveInterestRateRow = {
  __typename?: 'EffectiveInterestRateRow';
  date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  interestRate: Scalars['Float']['output'];
  location: Scalars['String']['output'];
};

export type ExcelWidth = {
  wpx?: InputMaybe<Scalars['Int']['input']>;
};

export type ExportedFile = {
  __typename?: 'ExportedFile';
  url?: Maybe<Scalars['String']['output']>;
};

export enum GroupFieldsIndustrialFactor {
  Industry = 'INDUSTRY',
  Location = 'LOCATION'
}

export type IndustrialFactor = {
  __typename?: 'IndustrialFactor';
  data?: Maybe<Array<IndustrialFactorRow>>;
  total: Scalars['Int']['output'];
};

export type IndustrialFactorCreateInput = {
  factor: Scalars['Float']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  supporting?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type IndustrialFactorInput = {
  factor: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  supporting?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type IndustrialFactorRow = {
  __typename?: 'IndustrialFactorRow';
  factor: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  supporting?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
};

export type IntangibleAsset = {
  __typename?: 'IntangibleAsset';
  amount?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  parentId?: Maybe<Scalars['ID']['output']>;
  rul?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type IntangibleAssetCreateOneInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  rul?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type IntangibleAssetUpdateOneInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  rul?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type IssueRow = {
  __typename?: 'IssueRow';
  createdAt: Scalars['Date']['output'];
  createdBy: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type LastUpdate = {
  __typename?: 'LastUpdate';
  data: LastUpdateRow;
};

export type LastUpdateRow = {
  __typename?: 'LastUpdateRow';
  CIRCULAR_RESEARCH?: Maybe<Scalars['String']['output']>;
  COMPARABLE_PPA?: Maybe<Scalars['String']['output']>;
  INDUSTRIAL_FACTOR?: Maybe<Scalars['String']['output']>;
  MARCO_ECON_FACTOR?: Maybe<Scalars['String']['output']>;
  PROPRIETARY_PPA?: Maybe<Scalars['String']['output']>;
  RECOVERY_RATES?: Maybe<Scalars['String']['output']>;
  ROYALTY_RATE?: Maybe<Scalars['String']['output']>;
};

export type ListedCompany = {
  __typename?: 'ListedCompany';
  data?: Maybe<Array<ListedCompanyRow>>;
  total: Scalars['Int']['output'];
};

export type ListedCompanyRow = {
  __typename?: 'ListedCompanyRow';
  companyType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  nativeName?: Maybe<Scalars['String']['output']>;
  primaryIndustry?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  stockExchange?: Maybe<Scalars['String']['output']>;
  ticker: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type MarcoEconFactor = {
  __typename?: 'MarcoEconFactor';
  data?: Maybe<Array<MarcoEconFactorRow>>;
  total: Scalars['Int']['output'];
};

export type MarcoEconFactorCreateInput = {
  description: Scalars['String']['input'];
  factor: Scalars['Float']['input'];
  supporting?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type MarcoEconFactorInput = {
  description: Scalars['String']['input'];
  factor: Scalars['Float']['input'];
  id: Scalars['ID']['input'];
  supporting?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type MarcoEconFactorRow = {
  __typename?: 'MarcoEconFactorRow';
  description: Scalars['String']['output'];
  factor: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  supporting?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createIndustrialFactor?: Maybe<IndustrialFactorRow>;
  createMarcoEconFactor?: Maybe<MarcoEconFactorRow>;
  createOneComparable?: Maybe<ComparableRow>;
  createOneIssue?: Maybe<IssueRow>;
  createOneProprietary?: Maybe<ProprietaryRow>;
  createOneResearch?: Maybe<ResearchRow>;
  createOneResearchReport?: Maybe<ResearchReportRow>;
  createOneRoyaltyRate?: Maybe<RoyaltyRateRow>;
  createRecoveryRate?: Maybe<RecoveryRateRow>;
  deleteAllBloombergBondYields?: Maybe<Scalars['Boolean']['output']>;
  deleteAllProprietaries?: Maybe<Scalars['Boolean']['output']>;
  deleteIndustrialFactor?: Maybe<IndustrialFactorRow>;
  deleteMarcoEconFactor?: Maybe<MarcoEconFactorRow>;
  deleteOneComparable?: Maybe<ComparableRow>;
  deleteOneProprietary?: Maybe<ProprietaryRow>;
  deleteOneResearch?: Maybe<ResearchRow>;
  deleteOneResearchReport?: Maybe<ResearchReportRow>;
  deleteOneRoyaltyRate?: Maybe<RoyaltyRateRow>;
  deleteRecoveryRate?: Maybe<RecoveryRateRow>;
  updateIndustrialFactor?: Maybe<IndustrialFactorRow>;
  updateManyComparablesByImportFile?: Maybe<Array<Maybe<ComparableRow>>>;
  updateManyProprietariesByImportFile?: Maybe<Array<Maybe<ProprietaryRow>>>;
  updateMarcoEconFactor?: Maybe<MarcoEconFactorRow>;
  updateOneComparable?: Maybe<ComparableRow>;
  updateOneProprietary?: Maybe<ProprietaryRow>;
  updateOneResearch?: Maybe<ResearchRow>;
  updateOneResearchReport?: Maybe<ResearchReportRow>;
  updateOneRoyaltyRate?: Maybe<RoyaltyRateRow>;
  updateRecoveryRate?: Maybe<RecoveryRateRow>;
  upsertAndDeleteManyComparableCompanies?: Maybe<ComparableCompany>;
  upsertAndDeleteManyIntangibleAssets?: Maybe<IntangibleAsset>;
};


export type MutationCreateIndustrialFactorArgs = {
  data: IndustrialFactorCreateInput;
};


export type MutationCreateMarcoEconFactorArgs = {
  data: MarcoEconFactorCreateInput;
};


export type MutationCreateOneComparableArgs = {
  data: ComparableCreateOneInput;
};


export type MutationCreateOneIssueArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};


export type MutationCreateOneProprietaryArgs = {
  data: ProprietaryCreateOneInput;
};


export type MutationCreateOneResearchArgs = {
  data: ResearchCreateOneInput;
};


export type MutationCreateOneResearchReportArgs = {
  data: ResearchReportCreateOneInput;
};


export type MutationCreateOneRoyaltyRateArgs = {
  data: RoyaltyRateCreateOneInput;
};


export type MutationCreateRecoveryRateArgs = {
  data: RecoveryRateCreateInput;
};


export type MutationDeleteIndustrialFactorArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMarcoEconFactorArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOneComparableArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOneProprietaryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOneResearchArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOneResearchReportArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOneRoyaltyRateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRecoveryRateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateIndustrialFactorArgs = {
  data: IndustrialFactorInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateManyComparablesByImportFileArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUpdateManyProprietariesByImportFileArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUpdateMarcoEconFactorArgs = {
  data: MarcoEconFactorInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateOneComparableArgs = {
  data: ComparableUpdateOneInput;
};


export type MutationUpdateOneProprietaryArgs = {
  data: ProprietaryUpdateOneInput;
};


export type MutationUpdateOneResearchArgs = {
  data: ResearchUpdateOneInput;
};


export type MutationUpdateOneResearchReportArgs = {
  data: ResearchReportUpdateOneInput;
};


export type MutationUpdateOneRoyaltyRateArgs = {
  data: RoyaltyRateUpdateOneInput;
};


export type MutationUpdateRecoveryRateArgs = {
  data: RecoveryRateInput;
  id: Scalars['ID']['input'];
};


export type MutationUpsertAndDeleteManyComparableCompaniesArgs = {
  data: Array<InputMaybe<ComparableCompanyUpdateOneInput>>;
};


export type MutationUpsertAndDeleteManyIntangibleAssetsArgs = {
  data: Array<InputMaybe<IntangibleAssetUpdateOneInput>>;
};

export type Proprietary = {
  __typename?: 'Proprietary';
  data?: Maybe<Array<ProprietaryRow>>;
  total: Scalars['Int']['output'];
};

export type ProprietaryCreateOneInput = {
  Acquirer?: InputMaybe<Scalars['String']['input']>;
  AcquirerStockCode?: InputMaybe<Scalars['String']['input']>;
  AcquisitionDate?: InputMaybe<Scalars['String']['input']>;
  BusinessDescription?: InputMaybe<Scalars['String']['input']>;
  CheckingBy?: InputMaybe<Scalars['String']['input']>;
  CompanySpecificRisk?: InputMaybe<Scalars['Float']['input']>;
  ComparableCompanies?: InputMaybe<Array<InputMaybe<ComparableCompanyCreateOneInput>>>;
  Consideration?: InputMaybe<Scalars['Float']['input']>;
  Consideration100?: InputMaybe<Scalars['Float']['input']>;
  Currency?: InputMaybe<Scalars['String']['input']>;
  EVEBITDA?: InputMaybe<Scalars['Float']['input']>;
  EVS?: InputMaybe<Scalars['Float']['input']>;
  EnterpriseValue100?: InputMaybe<Scalars['Float']['input']>;
  EquityValue100?: InputMaybe<Scalars['Float']['input']>;
  GWIAGW?: InputMaybe<Scalars['Float']['input']>;
  Goodwill?: InputMaybe<Scalars['Float']['input']>;
  IAIAGW?: InputMaybe<Scalars['Float']['input']>;
  InCharge?: InputMaybe<Scalars['String']['input']>;
  Industry?: InputMaybe<Scalars['String']['input']>;
  IntangibleAssets?: InputMaybe<Scalars['Float']['input']>;
  IntangibleAssetsList?: InputMaybe<Array<InputMaybe<IntangibleAssetCreateOneInput>>>;
  LatestFullYearEBITDA?: InputMaybe<Scalars['Float']['input']>;
  LatestFullYearNetProfit?: InputMaybe<Scalars['Float']['input']>;
  LatestFullYearSales?: InputMaybe<Scalars['Float']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  ManagerInCharge?: InputMaybe<Scalars['String']['input']>;
  PE?: InputMaybe<Scalars['Float']['input']>;
  Remark?: InputMaybe<Scalars['String']['input']>;
  RoyaltyRate?: InputMaybe<Scalars['Float']['input']>;
  Sought?: InputMaybe<Scalars['Float']['input']>;
  TargetLocation?: InputMaybe<Scalars['String']['input']>;
  TargetName?: InputMaybe<Scalars['String']['input']>;
  Valuer?: InputMaybe<Scalars['String']['input']>;
  WACC?: InputMaybe<Scalars['Float']['input']>;
};

export type ProprietaryRow = {
  __typename?: 'ProprietaryRow';
  Acquirer?: Maybe<Scalars['String']['output']>;
  AcquirerStockCode?: Maybe<Scalars['String']['output']>;
  AcquisitionDate?: Maybe<Scalars['String']['output']>;
  BusinessDescription?: Maybe<Scalars['String']['output']>;
  CheckingBy?: Maybe<Scalars['String']['output']>;
  CompanySpecificRisk?: Maybe<Scalars['Float']['output']>;
  ComparableCompanies?: Maybe<Array<Maybe<ComparableCompany>>>;
  Consideration?: Maybe<Scalars['Float']['output']>;
  Consideration100?: Maybe<Scalars['Float']['output']>;
  Currency?: Maybe<Scalars['String']['output']>;
  EVEBITDA?: Maybe<Scalars['Float']['output']>;
  EVS?: Maybe<Scalars['Float']['output']>;
  EnterpriseValue100?: Maybe<Scalars['Float']['output']>;
  EquityValue100?: Maybe<Scalars['Float']['output']>;
  GWIAGW?: Maybe<Scalars['Float']['output']>;
  Goodwill?: Maybe<Scalars['Float']['output']>;
  IAIAGW?: Maybe<Scalars['Float']['output']>;
  InCharge?: Maybe<Scalars['String']['output']>;
  Industry?: Maybe<Scalars['String']['output']>;
  IntangibleAssets?: Maybe<Scalars['Float']['output']>;
  IntangibleAssetsList?: Maybe<Array<Maybe<IntangibleAsset>>>;
  LatestFullYearEBITDA?: Maybe<Scalars['Float']['output']>;
  LatestFullYearNetProfit?: Maybe<Scalars['Float']['output']>;
  LatestFullYearSales?: Maybe<Scalars['Float']['output']>;
  Link?: Maybe<Scalars['String']['output']>;
  ManagerInCharge?: Maybe<Scalars['String']['output']>;
  PE?: Maybe<Scalars['Float']['output']>;
  Remark?: Maybe<Scalars['String']['output']>;
  RoyaltyRate?: Maybe<Scalars['Float']['output']>;
  Sought?: Maybe<Scalars['Float']['output']>;
  TargetLocation?: Maybe<Scalars['String']['output']>;
  TargetName?: Maybe<Scalars['String']['output']>;
  Valuer?: Maybe<Scalars['String']['output']>;
  WACC?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
};

export type ProprietaryUpdateOneInput = {
  Acquirer?: InputMaybe<Scalars['String']['input']>;
  AcquirerStockCode?: InputMaybe<Scalars['String']['input']>;
  AcquisitionDate?: InputMaybe<Scalars['String']['input']>;
  BusinessDescription?: InputMaybe<Scalars['String']['input']>;
  CheckingBy?: InputMaybe<Scalars['String']['input']>;
  CompanySpecificRisk?: InputMaybe<Scalars['Float']['input']>;
  ComparableCompanies?: InputMaybe<Array<InputMaybe<ComparableCompanyUpdateOneInput>>>;
  Consideration?: InputMaybe<Scalars['Float']['input']>;
  Consideration100?: InputMaybe<Scalars['Float']['input']>;
  Currency?: InputMaybe<Scalars['String']['input']>;
  EVEBITDA?: InputMaybe<Scalars['Float']['input']>;
  EVS?: InputMaybe<Scalars['Float']['input']>;
  EnterpriseValue100?: InputMaybe<Scalars['Float']['input']>;
  EquityValue100?: InputMaybe<Scalars['Float']['input']>;
  GWIAGW?: InputMaybe<Scalars['Float']['input']>;
  Goodwill?: InputMaybe<Scalars['Float']['input']>;
  IAIAGW?: InputMaybe<Scalars['Float']['input']>;
  InCharge?: InputMaybe<Scalars['String']['input']>;
  Industry?: InputMaybe<Scalars['String']['input']>;
  IntangibleAssets?: InputMaybe<Scalars['Float']['input']>;
  IntangibleAssetsList?: InputMaybe<Array<InputMaybe<IntangibleAssetUpdateOneInput>>>;
  LatestFullYearEBITDA?: InputMaybe<Scalars['Float']['input']>;
  LatestFullYearNetProfit?: InputMaybe<Scalars['Float']['input']>;
  LatestFullYearSales?: InputMaybe<Scalars['Float']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  ManagerInCharge?: InputMaybe<Scalars['String']['input']>;
  PE?: InputMaybe<Scalars['Float']['input']>;
  Remark?: InputMaybe<Scalars['String']['input']>;
  RoyaltyRate?: InputMaybe<Scalars['Float']['input']>;
  Sought?: InputMaybe<Scalars['Float']['input']>;
  TargetLocation?: InputMaybe<Scalars['String']['input']>;
  TargetName?: InputMaybe<Scalars['String']['input']>;
  Valuer?: InputMaybe<Scalars['String']['input']>;
  WACC?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  bloombergExchangeRateByDate?: Maybe<BloombergExchangeRateValueRow>;
  effectiveInterestRates?: Maybe<EffectiveInterestRate>;
  exportManyBloombergBondYields?: Maybe<ExportedFile>;
  exportManyBloombergCpiAverages: ExportedFile;
  exportManyBloombergCpis: ExportedFile;
  exportManyBloombergExchangeRates?: Maybe<ExportedFile>;
  exportManyBloombergIndexesLendingRates?: Maybe<ExportedFile>;
  exportManyBloombergIndexesRiskFreeRates?: Maybe<ExportedFile>;
  extractKeywords: Array<Maybe<Scalars['String']['output']>>;
  getAllEffectiveInterestRate?: Maybe<Array<Maybe<EffectiveInterestRateRow>>>;
  getAllMarcoEconFactors?: Maybe<Array<Maybe<MarcoEconFactorRow>>>;
  getAllRecoveryRates?: Maybe<Array<Maybe<RecoveryRateRow>>>;
  getEffectiveInterestRateByDate?: Maybe<EffectiveInterestRateRow>;
  getIndustrialFactorYears?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  getIndustrialFactorsGroupKey?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getLastUpdate: LastUpdateRow;
  getManyBloombergBondYields?: Maybe<BloombergBondYields>;
  getManyBloombergCpiAverages: BloombergCpiAverages;
  getManyBloombergCpis: BloombergCpis;
  getManyBloombergExchangeRates?: Maybe<BloombergExchangeRates>;
  getManyBloombergIndexesLendingRates?: Maybe<BloombergIndexes>;
  getManyBloombergIndexesRiskFreeRates?: Maybe<BloombergIndexes>;
  getManyComparableCompanies?: Maybe<Array<ComparableCompany>>;
  getManyComparables: Comparables;
  getManyIntangibleAssets?: Maybe<Array<IntangibleAsset>>;
  getManyListedCompaniesByKeyword?: Maybe<ListedCompany>;
  getManyProprietaries: Proprietary;
  getManyResearchReports?: Maybe<ResearchReports>;
  getManyResearches: Research;
  getManyRoyaltyRates: RoyaltyRate;
  industrialFactor?: Maybe<IndustrialFactorRow>;
  industrialFactors?: Maybe<IndustrialFactor>;
  localhostGetDatesWithMultipleMissingData: BloombergExchangeRateMultiMissingInfo;
  localhostTravserseBloombergExchangeRate: Array<BloombergExchangeRateMissingInfo>;
  marcoEconFactors?: Maybe<MarcoEconFactor>;
  recoveryRates?: Maybe<RecoveryRate>;
};


export type QueryBloombergExchangeRateByDateArgs = {
  date: Scalars['DateTime']['input'];
};


export type QueryEffectiveInterestRatesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryExportManyBloombergBondYieldsArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  ratingFromFilter?: InputMaybe<Scalars['String']['input']>;
  ratingToFilter?: InputMaybe<Scalars['String']['input']>;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
  timeToMaturityMaxFilter?: InputMaybe<Scalars['Float']['input']>;
  timeToMaturityMinFilter?: InputMaybe<Scalars['Float']['input']>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryExportManyBloombergCpiAveragesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  periodFilter?: InputMaybe<Scalars['String']['input']>;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryExportManyBloombergCpisArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryExportManyBloombergExchangeRatesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryExportManyBloombergIndexesLendingRatesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryExportManyBloombergIndexesRiskFreeRatesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  regionFilter?: InputMaybe<Scalars['String']['input']>;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryExtractKeywordsArgs = {
  text: Scalars['String']['input'];
};


export type QueryGetEffectiveInterestRateByDateArgs = {
  date: Scalars['String']['input'];
  location: Scalars['String']['input'];
};


export type QueryGetIndustrialFactorsGroupKeyArgs = {
  groupBy: GroupFieldsIndustrialFactor;
  year: Scalars['Int']['input'];
};


export type QueryGetManyBloombergBondYieldsArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  ratingFromFilter?: InputMaybe<Scalars['String']['input']>;
  ratingToFilter?: InputMaybe<Scalars['String']['input']>;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
  timeToMaturityMaxFilter?: InputMaybe<Scalars['Float']['input']>;
  timeToMaturityMinFilter?: InputMaybe<Scalars['Float']['input']>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetManyBloombergCpiAveragesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  periodFilter?: InputMaybe<Scalars['String']['input']>;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetManyBloombergCpisArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetManyBloombergExchangeRatesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetManyBloombergIndexesLendingRatesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetManyBloombergIndexesRiskFreeRatesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  regionFilter?: InputMaybe<Scalars['String']['input']>;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetManyComparableCompaniesArgs = {
  parentId: Scalars['ID']['input'];
};


export type QueryGetManyComparablesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetManyIntangibleAssetsArgs = {
  parentId: Scalars['ID']['input'];
};


export type QueryGetManyListedCompaniesByKeywordArgs = {
  keyword: Scalars['String']['input'];
};


export type QueryGetManyProprietariesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetManyResearchReportsArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetManyResearchesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
  year: Scalars['String']['input'];
};


export type QueryGetManyRoyaltyRatesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryIndustrialFactorArgs = {
  industry: Scalars['String']['input'];
  location: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};


export type QueryIndustrialFactorsArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
  year?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLocalhostGetDatesWithMultipleMissingDataArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  threshold: Scalars['Int']['input'];
};


export type QueryLocalhostTravserseBloombergExchangeRateArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};


export type QueryMarcoEconFactorsArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryRecoveryRatesArgs = {
  columnsData: Array<ColumnsData>;
  keyword: Scalars['String']['input'];
  order: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  selectedFields: Array<InputMaybe<Scalars['String']['input']>>;
};

export type RecoveryRate = {
  __typename?: 'RecoveryRate';
  data?: Maybe<Array<RecoveryRateRow>>;
  total: Scalars['Int']['output'];
};

export type RecoveryRateCreateInput = {
  description: Scalars['String']['input'];
  recoveryRate: Scalars['Float']['input'];
  supporting?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type RecoveryRateInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recoveryRate: Scalars['Float']['input'];
  supporting?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type RecoveryRateRow = {
  __typename?: 'RecoveryRateRow';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  recoveryRate: Scalars['Float']['output'];
  supporting?: Maybe<Scalars['String']['output']>;
  year: Scalars['Int']['output'];
};

export type Research = {
  __typename?: 'Research';
  data?: Maybe<Array<ResearchRow>>;
  total: Scalars['Int']['output'];
};

export type ResearchCreateOneInput = {
  Adoptedstatisticaltype?: InputMaybe<Scalars['String']['input']>;
  CircularDate?: InputMaybe<Scalars['String']['input']>;
  CompanySpecificRiskPremium?: InputMaybe<Scalars['Float']['input']>;
  ComparableCompany?: InputMaybe<Scalars['String']['input']>;
  ControlPremium?: InputMaybe<Scalars['Float']['input']>;
  ControlPremiumSource?: InputMaybe<Scalars['String']['input']>;
  FinancialAdvisor?: InputMaybe<Scalars['String']['input']>;
  IndependentFinancialAdvisor?: InputMaybe<Scalars['String']['input']>;
  Industry?: InputMaybe<Scalars['String']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  ListCo?: InputMaybe<Scalars['String']['input']>;
  LoCD?: InputMaybe<Scalars['Float']['input']>;
  LoCDSource?: InputMaybe<Scalars['String']['input']>;
  LoMD1?: InputMaybe<Scalars['Float']['input']>;
  LoMD1Source?: InputMaybe<Scalars['String']['input']>;
  LoMD2?: InputMaybe<Scalars['Float']['input']>;
  LoMD2Source?: InputMaybe<Scalars['String']['input']>;
  Location?: InputMaybe<Scalars['String']['input']>;
  MarketApproachMultiple1?: InputMaybe<Scalars['String']['input']>;
  MarketApproachMultiple2?: InputMaybe<Scalars['String']['input']>;
  MarketApproachMultiple3?: InputMaybe<Scalars['String']['input']>;
  MarketApproachMultiple4?: InputMaybe<Scalars['String']['input']>;
  MarketApproachMultipleResult1?: InputMaybe<Scalars['Float']['input']>;
  MarketApproachMultipleResult2?: InputMaybe<Scalars['Float']['input']>;
  MarketApproachMultipleResult3?: InputMaybe<Scalars['Float']['input']>;
  MarketApproachMultipleResult4?: InputMaybe<Scalars['Float']['input']>;
  Remark?: InputMaybe<Scalars['String']['input']>;
  SelectedComparableCompaniesStockCode?: InputMaybe<Scalars['String']['input']>;
  StockCode?: InputMaybe<Scalars['String']['input']>;
  Target?: InputMaybe<Scalars['String']['input']>;
  TargetBusiness?: InputMaybe<Scalars['String']['input']>;
  TransactionType?: InputMaybe<Scalars['String']['input']>;
  ValuationApproach1?: InputMaybe<Scalars['String']['input']>;
  ValuationApproach2?: InputMaybe<Scalars['String']['input']>;
  ValuationReport?: InputMaybe<Scalars['String']['input']>;
  Valuer?: InputMaybe<Scalars['String']['input']>;
  WACC?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type ResearchId = {
  __typename?: 'ResearchId';
  id: Scalars['ID']['output'];
};

export type ResearchReportCreateOneInput = {
  companyName: Scalars['String']['input'];
  comparableCompanies?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['File']['input']>>;
  industries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResearchReportRow = {
  __typename?: 'ResearchReportRow';
  companyName?: Maybe<Scalars['String']['output']>;
  comparableCompanies?: Maybe<Array<Maybe<ListedCompanyRow>>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  files?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  industries?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  keywords?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ResearchReportUpdateOneInput = {
  companyName: Scalars['String']['input'];
  comparableCompanies?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  id: Scalars['ID']['input'];
  industries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResearchReports = {
  __typename?: 'ResearchReports';
  data?: Maybe<Array<ResearchReportRow>>;
  total: Scalars['Int']['output'];
};

export type ResearchRow = {
  __typename?: 'ResearchRow';
  Adoptedstatisticaltype?: Maybe<Scalars['String']['output']>;
  CircularDate?: Maybe<Scalars['String']['output']>;
  CompanySpecificRiskPremium?: Maybe<Scalars['Float']['output']>;
  ComparableCompany?: Maybe<Scalars['String']['output']>;
  ControlPremium?: Maybe<Scalars['Float']['output']>;
  ControlPremiumSource?: Maybe<Scalars['String']['output']>;
  FinancialAdvisor?: Maybe<Scalars['String']['output']>;
  IndependentFinancialAdvisor?: Maybe<Scalars['String']['output']>;
  Industry?: Maybe<Scalars['String']['output']>;
  Link?: Maybe<Scalars['String']['output']>;
  ListCo?: Maybe<Scalars['String']['output']>;
  LoCD?: Maybe<Scalars['Float']['output']>;
  LoCDSource?: Maybe<Scalars['String']['output']>;
  LoMD1?: Maybe<Scalars['Float']['output']>;
  LoMD1Source?: Maybe<Scalars['String']['output']>;
  LoMD2?: Maybe<Scalars['Float']['output']>;
  LoMD2Source?: Maybe<Scalars['String']['output']>;
  Location?: Maybe<Scalars['String']['output']>;
  MarketApproachMultiple1?: Maybe<Scalars['String']['output']>;
  MarketApproachMultiple2?: Maybe<Scalars['String']['output']>;
  MarketApproachMultiple3?: Maybe<Scalars['String']['output']>;
  MarketApproachMultiple4?: Maybe<Scalars['String']['output']>;
  MarketApproachMultipleResult1?: Maybe<Scalars['Float']['output']>;
  MarketApproachMultipleResult2?: Maybe<Scalars['Float']['output']>;
  MarketApproachMultipleResult3?: Maybe<Scalars['Float']['output']>;
  MarketApproachMultipleResult4?: Maybe<Scalars['Float']['output']>;
  Remark?: Maybe<Scalars['String']['output']>;
  SelectedComparableCompaniesStockCode?: Maybe<Scalars['String']['output']>;
  StockCode?: Maybe<Scalars['String']['output']>;
  Target?: Maybe<Scalars['String']['output']>;
  TargetBusiness?: Maybe<Scalars['String']['output']>;
  TransactionType?: Maybe<Scalars['String']['output']>;
  ValuationApproach1?: Maybe<Scalars['String']['output']>;
  ValuationApproach2?: Maybe<Scalars['String']['output']>;
  ValuationReport?: Maybe<Scalars['String']['output']>;
  Valuer?: Maybe<Scalars['String']['output']>;
  WACC?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  year?: Maybe<Scalars['String']['output']>;
};

export type ResearchUpdateOneInput = {
  Adoptedstatisticaltype?: InputMaybe<Scalars['String']['input']>;
  CircularDate?: InputMaybe<Scalars['String']['input']>;
  CompanySpecificRiskPremium?: InputMaybe<Scalars['Float']['input']>;
  ComparableCompany?: InputMaybe<Scalars['String']['input']>;
  ControlPremium?: InputMaybe<Scalars['Float']['input']>;
  ControlPremiumSource?: InputMaybe<Scalars['String']['input']>;
  FinancialAdvisor?: InputMaybe<Scalars['String']['input']>;
  IndependentFinancialAdvisor?: InputMaybe<Scalars['String']['input']>;
  Industry?: InputMaybe<Scalars['String']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  ListCo?: InputMaybe<Scalars['String']['input']>;
  LoCD?: InputMaybe<Scalars['Float']['input']>;
  LoCDSource?: InputMaybe<Scalars['String']['input']>;
  LoMD1?: InputMaybe<Scalars['Float']['input']>;
  LoMD1Source?: InputMaybe<Scalars['String']['input']>;
  LoMD2?: InputMaybe<Scalars['Float']['input']>;
  LoMD2Source?: InputMaybe<Scalars['String']['input']>;
  Location?: InputMaybe<Scalars['String']['input']>;
  MarketApproachMultiple1?: InputMaybe<Scalars['String']['input']>;
  MarketApproachMultiple2?: InputMaybe<Scalars['String']['input']>;
  MarketApproachMultiple3?: InputMaybe<Scalars['String']['input']>;
  MarketApproachMultiple4?: InputMaybe<Scalars['String']['input']>;
  MarketApproachMultipleResult1?: InputMaybe<Scalars['Float']['input']>;
  MarketApproachMultipleResult2?: InputMaybe<Scalars['Float']['input']>;
  MarketApproachMultipleResult3?: InputMaybe<Scalars['Float']['input']>;
  MarketApproachMultipleResult4?: InputMaybe<Scalars['Float']['input']>;
  Remark?: InputMaybe<Scalars['String']['input']>;
  SelectedComparableCompaniesStockCode?: InputMaybe<Scalars['String']['input']>;
  StockCode?: InputMaybe<Scalars['String']['input']>;
  Target?: InputMaybe<Scalars['String']['input']>;
  TargetBusiness?: InputMaybe<Scalars['String']['input']>;
  TransactionType?: InputMaybe<Scalars['String']['input']>;
  ValuationApproach1?: InputMaybe<Scalars['String']['input']>;
  ValuationApproach2?: InputMaybe<Scalars['String']['input']>;
  ValuationReport?: InputMaybe<Scalars['String']['input']>;
  Valuer?: InputMaybe<Scalars['String']['input']>;
  WACC?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  year?: InputMaybe<Scalars['String']['input']>;
};

export type RoyaltyRate = {
  __typename?: 'RoyaltyRate';
  data?: Maybe<Array<RoyaltyRateRow>>;
  total: Scalars['Int']['output'];
};

export type RoyaltyRateCreateOneInput = {
  CompensationDetail?: InputMaybe<Scalars['String']['input']>;
  DataSource?: InputMaybe<Scalars['String']['input']>;
  Industry?: InputMaybe<Scalars['String']['input']>;
  IntangibleAssetType?: InputMaybe<Scalars['String']['input']>;
  JobCode?: InputMaybe<Scalars['String']['input']>;
  LicensedPropertyDescription?: InputMaybe<Scalars['String']['input']>;
  Licensee?: InputMaybe<Scalars['String']['input']>;
  Licensor?: InputMaybe<Scalars['String']['input']>;
  RoyaltyRateHighRange?: InputMaybe<Scalars['Float']['input']>;
  RoyaltyRateLowRange?: InputMaybe<Scalars['Float']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  TargetsBusinessDescription?: InputMaybe<Scalars['String']['input']>;
  TargetsName?: InputMaybe<Scalars['String']['input']>;
  Year?: InputMaybe<Scalars['String']['input']>;
};

export type RoyaltyRateRow = {
  __typename?: 'RoyaltyRateRow';
  CompensationDetail?: Maybe<Scalars['String']['output']>;
  DataSource?: Maybe<Scalars['String']['output']>;
  Industry?: Maybe<Scalars['String']['output']>;
  IntangibleAssetType?: Maybe<Scalars['String']['output']>;
  JobCode?: Maybe<Scalars['String']['output']>;
  LicensedPropertyDescription?: Maybe<Scalars['String']['output']>;
  Licensee?: Maybe<Scalars['String']['output']>;
  Licensor?: Maybe<Scalars['String']['output']>;
  RoyaltyRateHighRange?: Maybe<Scalars['Float']['output']>;
  RoyaltyRateLowRange?: Maybe<Scalars['Float']['output']>;
  Source?: Maybe<Scalars['String']['output']>;
  TargetsBusinessDescription?: Maybe<Scalars['String']['output']>;
  TargetsName?: Maybe<Scalars['String']['output']>;
  Year?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type RoyaltyRateUpdateOneInput = {
  CompensationDetail?: InputMaybe<Scalars['String']['input']>;
  DataSource?: InputMaybe<Scalars['String']['input']>;
  Industry?: InputMaybe<Scalars['String']['input']>;
  IntangibleAssetType?: InputMaybe<Scalars['String']['input']>;
  JobCode?: InputMaybe<Scalars['String']['input']>;
  LicensedPropertyDescription?: InputMaybe<Scalars['String']['input']>;
  Licensee?: InputMaybe<Scalars['String']['input']>;
  Licensor?: InputMaybe<Scalars['String']['input']>;
  RoyaltyRateHighRange?: InputMaybe<Scalars['Float']['input']>;
  RoyaltyRateLowRange?: InputMaybe<Scalars['Float']['input']>;
  Source?: InputMaybe<Scalars['String']['input']>;
  TargetsBusinessDescription?: InputMaybe<Scalars['String']['input']>;
  TargetsName?: InputMaybe<Scalars['String']['input']>;
  Year?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type TickerData = {
  __typename?: 'TickerData';
  dataDate: Scalars['DateTime']['output'];
  dataValue: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  screenshotId?: Maybe<Scalars['ID']['output']>;
  ticker: Scalars['String']['output'];
};

export type ComparableCompanyFieldsFragment = { __typename?: 'ComparableCompany', id?: string | null, parentId?: string | null, company?: string | null, code?: string | null };

export type IntangibleAssetsFieldsFragment = { __typename?: 'IntangibleAsset', id?: string | null, parentId?: string | null, type?: string | null, amount?: number | null, rul?: string | null };

export type ResearchFieldsFragment = { __typename?: 'ResearchRow', id: string, year?: string | null, ListCo?: string | null, StockCode?: string | null, Target?: string | null, TargetBusiness?: string | null, Industry?: string | null, Location?: string | null, CircularDate?: string | null, TransactionType?: string | null, ValuationReport?: string | null, Valuer?: string | null, ValuationApproach1?: string | null, ValuationApproach2?: string | null, ComparableCompany?: string | null, WACC?: number | null, CompanySpecificRiskPremium?: number | null, LoMD1?: number | null, LoMD1Source?: string | null, LoMD2?: number | null, LoMD2Source?: string | null, LoCD?: number | null, LoCDSource?: string | null, ControlPremium?: number | null, ControlPremiumSource?: string | null, Adoptedstatisticaltype?: string | null, MarketApproachMultiple1?: string | null, MarketApproachMultipleResult1?: number | null, MarketApproachMultiple2?: string | null, MarketApproachMultipleResult2?: number | null, MarketApproachMultiple3?: string | null, MarketApproachMultipleResult3?: number | null, MarketApproachMultiple4?: string | null, MarketApproachMultipleResult4?: number | null, SelectedComparableCompaniesStockCode?: string | null, FinancialAdvisor?: string | null, IndependentFinancialAdvisor?: string | null, Remark?: string | null, Link?: string | null };

export type ComparableFieldsFragment = { __typename?: 'ComparableRow', id: string, Industry?: string | null, ListCo?: string | null, StockCode?: string | null, Target?: string | null, TargetBusiness?: string | null, Location?: string | null, AcquisitionDate?: string | null, Currency?: string | null, IntangibleAssets?: number | null, GoodwillFull?: number | null, IAIAGW?: number | null, GWIAGW?: number | null, PurchaseConsiderationRaw?: number | null, EquityinterestAcquired?: number | null, PurchaseConsiderationFull?: number | null, Remarks?: string | null, PageNo?: string | null, Link?: string | null, DoneBy?: string | null, IntangibleAssetsList?: Array<{ __typename?: 'IntangibleAsset', amount?: number | null, rul?: string | null, type?: string | null, id?: string | null } | null> | null };

export type RoyaltyFieldsFragment = { __typename?: 'RoyaltyRateRow', id: string, JobCode?: string | null, TargetsName?: string | null, TargetsBusinessDescription?: string | null, IntangibleAssetType?: string | null, Industry?: string | null, DataSource?: string | null, Licensee?: string | null, Licensor?: string | null, RoyaltyRateLowRange?: number | null, RoyaltyRateHighRange?: number | null, LicensedPropertyDescription?: string | null, CompensationDetail?: string | null, Source?: string | null, Year?: string | null };

export type ProprietaryFieldsFragment = { __typename?: 'ProprietaryRow', id: string, Industry?: string | null, AcquisitionDate?: string | null, TargetName?: string | null, BusinessDescription?: string | null, TargetLocation?: string | null, Currency?: string | null, Link?: string | null, Acquirer?: string | null, AcquirerStockCode?: string | null, IntangibleAssets?: number | null, Goodwill?: number | null, IAIAGW?: number | null, GWIAGW?: number | null, Consideration?: number | null, Sought?: number | null, Consideration100?: number | null, Valuer?: string | null, ManagerInCharge?: string | null, RoyaltyRate?: number | null, WACC?: number | null, CompanySpecificRisk?: number | null, EnterpriseValue100?: number | null, EquityValue100?: number | null, LatestFullYearNetProfit?: number | null, LatestFullYearEBITDA?: number | null, LatestFullYearSales?: number | null, PE?: number | null, EVEBITDA?: number | null, EVS?: number | null, Remark?: string | null, InCharge?: string | null, CheckingBy?: string | null, IntangibleAssetsList?: Array<{ __typename?: 'IntangibleAsset', id?: string | null, amount?: number | null, rul?: string | null, type?: string | null } | null> | null, ComparableCompanies?: Array<{ __typename?: 'ComparableCompany', id?: string | null, company?: string | null, code?: string | null } | null> | null };

export type IndustrialFactorFieldsFragment = { __typename?: 'IndustrialFactorRow', id: string, year: number, location?: string | null, industry?: string | null, factor: number, supporting?: string | null };

export type MarcoEconFactorFieldsFragment = { __typename?: 'MarcoEconFactorRow', id: string, year: number, description: string, factor: number, supporting?: string | null };

export type RecoveryRateFieldsFragment = { __typename?: 'RecoveryRateRow', id: string, year: number, description: string, recoveryRate: number, supporting?: string | null };

export type EffectiveInterestRateFieldsFragment = { __typename?: 'EffectiveInterestRateRow', id: string, location: string, date: string, interestRate: number };

export type BloombergIndexFieldsFragment = { __typename?: 'BloombergIndexRow', id: string, ticker: string, dataDate: Date, dataValue: number, index?: string | null, description?: string | null, type?: string | null, region?: string | null };

export type BloombergCpiFieldsFragment = { __typename?: 'BloombergCpiRow', dataDate: Date, CNCPIYOY_Index?: number | null, CPI_YOY_Index?: number | null, HKCPIY_Index?: number | null, CNCPIYOY_Index_screenshot?: string | null, CPI_YOY_Index_screenshot?: string | null, HKCPIY_Index_screenshot?: string | null };

export type BloombergCpiAverageFieldsFragment = { __typename?: 'BloombergCpiAverageRow', dataDate: Date, rangeInYear: number, period: string, CNCPIYOY_Index?: number | null, CPI_YOY_Index?: number | null, HKCPIY_Index?: number | null, CNCPIYOY_Index_id?: string | null, CPI_YOY_Index_id?: string | null, HKCPIY_Index_id?: string | null };

export type BloombergExchangeRateFieldsFragment = { __typename?: 'BloombergExchangeRateRow', dataDate: Date, USDCNY_BGNT_Curncy?: number | null, USDHKD_BGNT_Curncy?: number | null, CNYHKD_BGNT_Curncy?: number | null, EURHKD_BGNT_Curncy?: number | null, GBPHKD_BGNT_Curncy?: number | null, SGDHKD_BGNT_Curncy?: number | null, JPYHKD_BGNT_Curncy?: number | null, AUDHKD_BGNT_Curncy?: number | null, HKDTWD_BGNT_Curncy?: number | null, HKDKRW_BGNT_Curncy?: number | null, USDCNY_BGNT_Curncy_screenshot?: string | null, USDHKD_BGNT_Curncy_screenshot?: string | null, CNYHKD_BGNT_Curncy_screenshot?: string | null, EURHKD_BGNT_Curncy_screenshot?: string | null, GBPHKD_BGNT_Curncy_screenshot?: string | null, SGDHKD_BGNT_Curncy_screenshot?: string | null, JPYHKD_BGNT_Curncy_screenshot?: string | null, AUDHKD_BGNT_Curncy_screenshot?: string | null, HKDTWD_BGNT_Curncy_screenshot?: string | null, HKDKRW_BGNT_Curncy_screenshot?: string | null };

export type BloombergBondYieldFieldsFragment = { __typename?: 'BloombergBondYieldRow', id: string, ticker: string, issuerName: string, issueDate: string, biscLevel2?: string | null, isin: string, yieldToMaturityMid?: string | null, spRating?: string | null, spIssuerRating?: string | null, cpn?: string | null, maturity?: string | null, timeToMaturity?: number | null, series?: string | null, bbg?: string | null, mtyType?: string | null, currency?: string | null, valuationDate: Date, screenshotId?: string | null };

export type ExportedFileFieldsFragment = { __typename?: 'ExportedFile', url?: string | null };

export type IssueFieldsFragment = { __typename?: 'IssueRow', id: string, title: string, description?: string | null, status: string, createdAt: any, createdBy: string };

export type GetManyResearchesQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  year: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type GetManyResearchesQuery = { __typename?: 'Query', getManyResearches: { __typename?: 'Research', total: number, data?: Array<{ __typename?: 'ResearchRow', id: string, year?: string | null, ListCo?: string | null, StockCode?: string | null, Target?: string | null, TargetBusiness?: string | null, Industry?: string | null, Location?: string | null, CircularDate?: string | null, TransactionType?: string | null, ValuationReport?: string | null, Valuer?: string | null, ValuationApproach1?: string | null, ValuationApproach2?: string | null, ComparableCompany?: string | null, WACC?: number | null, CompanySpecificRiskPremium?: number | null, LoMD1?: number | null, LoMD1Source?: string | null, LoMD2?: number | null, LoMD2Source?: string | null, LoCD?: number | null, LoCDSource?: string | null, ControlPremium?: number | null, ControlPremiumSource?: string | null, Adoptedstatisticaltype?: string | null, MarketApproachMultiple1?: string | null, MarketApproachMultipleResult1?: number | null, MarketApproachMultiple2?: string | null, MarketApproachMultipleResult2?: number | null, MarketApproachMultiple3?: string | null, MarketApproachMultipleResult3?: number | null, MarketApproachMultiple4?: string | null, MarketApproachMultipleResult4?: number | null, SelectedComparableCompaniesStockCode?: string | null, FinancialAdvisor?: string | null, IndependentFinancialAdvisor?: string | null, Remark?: string | null, Link?: string | null }> | null } };

export type CreateOneResearchMutationVariables = Exact<{
  data: ResearchCreateOneInput;
}>;


export type CreateOneResearchMutation = { __typename?: 'Mutation', createOneResearch?: { __typename?: 'ResearchRow', id: string, year?: string | null, ListCo?: string | null, StockCode?: string | null, Target?: string | null, TargetBusiness?: string | null, Industry?: string | null, Location?: string | null, CircularDate?: string | null, TransactionType?: string | null, ValuationReport?: string | null, Valuer?: string | null, ValuationApproach1?: string | null, ValuationApproach2?: string | null, ComparableCompany?: string | null, WACC?: number | null, CompanySpecificRiskPremium?: number | null, LoMD1?: number | null, LoMD1Source?: string | null, LoMD2?: number | null, LoMD2Source?: string | null, LoCD?: number | null, LoCDSource?: string | null, ControlPremium?: number | null, ControlPremiumSource?: string | null, Adoptedstatisticaltype?: string | null, MarketApproachMultiple1?: string | null, MarketApproachMultipleResult1?: number | null, MarketApproachMultiple2?: string | null, MarketApproachMultipleResult2?: number | null, MarketApproachMultiple3?: string | null, MarketApproachMultipleResult3?: number | null, MarketApproachMultiple4?: string | null, MarketApproachMultipleResult4?: number | null, SelectedComparableCompaniesStockCode?: string | null, FinancialAdvisor?: string | null, IndependentFinancialAdvisor?: string | null, Remark?: string | null, Link?: string | null } | null };

export type UpdateOneResearchMutationVariables = Exact<{
  data: ResearchUpdateOneInput;
}>;


export type UpdateOneResearchMutation = { __typename?: 'Mutation', updateOneResearch?: { __typename?: 'ResearchRow', id: string, year?: string | null, ListCo?: string | null, StockCode?: string | null, Target?: string | null, TargetBusiness?: string | null, Industry?: string | null, Location?: string | null, CircularDate?: string | null, TransactionType?: string | null, ValuationReport?: string | null, Valuer?: string | null, ValuationApproach1?: string | null, ValuationApproach2?: string | null, ComparableCompany?: string | null, WACC?: number | null, CompanySpecificRiskPremium?: number | null, LoMD1?: number | null, LoMD1Source?: string | null, LoMD2?: number | null, LoMD2Source?: string | null, LoCD?: number | null, LoCDSource?: string | null, ControlPremium?: number | null, ControlPremiumSource?: string | null, Adoptedstatisticaltype?: string | null, MarketApproachMultiple1?: string | null, MarketApproachMultipleResult1?: number | null, MarketApproachMultiple2?: string | null, MarketApproachMultipleResult2?: number | null, MarketApproachMultiple3?: string | null, MarketApproachMultipleResult3?: number | null, MarketApproachMultiple4?: string | null, MarketApproachMultipleResult4?: number | null, SelectedComparableCompaniesStockCode?: string | null, FinancialAdvisor?: string | null, IndependentFinancialAdvisor?: string | null, Remark?: string | null, Link?: string | null } | null };

export type DeleteOneResearchMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteOneResearchMutation = { __typename?: 'Mutation', deleteOneResearch?: { __typename?: 'ResearchRow', id: string } | null };

export type GetLastUpdateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLastUpdateQuery = { __typename?: 'Query', getLastUpdate: { __typename?: 'LastUpdateRow', CIRCULAR_RESEARCH?: string | null, COMPARABLE_PPA?: string | null, ROYALTY_RATE?: string | null, PROPRIETARY_PPA?: string | null, MARCO_ECON_FACTOR?: string | null, INDUSTRIAL_FACTOR?: string | null, RECOVERY_RATES?: string | null } };

export type GetManyComparablesQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type GetManyComparablesQuery = { __typename?: 'Query', getManyComparables: { __typename?: 'Comparables', total: number, data?: Array<{ __typename?: 'ComparableRow', id: string, Industry?: string | null, ListCo?: string | null, StockCode?: string | null, Target?: string | null, TargetBusiness?: string | null, Location?: string | null, AcquisitionDate?: string | null, Currency?: string | null, IntangibleAssets?: number | null, GoodwillFull?: number | null, IAIAGW?: number | null, GWIAGW?: number | null, PurchaseConsiderationRaw?: number | null, EquityinterestAcquired?: number | null, PurchaseConsiderationFull?: number | null, Remarks?: string | null, PageNo?: string | null, Link?: string | null, DoneBy?: string | null, IntangibleAssetsList?: Array<{ __typename?: 'IntangibleAsset', amount?: number | null, rul?: string | null, type?: string | null, id?: string | null } | null> | null }> | null } };

export type CreateOneComparableMutationVariables = Exact<{
  data: ComparableCreateOneInput;
}>;


export type CreateOneComparableMutation = { __typename?: 'Mutation', createOneComparable?: { __typename?: 'ComparableRow', id: string, Industry?: string | null, ListCo?: string | null, StockCode?: string | null, Target?: string | null, TargetBusiness?: string | null, Location?: string | null, AcquisitionDate?: string | null, Currency?: string | null, IntangibleAssets?: number | null, GoodwillFull?: number | null, IAIAGW?: number | null, GWIAGW?: number | null, PurchaseConsiderationRaw?: number | null, EquityinterestAcquired?: number | null, PurchaseConsiderationFull?: number | null, Remarks?: string | null, PageNo?: string | null, Link?: string | null, DoneBy?: string | null, IntangibleAssetsList?: Array<{ __typename?: 'IntangibleAsset', amount?: number | null, rul?: string | null, type?: string | null, id?: string | null } | null> | null } | null };

export type UpdateOneComparableMutationVariables = Exact<{
  data: ComparableUpdateOneInput;
}>;


export type UpdateOneComparableMutation = { __typename?: 'Mutation', updateOneComparable?: { __typename?: 'ComparableRow', id: string, Industry?: string | null, ListCo?: string | null, StockCode?: string | null, Target?: string | null, TargetBusiness?: string | null, Location?: string | null, AcquisitionDate?: string | null, Currency?: string | null, IntangibleAssets?: number | null, GoodwillFull?: number | null, IAIAGW?: number | null, GWIAGW?: number | null, PurchaseConsiderationRaw?: number | null, EquityinterestAcquired?: number | null, PurchaseConsiderationFull?: number | null, Remarks?: string | null, PageNo?: string | null, Link?: string | null, DoneBy?: string | null, IntangibleAssetsList?: Array<{ __typename?: 'IntangibleAsset', amount?: number | null, rul?: string | null, type?: string | null, id?: string | null } | null> | null } | null };

export type UpdateManyComparablesByImportFileMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UpdateManyComparablesByImportFileMutation = { __typename?: 'Mutation', updateManyComparablesByImportFile?: Array<{ __typename?: 'ComparableRow', id: string, Industry?: string | null, ListCo?: string | null, StockCode?: string | null, Target?: string | null, TargetBusiness?: string | null, Location?: string | null, AcquisitionDate?: string | null, Currency?: string | null, IntangibleAssets?: number | null, GoodwillFull?: number | null, IAIAGW?: number | null, GWIAGW?: number | null, PurchaseConsiderationRaw?: number | null, EquityinterestAcquired?: number | null, PurchaseConsiderationFull?: number | null, Remarks?: string | null, PageNo?: string | null, Link?: string | null, DoneBy?: string | null, IntangibleAssetsList?: Array<{ __typename?: 'IntangibleAsset', amount?: number | null, rul?: string | null, type?: string | null, id?: string | null } | null> | null } | null> | null };

export type DeleteOneComparableMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteOneComparableMutation = { __typename?: 'Mutation', deleteOneComparable?: { __typename?: 'ComparableRow', id: string } | null };

export type GetManyRoyaltyRatesQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type GetManyRoyaltyRatesQuery = { __typename?: 'Query', getManyRoyaltyRates: { __typename?: 'RoyaltyRate', total: number, data?: Array<{ __typename?: 'RoyaltyRateRow', id: string, JobCode?: string | null, TargetsName?: string | null, TargetsBusinessDescription?: string | null, IntangibleAssetType?: string | null, Industry?: string | null, DataSource?: string | null, Licensee?: string | null, Licensor?: string | null, RoyaltyRateLowRange?: number | null, RoyaltyRateHighRange?: number | null, LicensedPropertyDescription?: string | null, CompensationDetail?: string | null, Source?: string | null, Year?: string | null }> | null } };

export type CreateOneRoyaltyRateMutationVariables = Exact<{
  data: RoyaltyRateCreateOneInput;
}>;


export type CreateOneRoyaltyRateMutation = { __typename?: 'Mutation', createOneRoyaltyRate?: { __typename?: 'RoyaltyRateRow', id: string, JobCode?: string | null, TargetsName?: string | null, TargetsBusinessDescription?: string | null, IntangibleAssetType?: string | null, Industry?: string | null, DataSource?: string | null, Licensee?: string | null, Licensor?: string | null, RoyaltyRateLowRange?: number | null, RoyaltyRateHighRange?: number | null, LicensedPropertyDescription?: string | null, CompensationDetail?: string | null, Source?: string | null, Year?: string | null } | null };

export type UpdateOneRoyaltyRateMutationVariables = Exact<{
  data: RoyaltyRateUpdateOneInput;
}>;


export type UpdateOneRoyaltyRateMutation = { __typename?: 'Mutation', updateOneRoyaltyRate?: { __typename?: 'RoyaltyRateRow', id: string, JobCode?: string | null, TargetsName?: string | null, TargetsBusinessDescription?: string | null, IntangibleAssetType?: string | null, Industry?: string | null, DataSource?: string | null, Licensee?: string | null, Licensor?: string | null, RoyaltyRateLowRange?: number | null, RoyaltyRateHighRange?: number | null, LicensedPropertyDescription?: string | null, CompensationDetail?: string | null, Source?: string | null, Year?: string | null } | null };

export type DeleteOneRoyaltyRateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteOneRoyaltyRateMutation = { __typename?: 'Mutation', deleteOneRoyaltyRate?: { __typename?: 'RoyaltyRateRow', id: string } | null };

export type GetManyProprietariesQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type GetManyProprietariesQuery = { __typename?: 'Query', getManyProprietaries: { __typename?: 'Proprietary', total: number, data?: Array<{ __typename?: 'ProprietaryRow', id: string, Industry?: string | null, AcquisitionDate?: string | null, TargetName?: string | null, BusinessDescription?: string | null, TargetLocation?: string | null, Currency?: string | null, Link?: string | null, Acquirer?: string | null, AcquirerStockCode?: string | null, IntangibleAssets?: number | null, Goodwill?: number | null, IAIAGW?: number | null, GWIAGW?: number | null, Consideration?: number | null, Sought?: number | null, Consideration100?: number | null, Valuer?: string | null, ManagerInCharge?: string | null, RoyaltyRate?: number | null, WACC?: number | null, CompanySpecificRisk?: number | null, EnterpriseValue100?: number | null, EquityValue100?: number | null, LatestFullYearNetProfit?: number | null, LatestFullYearEBITDA?: number | null, LatestFullYearSales?: number | null, PE?: number | null, EVEBITDA?: number | null, EVS?: number | null, Remark?: string | null, InCharge?: string | null, CheckingBy?: string | null, IntangibleAssetsList?: Array<{ __typename?: 'IntangibleAsset', id?: string | null, amount?: number | null, rul?: string | null, type?: string | null } | null> | null, ComparableCompanies?: Array<{ __typename?: 'ComparableCompany', id?: string | null, company?: string | null, code?: string | null } | null> | null }> | null } };

export type CreateOneProprietaryMutationVariables = Exact<{
  data: ProprietaryCreateOneInput;
}>;


export type CreateOneProprietaryMutation = { __typename?: 'Mutation', createOneProprietary?: { __typename?: 'ProprietaryRow', id: string, Industry?: string | null, AcquisitionDate?: string | null, TargetName?: string | null, BusinessDescription?: string | null, TargetLocation?: string | null, Currency?: string | null, Link?: string | null, Acquirer?: string | null, AcquirerStockCode?: string | null, IntangibleAssets?: number | null, Goodwill?: number | null, IAIAGW?: number | null, GWIAGW?: number | null, Consideration?: number | null, Sought?: number | null, Consideration100?: number | null, Valuer?: string | null, ManagerInCharge?: string | null, RoyaltyRate?: number | null, WACC?: number | null, CompanySpecificRisk?: number | null, EnterpriseValue100?: number | null, EquityValue100?: number | null, LatestFullYearNetProfit?: number | null, LatestFullYearEBITDA?: number | null, LatestFullYearSales?: number | null, PE?: number | null, EVEBITDA?: number | null, EVS?: number | null, Remark?: string | null, InCharge?: string | null, CheckingBy?: string | null, IntangibleAssetsList?: Array<{ __typename?: 'IntangibleAsset', id?: string | null, amount?: number | null, rul?: string | null, type?: string | null } | null> | null, ComparableCompanies?: Array<{ __typename?: 'ComparableCompany', id?: string | null, company?: string | null, code?: string | null } | null> | null } | null };

export type UpdateOneProprietaryMutationVariables = Exact<{
  data: ProprietaryUpdateOneInput;
}>;


export type UpdateOneProprietaryMutation = { __typename?: 'Mutation', updateOneProprietary?: { __typename?: 'ProprietaryRow', id: string, Industry?: string | null, AcquisitionDate?: string | null, TargetName?: string | null, BusinessDescription?: string | null, TargetLocation?: string | null, Currency?: string | null, Link?: string | null, Acquirer?: string | null, AcquirerStockCode?: string | null, IntangibleAssets?: number | null, Goodwill?: number | null, IAIAGW?: number | null, GWIAGW?: number | null, Consideration?: number | null, Sought?: number | null, Consideration100?: number | null, Valuer?: string | null, ManagerInCharge?: string | null, RoyaltyRate?: number | null, WACC?: number | null, CompanySpecificRisk?: number | null, EnterpriseValue100?: number | null, EquityValue100?: number | null, LatestFullYearNetProfit?: number | null, LatestFullYearEBITDA?: number | null, LatestFullYearSales?: number | null, PE?: number | null, EVEBITDA?: number | null, EVS?: number | null, Remark?: string | null, InCharge?: string | null, CheckingBy?: string | null, IntangibleAssetsList?: Array<{ __typename?: 'IntangibleAsset', id?: string | null, amount?: number | null, rul?: string | null, type?: string | null } | null> | null, ComparableCompanies?: Array<{ __typename?: 'ComparableCompany', id?: string | null, company?: string | null, code?: string | null } | null> | null } | null };

export type UpdateManyProprietariesByImportFileMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UpdateManyProprietariesByImportFileMutation = { __typename?: 'Mutation', updateManyProprietariesByImportFile?: Array<{ __typename?: 'ProprietaryRow', id: string, Industry?: string | null, AcquisitionDate?: string | null, TargetName?: string | null, BusinessDescription?: string | null, TargetLocation?: string | null, Currency?: string | null, Link?: string | null, Acquirer?: string | null, AcquirerStockCode?: string | null, IntangibleAssets?: number | null, Goodwill?: number | null, IAIAGW?: number | null, GWIAGW?: number | null, Consideration?: number | null, Sought?: number | null, Consideration100?: number | null, Valuer?: string | null, ManagerInCharge?: string | null, RoyaltyRate?: number | null, WACC?: number | null, CompanySpecificRisk?: number | null, EnterpriseValue100?: number | null, EquityValue100?: number | null, LatestFullYearNetProfit?: number | null, LatestFullYearEBITDA?: number | null, LatestFullYearSales?: number | null, PE?: number | null, EVEBITDA?: number | null, EVS?: number | null, Remark?: string | null, InCharge?: string | null, CheckingBy?: string | null, IntangibleAssetsList?: Array<{ __typename?: 'IntangibleAsset', id?: string | null, amount?: number | null, rul?: string | null, type?: string | null } | null> | null, ComparableCompanies?: Array<{ __typename?: 'ComparableCompany', id?: string | null, company?: string | null, code?: string | null } | null> | null } | null> | null };

export type DeleteOneProprietaryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteOneProprietaryMutation = { __typename?: 'Mutation', deleteOneProprietary?: { __typename?: 'ProprietaryRow', id: string } | null };

export type IndustrialFactorQueryVariables = Exact<{
  year: Scalars['Int']['input'];
  location: Scalars['String']['input'];
  industry: Scalars['String']['input'];
}>;


export type IndustrialFactorQuery = { __typename?: 'Query', industrialFactor?: { __typename?: 'IndustrialFactorRow', id: string, year: number, location?: string | null, industry?: string | null, factor: number, supporting?: string | null } | null };

export type IndustrialFactorsQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
}>;


export type IndustrialFactorsQuery = { __typename?: 'Query', industrialFactors?: { __typename?: 'IndustrialFactor', total: number, data?: Array<{ __typename?: 'IndustrialFactorRow', id: string, year: number, location?: string | null, industry?: string | null, factor: number, supporting?: string | null }> | null } | null };

export type GetIndustrialFactorsYearsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIndustrialFactorsYearsQuery = { __typename?: 'Query', getIndustrialFactorYears?: Array<number | null> | null };

export type MarcoEconFactorsQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type MarcoEconFactorsQuery = { __typename?: 'Query', marcoEconFactors?: { __typename?: 'MarcoEconFactor', total: number, data?: Array<{ __typename?: 'MarcoEconFactorRow', id: string, year: number, description: string, factor: number, supporting?: string | null }> | null } | null };

export type RecoveryRatesQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type RecoveryRatesQuery = { __typename?: 'Query', recoveryRates?: { __typename?: 'RecoveryRate', total: number, data?: Array<{ __typename?: 'RecoveryRateRow', id: string, year: number, description: string, recoveryRate: number, supporting?: string | null }> | null } | null };

export type GetManyComparableCompaniesQueryVariables = Exact<{
  parentId: Scalars['ID']['input'];
}>;


export type GetManyComparableCompaniesQuery = { __typename?: 'Query', getManyComparableCompanies?: Array<{ __typename?: 'ComparableCompany', id?: string | null, parentId?: string | null, company?: string | null, code?: string | null }> | null };

export type GetManyIntangibleAssetsQueryVariables = Exact<{
  parentId: Scalars['ID']['input'];
}>;


export type GetManyIntangibleAssetsQuery = { __typename?: 'Query', getManyIntangibleAssets?: Array<{ __typename?: 'IntangibleAsset', id?: string | null, parentId?: string | null, type?: string | null, amount?: number | null, rul?: string | null }> | null };

export type UpsertAndDeleteManyIntangibleAssetsMutationVariables = Exact<{
  data: Array<InputMaybe<IntangibleAssetUpdateOneInput>> | InputMaybe<IntangibleAssetUpdateOneInput>;
}>;


export type UpsertAndDeleteManyIntangibleAssetsMutation = { __typename?: 'Mutation', upsertAndDeleteManyIntangibleAssets?: { __typename?: 'IntangibleAsset', id?: string | null, parentId?: string | null, type?: string | null, amount?: number | null, rul?: string | null } | null };

export type UpsertAndDeleteManyComparableCompaniesMutationVariables = Exact<{
  data: Array<InputMaybe<ComparableCompanyUpdateOneInput>> | InputMaybe<ComparableCompanyUpdateOneInput>;
}>;


export type UpsertAndDeleteManyComparableCompaniesMutation = { __typename?: 'Mutation', upsertAndDeleteManyComparableCompanies?: { __typename?: 'ComparableCompany', id?: string | null, parentId?: string | null, company?: string | null, code?: string | null } | null };

export type EffectiveInterestRatesQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type EffectiveInterestRatesQuery = { __typename?: 'Query', effectiveInterestRates?: { __typename?: 'EffectiveInterestRate', total: number, data?: Array<{ __typename?: 'EffectiveInterestRateRow', id: string, location: string, date: string, interestRate: number }> | null } | null };

export type GetManyBloombergIndexesLendingRatesQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetManyBloombergIndexesLendingRatesQuery = { __typename?: 'Query', getManyBloombergIndexesLendingRates?: { __typename?: 'BloombergIndexes', total: number, data?: Array<{ __typename?: 'BloombergIndexRow', id: string, ticker: string, dataDate: Date, dataValue: number, index?: string | null, description?: string | null, type?: string | null, region?: string | null }> | null } | null };

export type GetManyBloombergIndexesRiskFreeRatesQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
  regionFilter?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetManyBloombergIndexesRiskFreeRatesQuery = { __typename?: 'Query', getManyBloombergIndexesRiskFreeRates?: { __typename?: 'BloombergIndexes', total: number, data?: Array<{ __typename?: 'BloombergIndexRow', id: string, ticker: string, dataDate: Date, dataValue: number, index?: string | null, description?: string | null, type?: string | null, region?: string | null }> | null } | null };

export type ExportManyBloombergIndexesLendingRatesQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
}>;


export type ExportManyBloombergIndexesLendingRatesQuery = { __typename?: 'Query', exportManyBloombergIndexesLendingRates?: { __typename?: 'ExportedFile', url?: string | null } | null };

export type ExportManyBloombergIndexesRiskFreeRatesQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
  regionFilter?: InputMaybe<Scalars['String']['input']>;
}>;


export type ExportManyBloombergIndexesRiskFreeRatesQuery = { __typename?: 'Query', exportManyBloombergIndexesRiskFreeRates?: { __typename?: 'ExportedFile', url?: string | null } | null };

export type GetManyBloombergCpisQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type GetManyBloombergCpisQuery = { __typename?: 'Query', getManyBloombergCpis: { __typename?: 'BloombergCpis', total: number, data?: Array<{ __typename?: 'BloombergCpiRow', dataDate: Date, CNCPIYOY_Index?: number | null, CPI_YOY_Index?: number | null, HKCPIY_Index?: number | null, CNCPIYOY_Index_screenshot?: string | null, CPI_YOY_Index_screenshot?: string | null, HKCPIY_Index_screenshot?: string | null }> | null } };

export type ExportManyBloombergCpisQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type ExportManyBloombergCpisQuery = { __typename?: 'Query', exportManyBloombergCpis: { __typename?: 'ExportedFile', url?: string | null } };

export type GetManyBloombergCpiAveragesQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  periodFilter?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetManyBloombergCpiAveragesQuery = { __typename?: 'Query', getManyBloombergCpiAverages: { __typename?: 'BloombergCpiAverages', total: number, data?: Array<{ __typename?: 'BloombergCpiAverageRow', dataDate: Date, rangeInYear: number, period: string, CNCPIYOY_Index?: number | null, CPI_YOY_Index?: number | null, HKCPIY_Index?: number | null, CNCPIYOY_Index_id?: string | null, CPI_YOY_Index_id?: string | null, HKCPIY_Index_id?: string | null }> | null } };

export type ExportManyBloombergCpiAveragesQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  periodFilter?: InputMaybe<Scalars['String']['input']>;
}>;


export type ExportManyBloombergCpiAveragesQuery = { __typename?: 'Query', exportManyBloombergCpiAverages: { __typename?: 'ExportedFile', url?: string | null } };

export type GetManyBloombergExchangeRatesQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type GetManyBloombergExchangeRatesQuery = { __typename?: 'Query', getManyBloombergExchangeRates?: { __typename?: 'BloombergExchangeRates', total: number, data?: Array<{ __typename?: 'BloombergExchangeRateRow', dataDate: Date, USDCNY_BGNT_Curncy?: number | null, USDHKD_BGNT_Curncy?: number | null, CNYHKD_BGNT_Curncy?: number | null, EURHKD_BGNT_Curncy?: number | null, GBPHKD_BGNT_Curncy?: number | null, SGDHKD_BGNT_Curncy?: number | null, JPYHKD_BGNT_Curncy?: number | null, AUDHKD_BGNT_Curncy?: number | null, HKDTWD_BGNT_Curncy?: number | null, HKDKRW_BGNT_Curncy?: number | null, USDCNY_BGNT_Curncy_screenshot?: string | null, USDHKD_BGNT_Curncy_screenshot?: string | null, CNYHKD_BGNT_Curncy_screenshot?: string | null, EURHKD_BGNT_Curncy_screenshot?: string | null, GBPHKD_BGNT_Curncy_screenshot?: string | null, SGDHKD_BGNT_Curncy_screenshot?: string | null, JPYHKD_BGNT_Curncy_screenshot?: string | null, AUDHKD_BGNT_Curncy_screenshot?: string | null, HKDTWD_BGNT_Curncy_screenshot?: string | null, HKDKRW_BGNT_Curncy_screenshot?: string | null }> | null } | null };

export type ExportManyBloombergExchangeRatesQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type ExportManyBloombergExchangeRatesQuery = { __typename?: 'Query', exportManyBloombergExchangeRates?: { __typename?: 'ExportedFile', url?: string | null } | null };

export type GetManyBloombergBondYieldsQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
  ratingFromFilter?: InputMaybe<Scalars['String']['input']>;
  ratingToFilter?: InputMaybe<Scalars['String']['input']>;
  timeToMaturityMinFilter?: InputMaybe<Scalars['Float']['input']>;
  timeToMaturityMaxFilter?: InputMaybe<Scalars['Float']['input']>;
}>;


export type GetManyBloombergBondYieldsQuery = { __typename?: 'Query', getManyBloombergBondYields?: { __typename?: 'BloombergBondYields', total: number, data?: Array<{ __typename?: 'BloombergBondYieldRow', id: string, ticker: string, issuerName: string, issueDate: string, biscLevel2?: string | null, isin: string, yieldToMaturityMid?: string | null, spRating?: string | null, spIssuerRating?: string | null, cpn?: string | null, maturity?: string | null, timeToMaturity?: number | null, series?: string | null, bbg?: string | null, mtyType?: string | null, currency?: string | null, valuationDate: Date, screenshotId?: string | null }> | null } | null };

export type ExportManyBloombergBondYieldsQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  valuationDateFilter?: InputMaybe<Scalars['String']['input']>;
  ratingFromFilter?: InputMaybe<Scalars['String']['input']>;
  ratingToFilter?: InputMaybe<Scalars['String']['input']>;
  timeToMaturityMinFilter?: InputMaybe<Scalars['Float']['input']>;
  timeToMaturityMaxFilter?: InputMaybe<Scalars['Float']['input']>;
}>;


export type ExportManyBloombergBondYieldsQuery = { __typename?: 'Query', exportManyBloombergBondYields?: { __typename?: 'ExportedFile', url?: string | null } | null };

export type CreateOneIssueMutationVariables = Exact<{
  title: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateOneIssueMutation = { __typename?: 'Mutation', createOneIssue?: { __typename?: 'IssueRow', id: string, title: string, description?: string | null, status: string, createdAt: any, createdBy: string } | null };

export type ResearchReportFieldsFragment = { __typename?: 'ResearchReportRow', id: string, companyName?: string | null, description?: string | null, keywords?: Array<string | null> | null, industries?: Array<string | null> | null, files?: Array<string | null> | null, updatedAt?: any | null, comparableCompanies?: Array<{ __typename?: 'ListedCompanyRow', id: string, name: string, stockExchange?: string | null, ticker: string } | null> | null };

export type GetManyResearchReportsQueryVariables = Exact<{
  pageSize: Scalars['Int']['input'];
  pageIndex: Scalars['Int']['input'];
  keyword: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  order: Scalars['String']['input'];
  columnsData: Array<ColumnsData> | ColumnsData;
  selectedFields: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;


export type GetManyResearchReportsQuery = { __typename?: 'Query', getManyResearchReports?: { __typename?: 'ResearchReports', total: number, data?: Array<{ __typename?: 'ResearchReportRow', id: string, companyName?: string | null, description?: string | null, keywords?: Array<string | null> | null, industries?: Array<string | null> | null, files?: Array<string | null> | null, updatedAt?: any | null, comparableCompanies?: Array<{ __typename?: 'ListedCompanyRow', id: string, name: string, stockExchange?: string | null, ticker: string } | null> | null }> | null } | null };

export type UpdateOneResearchReportMutationVariables = Exact<{
  data: ResearchReportUpdateOneInput;
}>;


export type UpdateOneResearchReportMutation = { __typename?: 'Mutation', updateOneResearchReport?: { __typename?: 'ResearchReportRow', id: string, companyName?: string | null, description?: string | null, keywords?: Array<string | null> | null, industries?: Array<string | null> | null, files?: Array<string | null> | null, updatedAt?: any | null, comparableCompanies?: Array<{ __typename?: 'ListedCompanyRow', id: string, name: string, stockExchange?: string | null, ticker: string } | null> | null } | null };

export type DeleteOneResearchReportMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteOneResearchReportMutation = { __typename?: 'Mutation', deleteOneResearchReport?: { __typename?: 'ResearchReportRow', id: string } | null };

export type CreateOneResearchReportMutationVariables = Exact<{
  data: ResearchReportCreateOneInput;
}>;


export type CreateOneResearchReportMutation = { __typename?: 'Mutation', createOneResearchReport?: { __typename?: 'ResearchReportRow', id: string, companyName?: string | null, description?: string | null, keywords?: Array<string | null> | null, industries?: Array<string | null> | null, files?: Array<string | null> | null, updatedAt?: any | null, comparableCompanies?: Array<{ __typename?: 'ListedCompanyRow', id: string, name: string, stockExchange?: string | null, ticker: string } | null> | null } | null };

export type ListedCompanyFieldsFragment = { __typename?: 'ListedCompanyRow', id: string, name: string, nativeName?: string | null, stockExchange?: string | null, ticker: string, companyType?: string | null, description?: string | null, primaryIndustry?: string | null, website?: string | null, region?: string | null };

export type GetManyListedCompaniesByKeywordQueryVariables = Exact<{
  keyword: Scalars['String']['input'];
}>;


export type GetManyListedCompaniesByKeywordQuery = { __typename?: 'Query', getManyListedCompaniesByKeyword?: { __typename?: 'ListedCompany', total: number, data?: Array<{ __typename?: 'ListedCompanyRow', id: string, name: string, nativeName?: string | null, stockExchange?: string | null, ticker: string, companyType?: string | null, description?: string | null, primaryIndustry?: string | null, website?: string | null, region?: string | null }> | null } | null };

export type ExtractKeywordsQueryVariables = Exact<{
  text: Scalars['String']['input'];
}>;


export type ExtractKeywordsQuery = { __typename?: 'Query', extractKeywords: Array<string | null> };

export const ComparableCompanyFieldsFragmentDoc = gql`
    fragment comparableCompanyFields on ComparableCompany {
  id
  parentId
  company
  code
}
    `;
export const IntangibleAssetsFieldsFragmentDoc = gql`
    fragment intangibleAssetsFields on IntangibleAsset {
  id
  parentId
  type
  amount
  rul
}
    `;
export const ResearchFieldsFragmentDoc = gql`
    fragment researchFields on ResearchRow {
  id
  year
  ListCo
  StockCode
  Target
  TargetBusiness
  Industry
  Location
  CircularDate
  TransactionType
  ValuationReport
  Valuer
  ValuationApproach1
  ValuationApproach2
  ComparableCompany
  WACC
  CompanySpecificRiskPremium
  LoMD1
  LoMD1Source
  LoMD2
  LoMD2Source
  LoCD
  LoCDSource
  ControlPremium
  ControlPremiumSource
  Adoptedstatisticaltype
  MarketApproachMultiple1
  MarketApproachMultipleResult1
  MarketApproachMultiple2
  MarketApproachMultipleResult2
  MarketApproachMultiple3
  MarketApproachMultipleResult3
  MarketApproachMultiple4
  MarketApproachMultipleResult4
  SelectedComparableCompaniesStockCode
  FinancialAdvisor
  IndependentFinancialAdvisor
  Remark
  Link
}
    `;
export const ComparableFieldsFragmentDoc = gql`
    fragment comparableFields on ComparableRow {
  id
  Industry
  ListCo
  StockCode
  Target
  TargetBusiness
  Location
  AcquisitionDate
  Currency
  IntangibleAssets
  GoodwillFull
  IAIAGW
  GWIAGW
  PurchaseConsiderationRaw
  EquityinterestAcquired
  PurchaseConsiderationFull
  IntangibleAssetsList {
    amount
    rul
    type
    id
  }
  Remarks
  PageNo
  Link
  DoneBy
}
    `;
export const RoyaltyFieldsFragmentDoc = gql`
    fragment royaltyFields on RoyaltyRateRow {
  id
  JobCode
  TargetsName
  TargetsBusinessDescription
  IntangibleAssetType
  Industry
  DataSource
  Licensee
  Licensor
  RoyaltyRateLowRange
  RoyaltyRateHighRange
  LicensedPropertyDescription
  CompensationDetail
  Source
  Year
}
    `;
export const ProprietaryFieldsFragmentDoc = gql`
    fragment proprietaryFields on ProprietaryRow {
  id
  Industry
  AcquisitionDate
  TargetName
  BusinessDescription
  TargetLocation
  Currency
  Link
  Acquirer
  AcquirerStockCode
  IntangibleAssets
  Goodwill
  IAIAGW
  GWIAGW
  Consideration
  Sought
  Consideration100
  Valuer
  ManagerInCharge
  RoyaltyRate
  IntangibleAssetsList {
    id
    amount
    rul
    type
  }
  ComparableCompanies {
    id
    company
    code
  }
  WACC
  CompanySpecificRisk
  EnterpriseValue100
  EquityValue100
  LatestFullYearNetProfit
  LatestFullYearEBITDA
  LatestFullYearSales
  PE
  EVEBITDA
  EVS
  Remark
  InCharge
  CheckingBy
}
    `;
export const IndustrialFactorFieldsFragmentDoc = gql`
    fragment industrialFactorFields on IndustrialFactorRow {
  id
  year
  location
  industry
  factor
  supporting
}
    `;
export const MarcoEconFactorFieldsFragmentDoc = gql`
    fragment marcoEconFactorFields on MarcoEconFactorRow {
  id
  year
  description
  factor
  supporting
}
    `;
export const RecoveryRateFieldsFragmentDoc = gql`
    fragment recoveryRateFields on RecoveryRateRow {
  id
  year
  description
  recoveryRate
  supporting
}
    `;
export const EffectiveInterestRateFieldsFragmentDoc = gql`
    fragment effectiveInterestRateFields on EffectiveInterestRateRow {
  id
  location
  date
  interestRate
}
    `;
export const BloombergIndexFieldsFragmentDoc = gql`
    fragment bloombergIndexFields on BloombergIndexRow {
  id
  ticker
  dataDate
  dataValue
  index
  description
  type
  region
}
    `;
export const BloombergCpiFieldsFragmentDoc = gql`
    fragment bloombergCpiFields on BloombergCpiRow {
  dataDate
  CNCPIYOY_Index
  CPI_YOY_Index
  HKCPIY_Index
  CNCPIYOY_Index_screenshot
  CPI_YOY_Index_screenshot
  HKCPIY_Index_screenshot
}
    `;
export const BloombergCpiAverageFieldsFragmentDoc = gql`
    fragment bloombergCpiAverageFields on BloombergCpiAverageRow {
  dataDate
  rangeInYear
  period
  CNCPIYOY_Index
  CPI_YOY_Index
  HKCPIY_Index
  CNCPIYOY_Index_id
  CPI_YOY_Index_id
  HKCPIY_Index_id
}
    `;
export const BloombergExchangeRateFieldsFragmentDoc = gql`
    fragment bloombergExchangeRateFields on BloombergExchangeRateRow {
  dataDate
  USDCNY_BGNT_Curncy
  USDHKD_BGNT_Curncy
  CNYHKD_BGNT_Curncy
  EURHKD_BGNT_Curncy
  GBPHKD_BGNT_Curncy
  SGDHKD_BGNT_Curncy
  JPYHKD_BGNT_Curncy
  AUDHKD_BGNT_Curncy
  HKDTWD_BGNT_Curncy
  HKDKRW_BGNT_Curncy
  USDCNY_BGNT_Curncy_screenshot
  USDHKD_BGNT_Curncy_screenshot
  CNYHKD_BGNT_Curncy_screenshot
  EURHKD_BGNT_Curncy_screenshot
  GBPHKD_BGNT_Curncy_screenshot
  SGDHKD_BGNT_Curncy_screenshot
  JPYHKD_BGNT_Curncy_screenshot
  AUDHKD_BGNT_Curncy_screenshot
  HKDTWD_BGNT_Curncy_screenshot
  HKDKRW_BGNT_Curncy_screenshot
}
    `;
export const BloombergBondYieldFieldsFragmentDoc = gql`
    fragment bloombergBondYieldFields on BloombergBondYieldRow {
  id
  ticker
  issuerName
  issueDate
  biscLevel2
  isin
  yieldToMaturityMid
  spRating
  spIssuerRating
  cpn
  maturity
  timeToMaturity
  series
  bbg
  mtyType
  currency
  valuationDate
  screenshotId
}
    `;
export const ExportedFileFieldsFragmentDoc = gql`
    fragment exportedFileFields on ExportedFile {
  url
}
    `;
export const IssueFieldsFragmentDoc = gql`
    fragment issueFields on IssueRow {
  id
  title
  description
  status
  createdAt
  createdBy
}
    `;
export const ResearchReportFieldsFragmentDoc = gql`
    fragment researchReportFields on ResearchReportRow {
  id
  companyName
  comparableCompanies {
    id
    name
    stockExchange
    ticker
  }
  description
  keywords
  industries
  files
  updatedAt
}
    `;
export const ListedCompanyFieldsFragmentDoc = gql`
    fragment listedCompanyFields on ListedCompanyRow {
  id
  name
  nativeName
  stockExchange
  ticker
  companyType
  description
  primaryIndustry
  website
  region
}
    `;
export const GetManyResearchesDocument = gql`
    query GetManyResearches($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $year: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  getManyResearches(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    year: $year
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    total
    data {
      ...researchFields
    }
  }
}
    ${ResearchFieldsFragmentDoc}`;

/**
 * __useGetManyResearchesQuery__
 *
 * To run a query within a React component, call `useGetManyResearchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyResearchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyResearchesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      year: // value for 'year'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useGetManyResearchesQuery(baseOptions: Apollo.QueryHookOptions<GetManyResearchesQuery, GetManyResearchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyResearchesQuery, GetManyResearchesQueryVariables>(GetManyResearchesDocument, options);
      }
export function useGetManyResearchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyResearchesQuery, GetManyResearchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyResearchesQuery, GetManyResearchesQueryVariables>(GetManyResearchesDocument, options);
        }
export function useGetManyResearchesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyResearchesQuery, GetManyResearchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyResearchesQuery, GetManyResearchesQueryVariables>(GetManyResearchesDocument, options);
        }
export type GetManyResearchesQueryHookResult = ReturnType<typeof useGetManyResearchesQuery>;
export type GetManyResearchesLazyQueryHookResult = ReturnType<typeof useGetManyResearchesLazyQuery>;
export type GetManyResearchesSuspenseQueryHookResult = ReturnType<typeof useGetManyResearchesSuspenseQuery>;
export type GetManyResearchesQueryResult = Apollo.QueryResult<GetManyResearchesQuery, GetManyResearchesQueryVariables>;
export const CreateOneResearchDocument = gql`
    mutation CreateOneResearch($data: ResearchCreateOneInput!) {
  createOneResearch(data: $data) {
    ...researchFields
  }
}
    ${ResearchFieldsFragmentDoc}`;
export type CreateOneResearchMutationFn = Apollo.MutationFunction<CreateOneResearchMutation, CreateOneResearchMutationVariables>;

/**
 * __useCreateOneResearchMutation__
 *
 * To run a mutation, you first call `useCreateOneResearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneResearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneResearchMutation, { data, loading, error }] = useCreateOneResearchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneResearchMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneResearchMutation, CreateOneResearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneResearchMutation, CreateOneResearchMutationVariables>(CreateOneResearchDocument, options);
      }
export type CreateOneResearchMutationHookResult = ReturnType<typeof useCreateOneResearchMutation>;
export type CreateOneResearchMutationResult = Apollo.MutationResult<CreateOneResearchMutation>;
export type CreateOneResearchMutationOptions = Apollo.BaseMutationOptions<CreateOneResearchMutation, CreateOneResearchMutationVariables>;
export const UpdateOneResearchDocument = gql`
    mutation UpdateOneResearch($data: ResearchUpdateOneInput!) {
  updateOneResearch(data: $data) {
    ...researchFields
  }
}
    ${ResearchFieldsFragmentDoc}`;
export type UpdateOneResearchMutationFn = Apollo.MutationFunction<UpdateOneResearchMutation, UpdateOneResearchMutationVariables>;

/**
 * __useUpdateOneResearchMutation__
 *
 * To run a mutation, you first call `useUpdateOneResearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneResearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneResearchMutation, { data, loading, error }] = useUpdateOneResearchMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneResearchMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneResearchMutation, UpdateOneResearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneResearchMutation, UpdateOneResearchMutationVariables>(UpdateOneResearchDocument, options);
      }
export type UpdateOneResearchMutationHookResult = ReturnType<typeof useUpdateOneResearchMutation>;
export type UpdateOneResearchMutationResult = Apollo.MutationResult<UpdateOneResearchMutation>;
export type UpdateOneResearchMutationOptions = Apollo.BaseMutationOptions<UpdateOneResearchMutation, UpdateOneResearchMutationVariables>;
export const DeleteOneResearchDocument = gql`
    mutation DeleteOneResearch($id: ID!) {
  deleteOneResearch(id: $id) {
    id
  }
}
    `;
export type DeleteOneResearchMutationFn = Apollo.MutationFunction<DeleteOneResearchMutation, DeleteOneResearchMutationVariables>;

/**
 * __useDeleteOneResearchMutation__
 *
 * To run a mutation, you first call `useDeleteOneResearchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneResearchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneResearchMutation, { data, loading, error }] = useDeleteOneResearchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneResearchMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneResearchMutation, DeleteOneResearchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneResearchMutation, DeleteOneResearchMutationVariables>(DeleteOneResearchDocument, options);
      }
export type DeleteOneResearchMutationHookResult = ReturnType<typeof useDeleteOneResearchMutation>;
export type DeleteOneResearchMutationResult = Apollo.MutationResult<DeleteOneResearchMutation>;
export type DeleteOneResearchMutationOptions = Apollo.BaseMutationOptions<DeleteOneResearchMutation, DeleteOneResearchMutationVariables>;
export const GetLastUpdateDocument = gql`
    query GetLastUpdate {
  getLastUpdate {
    CIRCULAR_RESEARCH
    COMPARABLE_PPA
    ROYALTY_RATE
    PROPRIETARY_PPA
    MARCO_ECON_FACTOR
    INDUSTRIAL_FACTOR
    RECOVERY_RATES
  }
}
    `;

/**
 * __useGetLastUpdateQuery__
 *
 * To run a query within a React component, call `useGetLastUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastUpdateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastUpdateQuery(baseOptions?: Apollo.QueryHookOptions<GetLastUpdateQuery, GetLastUpdateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastUpdateQuery, GetLastUpdateQueryVariables>(GetLastUpdateDocument, options);
      }
export function useGetLastUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastUpdateQuery, GetLastUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastUpdateQuery, GetLastUpdateQueryVariables>(GetLastUpdateDocument, options);
        }
export function useGetLastUpdateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLastUpdateQuery, GetLastUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLastUpdateQuery, GetLastUpdateQueryVariables>(GetLastUpdateDocument, options);
        }
export type GetLastUpdateQueryHookResult = ReturnType<typeof useGetLastUpdateQuery>;
export type GetLastUpdateLazyQueryHookResult = ReturnType<typeof useGetLastUpdateLazyQuery>;
export type GetLastUpdateSuspenseQueryHookResult = ReturnType<typeof useGetLastUpdateSuspenseQuery>;
export type GetLastUpdateQueryResult = Apollo.QueryResult<GetLastUpdateQuery, GetLastUpdateQueryVariables>;
export const GetManyComparablesDocument = gql`
    query GetManyComparables($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  getManyComparables(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    total
    data {
      ...comparableFields
    }
  }
}
    ${ComparableFieldsFragmentDoc}`;

/**
 * __useGetManyComparablesQuery__
 *
 * To run a query within a React component, call `useGetManyComparablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyComparablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyComparablesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useGetManyComparablesQuery(baseOptions: Apollo.QueryHookOptions<GetManyComparablesQuery, GetManyComparablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyComparablesQuery, GetManyComparablesQueryVariables>(GetManyComparablesDocument, options);
      }
export function useGetManyComparablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyComparablesQuery, GetManyComparablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyComparablesQuery, GetManyComparablesQueryVariables>(GetManyComparablesDocument, options);
        }
export function useGetManyComparablesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyComparablesQuery, GetManyComparablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyComparablesQuery, GetManyComparablesQueryVariables>(GetManyComparablesDocument, options);
        }
export type GetManyComparablesQueryHookResult = ReturnType<typeof useGetManyComparablesQuery>;
export type GetManyComparablesLazyQueryHookResult = ReturnType<typeof useGetManyComparablesLazyQuery>;
export type GetManyComparablesSuspenseQueryHookResult = ReturnType<typeof useGetManyComparablesSuspenseQuery>;
export type GetManyComparablesQueryResult = Apollo.QueryResult<GetManyComparablesQuery, GetManyComparablesQueryVariables>;
export const CreateOneComparableDocument = gql`
    mutation CreateOneComparable($data: ComparableCreateOneInput!) {
  createOneComparable(data: $data) {
    ...comparableFields
  }
}
    ${ComparableFieldsFragmentDoc}`;
export type CreateOneComparableMutationFn = Apollo.MutationFunction<CreateOneComparableMutation, CreateOneComparableMutationVariables>;

/**
 * __useCreateOneComparableMutation__
 *
 * To run a mutation, you first call `useCreateOneComparableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneComparableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneComparableMutation, { data, loading, error }] = useCreateOneComparableMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneComparableMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneComparableMutation, CreateOneComparableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneComparableMutation, CreateOneComparableMutationVariables>(CreateOneComparableDocument, options);
      }
export type CreateOneComparableMutationHookResult = ReturnType<typeof useCreateOneComparableMutation>;
export type CreateOneComparableMutationResult = Apollo.MutationResult<CreateOneComparableMutation>;
export type CreateOneComparableMutationOptions = Apollo.BaseMutationOptions<CreateOneComparableMutation, CreateOneComparableMutationVariables>;
export const UpdateOneComparableDocument = gql`
    mutation UpdateOneComparable($data: ComparableUpdateOneInput!) {
  updateOneComparable(data: $data) {
    ...comparableFields
  }
}
    ${ComparableFieldsFragmentDoc}`;
export type UpdateOneComparableMutationFn = Apollo.MutationFunction<UpdateOneComparableMutation, UpdateOneComparableMutationVariables>;

/**
 * __useUpdateOneComparableMutation__
 *
 * To run a mutation, you first call `useUpdateOneComparableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneComparableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneComparableMutation, { data, loading, error }] = useUpdateOneComparableMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneComparableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneComparableMutation, UpdateOneComparableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneComparableMutation, UpdateOneComparableMutationVariables>(UpdateOneComparableDocument, options);
      }
export type UpdateOneComparableMutationHookResult = ReturnType<typeof useUpdateOneComparableMutation>;
export type UpdateOneComparableMutationResult = Apollo.MutationResult<UpdateOneComparableMutation>;
export type UpdateOneComparableMutationOptions = Apollo.BaseMutationOptions<UpdateOneComparableMutation, UpdateOneComparableMutationVariables>;
export const UpdateManyComparablesByImportFileDocument = gql`
    mutation UpdateManyComparablesByImportFile($file: Upload!) {
  updateManyComparablesByImportFile(file: $file) {
    ...comparableFields
  }
}
    ${ComparableFieldsFragmentDoc}`;
export type UpdateManyComparablesByImportFileMutationFn = Apollo.MutationFunction<UpdateManyComparablesByImportFileMutation, UpdateManyComparablesByImportFileMutationVariables>;

/**
 * __useUpdateManyComparablesByImportFileMutation__
 *
 * To run a mutation, you first call `useUpdateManyComparablesByImportFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyComparablesByImportFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyComparablesByImportFileMutation, { data, loading, error }] = useUpdateManyComparablesByImportFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateManyComparablesByImportFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyComparablesByImportFileMutation, UpdateManyComparablesByImportFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyComparablesByImportFileMutation, UpdateManyComparablesByImportFileMutationVariables>(UpdateManyComparablesByImportFileDocument, options);
      }
export type UpdateManyComparablesByImportFileMutationHookResult = ReturnType<typeof useUpdateManyComparablesByImportFileMutation>;
export type UpdateManyComparablesByImportFileMutationResult = Apollo.MutationResult<UpdateManyComparablesByImportFileMutation>;
export type UpdateManyComparablesByImportFileMutationOptions = Apollo.BaseMutationOptions<UpdateManyComparablesByImportFileMutation, UpdateManyComparablesByImportFileMutationVariables>;
export const DeleteOneComparableDocument = gql`
    mutation DeleteOneComparable($id: ID!) {
  deleteOneComparable(id: $id) {
    id
  }
}
    `;
export type DeleteOneComparableMutationFn = Apollo.MutationFunction<DeleteOneComparableMutation, DeleteOneComparableMutationVariables>;

/**
 * __useDeleteOneComparableMutation__
 *
 * To run a mutation, you first call `useDeleteOneComparableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneComparableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneComparableMutation, { data, loading, error }] = useDeleteOneComparableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneComparableMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneComparableMutation, DeleteOneComparableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneComparableMutation, DeleteOneComparableMutationVariables>(DeleteOneComparableDocument, options);
      }
export type DeleteOneComparableMutationHookResult = ReturnType<typeof useDeleteOneComparableMutation>;
export type DeleteOneComparableMutationResult = Apollo.MutationResult<DeleteOneComparableMutation>;
export type DeleteOneComparableMutationOptions = Apollo.BaseMutationOptions<DeleteOneComparableMutation, DeleteOneComparableMutationVariables>;
export const GetManyRoyaltyRatesDocument = gql`
    query GetManyRoyaltyRates($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  getManyRoyaltyRates(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    total
    data {
      ...royaltyFields
    }
  }
}
    ${RoyaltyFieldsFragmentDoc}`;

/**
 * __useGetManyRoyaltyRatesQuery__
 *
 * To run a query within a React component, call `useGetManyRoyaltyRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyRoyaltyRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyRoyaltyRatesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useGetManyRoyaltyRatesQuery(baseOptions: Apollo.QueryHookOptions<GetManyRoyaltyRatesQuery, GetManyRoyaltyRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyRoyaltyRatesQuery, GetManyRoyaltyRatesQueryVariables>(GetManyRoyaltyRatesDocument, options);
      }
export function useGetManyRoyaltyRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyRoyaltyRatesQuery, GetManyRoyaltyRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyRoyaltyRatesQuery, GetManyRoyaltyRatesQueryVariables>(GetManyRoyaltyRatesDocument, options);
        }
export function useGetManyRoyaltyRatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyRoyaltyRatesQuery, GetManyRoyaltyRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyRoyaltyRatesQuery, GetManyRoyaltyRatesQueryVariables>(GetManyRoyaltyRatesDocument, options);
        }
export type GetManyRoyaltyRatesQueryHookResult = ReturnType<typeof useGetManyRoyaltyRatesQuery>;
export type GetManyRoyaltyRatesLazyQueryHookResult = ReturnType<typeof useGetManyRoyaltyRatesLazyQuery>;
export type GetManyRoyaltyRatesSuspenseQueryHookResult = ReturnType<typeof useGetManyRoyaltyRatesSuspenseQuery>;
export type GetManyRoyaltyRatesQueryResult = Apollo.QueryResult<GetManyRoyaltyRatesQuery, GetManyRoyaltyRatesQueryVariables>;
export const CreateOneRoyaltyRateDocument = gql`
    mutation CreateOneRoyaltyRate($data: RoyaltyRateCreateOneInput!) {
  createOneRoyaltyRate(data: $data) {
    ...royaltyFields
  }
}
    ${RoyaltyFieldsFragmentDoc}`;
export type CreateOneRoyaltyRateMutationFn = Apollo.MutationFunction<CreateOneRoyaltyRateMutation, CreateOneRoyaltyRateMutationVariables>;

/**
 * __useCreateOneRoyaltyRateMutation__
 *
 * To run a mutation, you first call `useCreateOneRoyaltyRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneRoyaltyRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneRoyaltyRateMutation, { data, loading, error }] = useCreateOneRoyaltyRateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneRoyaltyRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneRoyaltyRateMutation, CreateOneRoyaltyRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneRoyaltyRateMutation, CreateOneRoyaltyRateMutationVariables>(CreateOneRoyaltyRateDocument, options);
      }
export type CreateOneRoyaltyRateMutationHookResult = ReturnType<typeof useCreateOneRoyaltyRateMutation>;
export type CreateOneRoyaltyRateMutationResult = Apollo.MutationResult<CreateOneRoyaltyRateMutation>;
export type CreateOneRoyaltyRateMutationOptions = Apollo.BaseMutationOptions<CreateOneRoyaltyRateMutation, CreateOneRoyaltyRateMutationVariables>;
export const UpdateOneRoyaltyRateDocument = gql`
    mutation UpdateOneRoyaltyRate($data: RoyaltyRateUpdateOneInput!) {
  updateOneRoyaltyRate(data: $data) {
    ...royaltyFields
  }
}
    ${RoyaltyFieldsFragmentDoc}`;
export type UpdateOneRoyaltyRateMutationFn = Apollo.MutationFunction<UpdateOneRoyaltyRateMutation, UpdateOneRoyaltyRateMutationVariables>;

/**
 * __useUpdateOneRoyaltyRateMutation__
 *
 * To run a mutation, you first call `useUpdateOneRoyaltyRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneRoyaltyRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneRoyaltyRateMutation, { data, loading, error }] = useUpdateOneRoyaltyRateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneRoyaltyRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneRoyaltyRateMutation, UpdateOneRoyaltyRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneRoyaltyRateMutation, UpdateOneRoyaltyRateMutationVariables>(UpdateOneRoyaltyRateDocument, options);
      }
export type UpdateOneRoyaltyRateMutationHookResult = ReturnType<typeof useUpdateOneRoyaltyRateMutation>;
export type UpdateOneRoyaltyRateMutationResult = Apollo.MutationResult<UpdateOneRoyaltyRateMutation>;
export type UpdateOneRoyaltyRateMutationOptions = Apollo.BaseMutationOptions<UpdateOneRoyaltyRateMutation, UpdateOneRoyaltyRateMutationVariables>;
export const DeleteOneRoyaltyRateDocument = gql`
    mutation DeleteOneRoyaltyRate($id: ID!) {
  deleteOneRoyaltyRate(id: $id) {
    id
  }
}
    `;
export type DeleteOneRoyaltyRateMutationFn = Apollo.MutationFunction<DeleteOneRoyaltyRateMutation, DeleteOneRoyaltyRateMutationVariables>;

/**
 * __useDeleteOneRoyaltyRateMutation__
 *
 * To run a mutation, you first call `useDeleteOneRoyaltyRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneRoyaltyRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneRoyaltyRateMutation, { data, loading, error }] = useDeleteOneRoyaltyRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneRoyaltyRateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneRoyaltyRateMutation, DeleteOneRoyaltyRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneRoyaltyRateMutation, DeleteOneRoyaltyRateMutationVariables>(DeleteOneRoyaltyRateDocument, options);
      }
export type DeleteOneRoyaltyRateMutationHookResult = ReturnType<typeof useDeleteOneRoyaltyRateMutation>;
export type DeleteOneRoyaltyRateMutationResult = Apollo.MutationResult<DeleteOneRoyaltyRateMutation>;
export type DeleteOneRoyaltyRateMutationOptions = Apollo.BaseMutationOptions<DeleteOneRoyaltyRateMutation, DeleteOneRoyaltyRateMutationVariables>;
export const GetManyProprietariesDocument = gql`
    query GetManyProprietaries($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  getManyProprietaries(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    total
    data {
      ...proprietaryFields
    }
  }
}
    ${ProprietaryFieldsFragmentDoc}`;

/**
 * __useGetManyProprietariesQuery__
 *
 * To run a query within a React component, call `useGetManyProprietariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyProprietariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyProprietariesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useGetManyProprietariesQuery(baseOptions: Apollo.QueryHookOptions<GetManyProprietariesQuery, GetManyProprietariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyProprietariesQuery, GetManyProprietariesQueryVariables>(GetManyProprietariesDocument, options);
      }
export function useGetManyProprietariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyProprietariesQuery, GetManyProprietariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyProprietariesQuery, GetManyProprietariesQueryVariables>(GetManyProprietariesDocument, options);
        }
export function useGetManyProprietariesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyProprietariesQuery, GetManyProprietariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyProprietariesQuery, GetManyProprietariesQueryVariables>(GetManyProprietariesDocument, options);
        }
export type GetManyProprietariesQueryHookResult = ReturnType<typeof useGetManyProprietariesQuery>;
export type GetManyProprietariesLazyQueryHookResult = ReturnType<typeof useGetManyProprietariesLazyQuery>;
export type GetManyProprietariesSuspenseQueryHookResult = ReturnType<typeof useGetManyProprietariesSuspenseQuery>;
export type GetManyProprietariesQueryResult = Apollo.QueryResult<GetManyProprietariesQuery, GetManyProprietariesQueryVariables>;
export const CreateOneProprietaryDocument = gql`
    mutation CreateOneProprietary($data: ProprietaryCreateOneInput!) {
  createOneProprietary(data: $data) {
    ...proprietaryFields
  }
}
    ${ProprietaryFieldsFragmentDoc}`;
export type CreateOneProprietaryMutationFn = Apollo.MutationFunction<CreateOneProprietaryMutation, CreateOneProprietaryMutationVariables>;

/**
 * __useCreateOneProprietaryMutation__
 *
 * To run a mutation, you first call `useCreateOneProprietaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneProprietaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneProprietaryMutation, { data, loading, error }] = useCreateOneProprietaryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneProprietaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneProprietaryMutation, CreateOneProprietaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneProprietaryMutation, CreateOneProprietaryMutationVariables>(CreateOneProprietaryDocument, options);
      }
export type CreateOneProprietaryMutationHookResult = ReturnType<typeof useCreateOneProprietaryMutation>;
export type CreateOneProprietaryMutationResult = Apollo.MutationResult<CreateOneProprietaryMutation>;
export type CreateOneProprietaryMutationOptions = Apollo.BaseMutationOptions<CreateOneProprietaryMutation, CreateOneProprietaryMutationVariables>;
export const UpdateOneProprietaryDocument = gql`
    mutation UpdateOneProprietary($data: ProprietaryUpdateOneInput!) {
  updateOneProprietary(data: $data) {
    ...proprietaryFields
  }
}
    ${ProprietaryFieldsFragmentDoc}`;
export type UpdateOneProprietaryMutationFn = Apollo.MutationFunction<UpdateOneProprietaryMutation, UpdateOneProprietaryMutationVariables>;

/**
 * __useUpdateOneProprietaryMutation__
 *
 * To run a mutation, you first call `useUpdateOneProprietaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneProprietaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneProprietaryMutation, { data, loading, error }] = useUpdateOneProprietaryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneProprietaryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneProprietaryMutation, UpdateOneProprietaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneProprietaryMutation, UpdateOneProprietaryMutationVariables>(UpdateOneProprietaryDocument, options);
      }
export type UpdateOneProprietaryMutationHookResult = ReturnType<typeof useUpdateOneProprietaryMutation>;
export type UpdateOneProprietaryMutationResult = Apollo.MutationResult<UpdateOneProprietaryMutation>;
export type UpdateOneProprietaryMutationOptions = Apollo.BaseMutationOptions<UpdateOneProprietaryMutation, UpdateOneProprietaryMutationVariables>;
export const UpdateManyProprietariesByImportFileDocument = gql`
    mutation UpdateManyProprietariesByImportFile($file: Upload!) {
  updateManyProprietariesByImportFile(file: $file) {
    ...proprietaryFields
  }
}
    ${ProprietaryFieldsFragmentDoc}`;
export type UpdateManyProprietariesByImportFileMutationFn = Apollo.MutationFunction<UpdateManyProprietariesByImportFileMutation, UpdateManyProprietariesByImportFileMutationVariables>;

/**
 * __useUpdateManyProprietariesByImportFileMutation__
 *
 * To run a mutation, you first call `useUpdateManyProprietariesByImportFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyProprietariesByImportFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyProprietariesByImportFileMutation, { data, loading, error }] = useUpdateManyProprietariesByImportFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateManyProprietariesByImportFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyProprietariesByImportFileMutation, UpdateManyProprietariesByImportFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyProprietariesByImportFileMutation, UpdateManyProprietariesByImportFileMutationVariables>(UpdateManyProprietariesByImportFileDocument, options);
      }
export type UpdateManyProprietariesByImportFileMutationHookResult = ReturnType<typeof useUpdateManyProprietariesByImportFileMutation>;
export type UpdateManyProprietariesByImportFileMutationResult = Apollo.MutationResult<UpdateManyProprietariesByImportFileMutation>;
export type UpdateManyProprietariesByImportFileMutationOptions = Apollo.BaseMutationOptions<UpdateManyProprietariesByImportFileMutation, UpdateManyProprietariesByImportFileMutationVariables>;
export const DeleteOneProprietaryDocument = gql`
    mutation DeleteOneProprietary($id: ID!) {
  deleteOneProprietary(id: $id) {
    id
  }
}
    `;
export type DeleteOneProprietaryMutationFn = Apollo.MutationFunction<DeleteOneProprietaryMutation, DeleteOneProprietaryMutationVariables>;

/**
 * __useDeleteOneProprietaryMutation__
 *
 * To run a mutation, you first call `useDeleteOneProprietaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneProprietaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneProprietaryMutation, { data, loading, error }] = useDeleteOneProprietaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneProprietaryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneProprietaryMutation, DeleteOneProprietaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneProprietaryMutation, DeleteOneProprietaryMutationVariables>(DeleteOneProprietaryDocument, options);
      }
export type DeleteOneProprietaryMutationHookResult = ReturnType<typeof useDeleteOneProprietaryMutation>;
export type DeleteOneProprietaryMutationResult = Apollo.MutationResult<DeleteOneProprietaryMutation>;
export type DeleteOneProprietaryMutationOptions = Apollo.BaseMutationOptions<DeleteOneProprietaryMutation, DeleteOneProprietaryMutationVariables>;
export const IndustrialFactorDocument = gql`
    query IndustrialFactor($year: Int!, $location: String!, $industry: String!) {
  industrialFactor(year: $year, location: $location, industry: $industry) {
    ...industrialFactorFields
  }
}
    ${IndustrialFactorFieldsFragmentDoc}`;

/**
 * __useIndustrialFactorQuery__
 *
 * To run a query within a React component, call `useIndustrialFactorQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustrialFactorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustrialFactorQuery({
 *   variables: {
 *      year: // value for 'year'
 *      location: // value for 'location'
 *      industry: // value for 'industry'
 *   },
 * });
 */
export function useIndustrialFactorQuery(baseOptions: Apollo.QueryHookOptions<IndustrialFactorQuery, IndustrialFactorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustrialFactorQuery, IndustrialFactorQueryVariables>(IndustrialFactorDocument, options);
      }
export function useIndustrialFactorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustrialFactorQuery, IndustrialFactorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustrialFactorQuery, IndustrialFactorQueryVariables>(IndustrialFactorDocument, options);
        }
export function useIndustrialFactorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IndustrialFactorQuery, IndustrialFactorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IndustrialFactorQuery, IndustrialFactorQueryVariables>(IndustrialFactorDocument, options);
        }
export type IndustrialFactorQueryHookResult = ReturnType<typeof useIndustrialFactorQuery>;
export type IndustrialFactorLazyQueryHookResult = ReturnType<typeof useIndustrialFactorLazyQuery>;
export type IndustrialFactorSuspenseQueryHookResult = ReturnType<typeof useIndustrialFactorSuspenseQuery>;
export type IndustrialFactorQueryResult = Apollo.QueryResult<IndustrialFactorQuery, IndustrialFactorQueryVariables>;
export const IndustrialFactorsDocument = gql`
    query IndustrialFactors($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!, $year: Int!) {
  industrialFactors(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
    year: $year
  ) {
    total
    data {
      ...industrialFactorFields
    }
  }
}
    ${IndustrialFactorFieldsFragmentDoc}`;

/**
 * __useIndustrialFactorsQuery__
 *
 * To run a query within a React component, call `useIndustrialFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustrialFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustrialFactorsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useIndustrialFactorsQuery(baseOptions: Apollo.QueryHookOptions<IndustrialFactorsQuery, IndustrialFactorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustrialFactorsQuery, IndustrialFactorsQueryVariables>(IndustrialFactorsDocument, options);
      }
export function useIndustrialFactorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustrialFactorsQuery, IndustrialFactorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustrialFactorsQuery, IndustrialFactorsQueryVariables>(IndustrialFactorsDocument, options);
        }
export function useIndustrialFactorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IndustrialFactorsQuery, IndustrialFactorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IndustrialFactorsQuery, IndustrialFactorsQueryVariables>(IndustrialFactorsDocument, options);
        }
export type IndustrialFactorsQueryHookResult = ReturnType<typeof useIndustrialFactorsQuery>;
export type IndustrialFactorsLazyQueryHookResult = ReturnType<typeof useIndustrialFactorsLazyQuery>;
export type IndustrialFactorsSuspenseQueryHookResult = ReturnType<typeof useIndustrialFactorsSuspenseQuery>;
export type IndustrialFactorsQueryResult = Apollo.QueryResult<IndustrialFactorsQuery, IndustrialFactorsQueryVariables>;
export const GetIndustrialFactorsYearsDocument = gql`
    query GetIndustrialFactorsYears {
  getIndustrialFactorYears
}
    `;

/**
 * __useGetIndustrialFactorsYearsQuery__
 *
 * To run a query within a React component, call `useGetIndustrialFactorsYearsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndustrialFactorsYearsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndustrialFactorsYearsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIndustrialFactorsYearsQuery(baseOptions?: Apollo.QueryHookOptions<GetIndustrialFactorsYearsQuery, GetIndustrialFactorsYearsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIndustrialFactorsYearsQuery, GetIndustrialFactorsYearsQueryVariables>(GetIndustrialFactorsYearsDocument, options);
      }
export function useGetIndustrialFactorsYearsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIndustrialFactorsYearsQuery, GetIndustrialFactorsYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIndustrialFactorsYearsQuery, GetIndustrialFactorsYearsQueryVariables>(GetIndustrialFactorsYearsDocument, options);
        }
export function useGetIndustrialFactorsYearsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIndustrialFactorsYearsQuery, GetIndustrialFactorsYearsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIndustrialFactorsYearsQuery, GetIndustrialFactorsYearsQueryVariables>(GetIndustrialFactorsYearsDocument, options);
        }
export type GetIndustrialFactorsYearsQueryHookResult = ReturnType<typeof useGetIndustrialFactorsYearsQuery>;
export type GetIndustrialFactorsYearsLazyQueryHookResult = ReturnType<typeof useGetIndustrialFactorsYearsLazyQuery>;
export type GetIndustrialFactorsYearsSuspenseQueryHookResult = ReturnType<typeof useGetIndustrialFactorsYearsSuspenseQuery>;
export type GetIndustrialFactorsYearsQueryResult = Apollo.QueryResult<GetIndustrialFactorsYearsQuery, GetIndustrialFactorsYearsQueryVariables>;
export const MarcoEconFactorsDocument = gql`
    query MarcoEconFactors($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  marcoEconFactors(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    total
    data {
      ...marcoEconFactorFields
    }
  }
}
    ${MarcoEconFactorFieldsFragmentDoc}`;

/**
 * __useMarcoEconFactorsQuery__
 *
 * To run a query within a React component, call `useMarcoEconFactorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarcoEconFactorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarcoEconFactorsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useMarcoEconFactorsQuery(baseOptions: Apollo.QueryHookOptions<MarcoEconFactorsQuery, MarcoEconFactorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarcoEconFactorsQuery, MarcoEconFactorsQueryVariables>(MarcoEconFactorsDocument, options);
      }
export function useMarcoEconFactorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarcoEconFactorsQuery, MarcoEconFactorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarcoEconFactorsQuery, MarcoEconFactorsQueryVariables>(MarcoEconFactorsDocument, options);
        }
export function useMarcoEconFactorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MarcoEconFactorsQuery, MarcoEconFactorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarcoEconFactorsQuery, MarcoEconFactorsQueryVariables>(MarcoEconFactorsDocument, options);
        }
export type MarcoEconFactorsQueryHookResult = ReturnType<typeof useMarcoEconFactorsQuery>;
export type MarcoEconFactorsLazyQueryHookResult = ReturnType<typeof useMarcoEconFactorsLazyQuery>;
export type MarcoEconFactorsSuspenseQueryHookResult = ReturnType<typeof useMarcoEconFactorsSuspenseQuery>;
export type MarcoEconFactorsQueryResult = Apollo.QueryResult<MarcoEconFactorsQuery, MarcoEconFactorsQueryVariables>;
export const RecoveryRatesDocument = gql`
    query RecoveryRates($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  recoveryRates(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    total
    data {
      ...recoveryRateFields
    }
  }
}
    ${RecoveryRateFieldsFragmentDoc}`;

/**
 * __useRecoveryRatesQuery__
 *
 * To run a query within a React component, call `useRecoveryRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecoveryRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecoveryRatesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useRecoveryRatesQuery(baseOptions: Apollo.QueryHookOptions<RecoveryRatesQuery, RecoveryRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecoveryRatesQuery, RecoveryRatesQueryVariables>(RecoveryRatesDocument, options);
      }
export function useRecoveryRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecoveryRatesQuery, RecoveryRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecoveryRatesQuery, RecoveryRatesQueryVariables>(RecoveryRatesDocument, options);
        }
export function useRecoveryRatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecoveryRatesQuery, RecoveryRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecoveryRatesQuery, RecoveryRatesQueryVariables>(RecoveryRatesDocument, options);
        }
export type RecoveryRatesQueryHookResult = ReturnType<typeof useRecoveryRatesQuery>;
export type RecoveryRatesLazyQueryHookResult = ReturnType<typeof useRecoveryRatesLazyQuery>;
export type RecoveryRatesSuspenseQueryHookResult = ReturnType<typeof useRecoveryRatesSuspenseQuery>;
export type RecoveryRatesQueryResult = Apollo.QueryResult<RecoveryRatesQuery, RecoveryRatesQueryVariables>;
export const GetManyComparableCompaniesDocument = gql`
    query GetManyComparableCompanies($parentId: ID!) {
  getManyComparableCompanies(parentId: $parentId) {
    ...comparableCompanyFields
  }
}
    ${ComparableCompanyFieldsFragmentDoc}`;

/**
 * __useGetManyComparableCompaniesQuery__
 *
 * To run a query within a React component, call `useGetManyComparableCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyComparableCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyComparableCompaniesQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useGetManyComparableCompaniesQuery(baseOptions: Apollo.QueryHookOptions<GetManyComparableCompaniesQuery, GetManyComparableCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyComparableCompaniesQuery, GetManyComparableCompaniesQueryVariables>(GetManyComparableCompaniesDocument, options);
      }
export function useGetManyComparableCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyComparableCompaniesQuery, GetManyComparableCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyComparableCompaniesQuery, GetManyComparableCompaniesQueryVariables>(GetManyComparableCompaniesDocument, options);
        }
export function useGetManyComparableCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyComparableCompaniesQuery, GetManyComparableCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyComparableCompaniesQuery, GetManyComparableCompaniesQueryVariables>(GetManyComparableCompaniesDocument, options);
        }
export type GetManyComparableCompaniesQueryHookResult = ReturnType<typeof useGetManyComparableCompaniesQuery>;
export type GetManyComparableCompaniesLazyQueryHookResult = ReturnType<typeof useGetManyComparableCompaniesLazyQuery>;
export type GetManyComparableCompaniesSuspenseQueryHookResult = ReturnType<typeof useGetManyComparableCompaniesSuspenseQuery>;
export type GetManyComparableCompaniesQueryResult = Apollo.QueryResult<GetManyComparableCompaniesQuery, GetManyComparableCompaniesQueryVariables>;
export const GetManyIntangibleAssetsDocument = gql`
    query GetManyIntangibleAssets($parentId: ID!) {
  getManyIntangibleAssets(parentId: $parentId) {
    ...intangibleAssetsFields
  }
}
    ${IntangibleAssetsFieldsFragmentDoc}`;

/**
 * __useGetManyIntangibleAssetsQuery__
 *
 * To run a query within a React component, call `useGetManyIntangibleAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyIntangibleAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyIntangibleAssetsQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useGetManyIntangibleAssetsQuery(baseOptions: Apollo.QueryHookOptions<GetManyIntangibleAssetsQuery, GetManyIntangibleAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyIntangibleAssetsQuery, GetManyIntangibleAssetsQueryVariables>(GetManyIntangibleAssetsDocument, options);
      }
export function useGetManyIntangibleAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyIntangibleAssetsQuery, GetManyIntangibleAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyIntangibleAssetsQuery, GetManyIntangibleAssetsQueryVariables>(GetManyIntangibleAssetsDocument, options);
        }
export function useGetManyIntangibleAssetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyIntangibleAssetsQuery, GetManyIntangibleAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyIntangibleAssetsQuery, GetManyIntangibleAssetsQueryVariables>(GetManyIntangibleAssetsDocument, options);
        }
export type GetManyIntangibleAssetsQueryHookResult = ReturnType<typeof useGetManyIntangibleAssetsQuery>;
export type GetManyIntangibleAssetsLazyQueryHookResult = ReturnType<typeof useGetManyIntangibleAssetsLazyQuery>;
export type GetManyIntangibleAssetsSuspenseQueryHookResult = ReturnType<typeof useGetManyIntangibleAssetsSuspenseQuery>;
export type GetManyIntangibleAssetsQueryResult = Apollo.QueryResult<GetManyIntangibleAssetsQuery, GetManyIntangibleAssetsQueryVariables>;
export const UpsertAndDeleteManyIntangibleAssetsDocument = gql`
    mutation UpsertAndDeleteManyIntangibleAssets($data: [IntangibleAssetUpdateOneInput]!) {
  upsertAndDeleteManyIntangibleAssets(data: $data) {
    ...intangibleAssetsFields
  }
}
    ${IntangibleAssetsFieldsFragmentDoc}`;
export type UpsertAndDeleteManyIntangibleAssetsMutationFn = Apollo.MutationFunction<UpsertAndDeleteManyIntangibleAssetsMutation, UpsertAndDeleteManyIntangibleAssetsMutationVariables>;

/**
 * __useUpsertAndDeleteManyIntangibleAssetsMutation__
 *
 * To run a mutation, you first call `useUpsertAndDeleteManyIntangibleAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAndDeleteManyIntangibleAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAndDeleteManyIntangibleAssetsMutation, { data, loading, error }] = useUpsertAndDeleteManyIntangibleAssetsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertAndDeleteManyIntangibleAssetsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAndDeleteManyIntangibleAssetsMutation, UpsertAndDeleteManyIntangibleAssetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAndDeleteManyIntangibleAssetsMutation, UpsertAndDeleteManyIntangibleAssetsMutationVariables>(UpsertAndDeleteManyIntangibleAssetsDocument, options);
      }
export type UpsertAndDeleteManyIntangibleAssetsMutationHookResult = ReturnType<typeof useUpsertAndDeleteManyIntangibleAssetsMutation>;
export type UpsertAndDeleteManyIntangibleAssetsMutationResult = Apollo.MutationResult<UpsertAndDeleteManyIntangibleAssetsMutation>;
export type UpsertAndDeleteManyIntangibleAssetsMutationOptions = Apollo.BaseMutationOptions<UpsertAndDeleteManyIntangibleAssetsMutation, UpsertAndDeleteManyIntangibleAssetsMutationVariables>;
export const UpsertAndDeleteManyComparableCompaniesDocument = gql`
    mutation UpsertAndDeleteManyComparableCompanies($data: [ComparableCompanyUpdateOneInput]!) {
  upsertAndDeleteManyComparableCompanies(data: $data) {
    ...comparableCompanyFields
  }
}
    ${ComparableCompanyFieldsFragmentDoc}`;
export type UpsertAndDeleteManyComparableCompaniesMutationFn = Apollo.MutationFunction<UpsertAndDeleteManyComparableCompaniesMutation, UpsertAndDeleteManyComparableCompaniesMutationVariables>;

/**
 * __useUpsertAndDeleteManyComparableCompaniesMutation__
 *
 * To run a mutation, you first call `useUpsertAndDeleteManyComparableCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAndDeleteManyComparableCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAndDeleteManyComparableCompaniesMutation, { data, loading, error }] = useUpsertAndDeleteManyComparableCompaniesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertAndDeleteManyComparableCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAndDeleteManyComparableCompaniesMutation, UpsertAndDeleteManyComparableCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAndDeleteManyComparableCompaniesMutation, UpsertAndDeleteManyComparableCompaniesMutationVariables>(UpsertAndDeleteManyComparableCompaniesDocument, options);
      }
export type UpsertAndDeleteManyComparableCompaniesMutationHookResult = ReturnType<typeof useUpsertAndDeleteManyComparableCompaniesMutation>;
export type UpsertAndDeleteManyComparableCompaniesMutationResult = Apollo.MutationResult<UpsertAndDeleteManyComparableCompaniesMutation>;
export type UpsertAndDeleteManyComparableCompaniesMutationOptions = Apollo.BaseMutationOptions<UpsertAndDeleteManyComparableCompaniesMutation, UpsertAndDeleteManyComparableCompaniesMutationVariables>;
export const EffectiveInterestRatesDocument = gql`
    query EffectiveInterestRates($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  effectiveInterestRates(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    total
    data {
      ...effectiveInterestRateFields
    }
  }
}
    ${EffectiveInterestRateFieldsFragmentDoc}`;

/**
 * __useEffectiveInterestRatesQuery__
 *
 * To run a query within a React component, call `useEffectiveInterestRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEffectiveInterestRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEffectiveInterestRatesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useEffectiveInterestRatesQuery(baseOptions: Apollo.QueryHookOptions<EffectiveInterestRatesQuery, EffectiveInterestRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EffectiveInterestRatesQuery, EffectiveInterestRatesQueryVariables>(EffectiveInterestRatesDocument, options);
      }
export function useEffectiveInterestRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EffectiveInterestRatesQuery, EffectiveInterestRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EffectiveInterestRatesQuery, EffectiveInterestRatesQueryVariables>(EffectiveInterestRatesDocument, options);
        }
export function useEffectiveInterestRatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EffectiveInterestRatesQuery, EffectiveInterestRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EffectiveInterestRatesQuery, EffectiveInterestRatesQueryVariables>(EffectiveInterestRatesDocument, options);
        }
export type EffectiveInterestRatesQueryHookResult = ReturnType<typeof useEffectiveInterestRatesQuery>;
export type EffectiveInterestRatesLazyQueryHookResult = ReturnType<typeof useEffectiveInterestRatesLazyQuery>;
export type EffectiveInterestRatesSuspenseQueryHookResult = ReturnType<typeof useEffectiveInterestRatesSuspenseQuery>;
export type EffectiveInterestRatesQueryResult = Apollo.QueryResult<EffectiveInterestRatesQuery, EffectiveInterestRatesQueryVariables>;
export const GetManyBloombergIndexesLendingRatesDocument = gql`
    query GetManyBloombergIndexesLendingRates($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!, $valuationDateFilter: String) {
  getManyBloombergIndexesLendingRates(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
    valuationDateFilter: $valuationDateFilter
  ) {
    total
    data {
      ...bloombergIndexFields
    }
  }
}
    ${BloombergIndexFieldsFragmentDoc}`;

/**
 * __useGetManyBloombergIndexesLendingRatesQuery__
 *
 * To run a query within a React component, call `useGetManyBloombergIndexesLendingRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyBloombergIndexesLendingRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyBloombergIndexesLendingRatesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *      valuationDateFilter: // value for 'valuationDateFilter'
 *   },
 * });
 */
export function useGetManyBloombergIndexesLendingRatesQuery(baseOptions: Apollo.QueryHookOptions<GetManyBloombergIndexesLendingRatesQuery, GetManyBloombergIndexesLendingRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyBloombergIndexesLendingRatesQuery, GetManyBloombergIndexesLendingRatesQueryVariables>(GetManyBloombergIndexesLendingRatesDocument, options);
      }
export function useGetManyBloombergIndexesLendingRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyBloombergIndexesLendingRatesQuery, GetManyBloombergIndexesLendingRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyBloombergIndexesLendingRatesQuery, GetManyBloombergIndexesLendingRatesQueryVariables>(GetManyBloombergIndexesLendingRatesDocument, options);
        }
export function useGetManyBloombergIndexesLendingRatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyBloombergIndexesLendingRatesQuery, GetManyBloombergIndexesLendingRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyBloombergIndexesLendingRatesQuery, GetManyBloombergIndexesLendingRatesQueryVariables>(GetManyBloombergIndexesLendingRatesDocument, options);
        }
export type GetManyBloombergIndexesLendingRatesQueryHookResult = ReturnType<typeof useGetManyBloombergIndexesLendingRatesQuery>;
export type GetManyBloombergIndexesLendingRatesLazyQueryHookResult = ReturnType<typeof useGetManyBloombergIndexesLendingRatesLazyQuery>;
export type GetManyBloombergIndexesLendingRatesSuspenseQueryHookResult = ReturnType<typeof useGetManyBloombergIndexesLendingRatesSuspenseQuery>;
export type GetManyBloombergIndexesLendingRatesQueryResult = Apollo.QueryResult<GetManyBloombergIndexesLendingRatesQuery, GetManyBloombergIndexesLendingRatesQueryVariables>;
export const GetManyBloombergIndexesRiskFreeRatesDocument = gql`
    query GetManyBloombergIndexesRiskFreeRates($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!, $valuationDateFilter: String, $regionFilter: String) {
  getManyBloombergIndexesRiskFreeRates(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
    valuationDateFilter: $valuationDateFilter
    regionFilter: $regionFilter
  ) {
    total
    data {
      ...bloombergIndexFields
    }
  }
}
    ${BloombergIndexFieldsFragmentDoc}`;

/**
 * __useGetManyBloombergIndexesRiskFreeRatesQuery__
 *
 * To run a query within a React component, call `useGetManyBloombergIndexesRiskFreeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyBloombergIndexesRiskFreeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyBloombergIndexesRiskFreeRatesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *      valuationDateFilter: // value for 'valuationDateFilter'
 *      regionFilter: // value for 'regionFilter'
 *   },
 * });
 */
export function useGetManyBloombergIndexesRiskFreeRatesQuery(baseOptions: Apollo.QueryHookOptions<GetManyBloombergIndexesRiskFreeRatesQuery, GetManyBloombergIndexesRiskFreeRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyBloombergIndexesRiskFreeRatesQuery, GetManyBloombergIndexesRiskFreeRatesQueryVariables>(GetManyBloombergIndexesRiskFreeRatesDocument, options);
      }
export function useGetManyBloombergIndexesRiskFreeRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyBloombergIndexesRiskFreeRatesQuery, GetManyBloombergIndexesRiskFreeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyBloombergIndexesRiskFreeRatesQuery, GetManyBloombergIndexesRiskFreeRatesQueryVariables>(GetManyBloombergIndexesRiskFreeRatesDocument, options);
        }
export function useGetManyBloombergIndexesRiskFreeRatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyBloombergIndexesRiskFreeRatesQuery, GetManyBloombergIndexesRiskFreeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyBloombergIndexesRiskFreeRatesQuery, GetManyBloombergIndexesRiskFreeRatesQueryVariables>(GetManyBloombergIndexesRiskFreeRatesDocument, options);
        }
export type GetManyBloombergIndexesRiskFreeRatesQueryHookResult = ReturnType<typeof useGetManyBloombergIndexesRiskFreeRatesQuery>;
export type GetManyBloombergIndexesRiskFreeRatesLazyQueryHookResult = ReturnType<typeof useGetManyBloombergIndexesRiskFreeRatesLazyQuery>;
export type GetManyBloombergIndexesRiskFreeRatesSuspenseQueryHookResult = ReturnType<typeof useGetManyBloombergIndexesRiskFreeRatesSuspenseQuery>;
export type GetManyBloombergIndexesRiskFreeRatesQueryResult = Apollo.QueryResult<GetManyBloombergIndexesRiskFreeRatesQuery, GetManyBloombergIndexesRiskFreeRatesQueryVariables>;
export const ExportManyBloombergIndexesLendingRatesDocument = gql`
    query ExportManyBloombergIndexesLendingRates($keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!, $valuationDateFilter: String) {
  exportManyBloombergIndexesLendingRates(
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
    valuationDateFilter: $valuationDateFilter
  ) {
    ...exportedFileFields
  }
}
    ${ExportedFileFieldsFragmentDoc}`;

/**
 * __useExportManyBloombergIndexesLendingRatesQuery__
 *
 * To run a query within a React component, call `useExportManyBloombergIndexesLendingRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportManyBloombergIndexesLendingRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportManyBloombergIndexesLendingRatesQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *      valuationDateFilter: // value for 'valuationDateFilter'
 *   },
 * });
 */
export function useExportManyBloombergIndexesLendingRatesQuery(baseOptions: Apollo.QueryHookOptions<ExportManyBloombergIndexesLendingRatesQuery, ExportManyBloombergIndexesLendingRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportManyBloombergIndexesLendingRatesQuery, ExportManyBloombergIndexesLendingRatesQueryVariables>(ExportManyBloombergIndexesLendingRatesDocument, options);
      }
export function useExportManyBloombergIndexesLendingRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportManyBloombergIndexesLendingRatesQuery, ExportManyBloombergIndexesLendingRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportManyBloombergIndexesLendingRatesQuery, ExportManyBloombergIndexesLendingRatesQueryVariables>(ExportManyBloombergIndexesLendingRatesDocument, options);
        }
export function useExportManyBloombergIndexesLendingRatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExportManyBloombergIndexesLendingRatesQuery, ExportManyBloombergIndexesLendingRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportManyBloombergIndexesLendingRatesQuery, ExportManyBloombergIndexesLendingRatesQueryVariables>(ExportManyBloombergIndexesLendingRatesDocument, options);
        }
export type ExportManyBloombergIndexesLendingRatesQueryHookResult = ReturnType<typeof useExportManyBloombergIndexesLendingRatesQuery>;
export type ExportManyBloombergIndexesLendingRatesLazyQueryHookResult = ReturnType<typeof useExportManyBloombergIndexesLendingRatesLazyQuery>;
export type ExportManyBloombergIndexesLendingRatesSuspenseQueryHookResult = ReturnType<typeof useExportManyBloombergIndexesLendingRatesSuspenseQuery>;
export type ExportManyBloombergIndexesLendingRatesQueryResult = Apollo.QueryResult<ExportManyBloombergIndexesLendingRatesQuery, ExportManyBloombergIndexesLendingRatesQueryVariables>;
export const ExportManyBloombergIndexesRiskFreeRatesDocument = gql`
    query ExportManyBloombergIndexesRiskFreeRates($keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!, $valuationDateFilter: String, $regionFilter: String) {
  exportManyBloombergIndexesRiskFreeRates(
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
    valuationDateFilter: $valuationDateFilter
    regionFilter: $regionFilter
  ) {
    ...exportedFileFields
  }
}
    ${ExportedFileFieldsFragmentDoc}`;

/**
 * __useExportManyBloombergIndexesRiskFreeRatesQuery__
 *
 * To run a query within a React component, call `useExportManyBloombergIndexesRiskFreeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportManyBloombergIndexesRiskFreeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportManyBloombergIndexesRiskFreeRatesQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *      valuationDateFilter: // value for 'valuationDateFilter'
 *      regionFilter: // value for 'regionFilter'
 *   },
 * });
 */
export function useExportManyBloombergIndexesRiskFreeRatesQuery(baseOptions: Apollo.QueryHookOptions<ExportManyBloombergIndexesRiskFreeRatesQuery, ExportManyBloombergIndexesRiskFreeRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportManyBloombergIndexesRiskFreeRatesQuery, ExportManyBloombergIndexesRiskFreeRatesQueryVariables>(ExportManyBloombergIndexesRiskFreeRatesDocument, options);
      }
export function useExportManyBloombergIndexesRiskFreeRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportManyBloombergIndexesRiskFreeRatesQuery, ExportManyBloombergIndexesRiskFreeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportManyBloombergIndexesRiskFreeRatesQuery, ExportManyBloombergIndexesRiskFreeRatesQueryVariables>(ExportManyBloombergIndexesRiskFreeRatesDocument, options);
        }
export function useExportManyBloombergIndexesRiskFreeRatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExportManyBloombergIndexesRiskFreeRatesQuery, ExportManyBloombergIndexesRiskFreeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportManyBloombergIndexesRiskFreeRatesQuery, ExportManyBloombergIndexesRiskFreeRatesQueryVariables>(ExportManyBloombergIndexesRiskFreeRatesDocument, options);
        }
export type ExportManyBloombergIndexesRiskFreeRatesQueryHookResult = ReturnType<typeof useExportManyBloombergIndexesRiskFreeRatesQuery>;
export type ExportManyBloombergIndexesRiskFreeRatesLazyQueryHookResult = ReturnType<typeof useExportManyBloombergIndexesRiskFreeRatesLazyQuery>;
export type ExportManyBloombergIndexesRiskFreeRatesSuspenseQueryHookResult = ReturnType<typeof useExportManyBloombergIndexesRiskFreeRatesSuspenseQuery>;
export type ExportManyBloombergIndexesRiskFreeRatesQueryResult = Apollo.QueryResult<ExportManyBloombergIndexesRiskFreeRatesQuery, ExportManyBloombergIndexesRiskFreeRatesQueryVariables>;
export const GetManyBloombergCpisDocument = gql`
    query GetManyBloombergCpis($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  getManyBloombergCpis(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    total
    data {
      ...bloombergCpiFields
    }
  }
}
    ${BloombergCpiFieldsFragmentDoc}`;

/**
 * __useGetManyBloombergCpisQuery__
 *
 * To run a query within a React component, call `useGetManyBloombergCpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyBloombergCpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyBloombergCpisQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useGetManyBloombergCpisQuery(baseOptions: Apollo.QueryHookOptions<GetManyBloombergCpisQuery, GetManyBloombergCpisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyBloombergCpisQuery, GetManyBloombergCpisQueryVariables>(GetManyBloombergCpisDocument, options);
      }
export function useGetManyBloombergCpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyBloombergCpisQuery, GetManyBloombergCpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyBloombergCpisQuery, GetManyBloombergCpisQueryVariables>(GetManyBloombergCpisDocument, options);
        }
export function useGetManyBloombergCpisSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyBloombergCpisQuery, GetManyBloombergCpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyBloombergCpisQuery, GetManyBloombergCpisQueryVariables>(GetManyBloombergCpisDocument, options);
        }
export type GetManyBloombergCpisQueryHookResult = ReturnType<typeof useGetManyBloombergCpisQuery>;
export type GetManyBloombergCpisLazyQueryHookResult = ReturnType<typeof useGetManyBloombergCpisLazyQuery>;
export type GetManyBloombergCpisSuspenseQueryHookResult = ReturnType<typeof useGetManyBloombergCpisSuspenseQuery>;
export type GetManyBloombergCpisQueryResult = Apollo.QueryResult<GetManyBloombergCpisQuery, GetManyBloombergCpisQueryVariables>;
export const ExportManyBloombergCpisDocument = gql`
    query ExportManyBloombergCpis($keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  exportManyBloombergCpis(
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    ...exportedFileFields
  }
}
    ${ExportedFileFieldsFragmentDoc}`;

/**
 * __useExportManyBloombergCpisQuery__
 *
 * To run a query within a React component, call `useExportManyBloombergCpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportManyBloombergCpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportManyBloombergCpisQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useExportManyBloombergCpisQuery(baseOptions: Apollo.QueryHookOptions<ExportManyBloombergCpisQuery, ExportManyBloombergCpisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportManyBloombergCpisQuery, ExportManyBloombergCpisQueryVariables>(ExportManyBloombergCpisDocument, options);
      }
export function useExportManyBloombergCpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportManyBloombergCpisQuery, ExportManyBloombergCpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportManyBloombergCpisQuery, ExportManyBloombergCpisQueryVariables>(ExportManyBloombergCpisDocument, options);
        }
export function useExportManyBloombergCpisSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExportManyBloombergCpisQuery, ExportManyBloombergCpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportManyBloombergCpisQuery, ExportManyBloombergCpisQueryVariables>(ExportManyBloombergCpisDocument, options);
        }
export type ExportManyBloombergCpisQueryHookResult = ReturnType<typeof useExportManyBloombergCpisQuery>;
export type ExportManyBloombergCpisLazyQueryHookResult = ReturnType<typeof useExportManyBloombergCpisLazyQuery>;
export type ExportManyBloombergCpisSuspenseQueryHookResult = ReturnType<typeof useExportManyBloombergCpisSuspenseQuery>;
export type ExportManyBloombergCpisQueryResult = Apollo.QueryResult<ExportManyBloombergCpisQuery, ExportManyBloombergCpisQueryVariables>;
export const GetManyBloombergCpiAveragesDocument = gql`
    query GetManyBloombergCpiAverages($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!, $periodFilter: String) {
  getManyBloombergCpiAverages(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
    periodFilter: $periodFilter
  ) {
    total
    data {
      ...bloombergCpiAverageFields
    }
  }
}
    ${BloombergCpiAverageFieldsFragmentDoc}`;

/**
 * __useGetManyBloombergCpiAveragesQuery__
 *
 * To run a query within a React component, call `useGetManyBloombergCpiAveragesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyBloombergCpiAveragesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyBloombergCpiAveragesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *      periodFilter: // value for 'periodFilter'
 *   },
 * });
 */
export function useGetManyBloombergCpiAveragesQuery(baseOptions: Apollo.QueryHookOptions<GetManyBloombergCpiAveragesQuery, GetManyBloombergCpiAveragesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyBloombergCpiAveragesQuery, GetManyBloombergCpiAveragesQueryVariables>(GetManyBloombergCpiAveragesDocument, options);
      }
export function useGetManyBloombergCpiAveragesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyBloombergCpiAveragesQuery, GetManyBloombergCpiAveragesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyBloombergCpiAveragesQuery, GetManyBloombergCpiAveragesQueryVariables>(GetManyBloombergCpiAveragesDocument, options);
        }
export function useGetManyBloombergCpiAveragesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyBloombergCpiAveragesQuery, GetManyBloombergCpiAveragesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyBloombergCpiAveragesQuery, GetManyBloombergCpiAveragesQueryVariables>(GetManyBloombergCpiAveragesDocument, options);
        }
export type GetManyBloombergCpiAveragesQueryHookResult = ReturnType<typeof useGetManyBloombergCpiAveragesQuery>;
export type GetManyBloombergCpiAveragesLazyQueryHookResult = ReturnType<typeof useGetManyBloombergCpiAveragesLazyQuery>;
export type GetManyBloombergCpiAveragesSuspenseQueryHookResult = ReturnType<typeof useGetManyBloombergCpiAveragesSuspenseQuery>;
export type GetManyBloombergCpiAveragesQueryResult = Apollo.QueryResult<GetManyBloombergCpiAveragesQuery, GetManyBloombergCpiAveragesQueryVariables>;
export const ExportManyBloombergCpiAveragesDocument = gql`
    query ExportManyBloombergCpiAverages($keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!, $periodFilter: String) {
  exportManyBloombergCpiAverages(
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
    periodFilter: $periodFilter
  ) {
    ...exportedFileFields
  }
}
    ${ExportedFileFieldsFragmentDoc}`;

/**
 * __useExportManyBloombergCpiAveragesQuery__
 *
 * To run a query within a React component, call `useExportManyBloombergCpiAveragesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportManyBloombergCpiAveragesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportManyBloombergCpiAveragesQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *      periodFilter: // value for 'periodFilter'
 *   },
 * });
 */
export function useExportManyBloombergCpiAveragesQuery(baseOptions: Apollo.QueryHookOptions<ExportManyBloombergCpiAveragesQuery, ExportManyBloombergCpiAveragesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportManyBloombergCpiAveragesQuery, ExportManyBloombergCpiAveragesQueryVariables>(ExportManyBloombergCpiAveragesDocument, options);
      }
export function useExportManyBloombergCpiAveragesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportManyBloombergCpiAveragesQuery, ExportManyBloombergCpiAveragesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportManyBloombergCpiAveragesQuery, ExportManyBloombergCpiAveragesQueryVariables>(ExportManyBloombergCpiAveragesDocument, options);
        }
export function useExportManyBloombergCpiAveragesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExportManyBloombergCpiAveragesQuery, ExportManyBloombergCpiAveragesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportManyBloombergCpiAveragesQuery, ExportManyBloombergCpiAveragesQueryVariables>(ExportManyBloombergCpiAveragesDocument, options);
        }
export type ExportManyBloombergCpiAveragesQueryHookResult = ReturnType<typeof useExportManyBloombergCpiAveragesQuery>;
export type ExportManyBloombergCpiAveragesLazyQueryHookResult = ReturnType<typeof useExportManyBloombergCpiAveragesLazyQuery>;
export type ExportManyBloombergCpiAveragesSuspenseQueryHookResult = ReturnType<typeof useExportManyBloombergCpiAveragesSuspenseQuery>;
export type ExportManyBloombergCpiAveragesQueryResult = Apollo.QueryResult<ExportManyBloombergCpiAveragesQuery, ExportManyBloombergCpiAveragesQueryVariables>;
export const GetManyBloombergExchangeRatesDocument = gql`
    query GetManyBloombergExchangeRates($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  getManyBloombergExchangeRates(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    total
    data {
      ...bloombergExchangeRateFields
    }
  }
}
    ${BloombergExchangeRateFieldsFragmentDoc}`;

/**
 * __useGetManyBloombergExchangeRatesQuery__
 *
 * To run a query within a React component, call `useGetManyBloombergExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyBloombergExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyBloombergExchangeRatesQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useGetManyBloombergExchangeRatesQuery(baseOptions: Apollo.QueryHookOptions<GetManyBloombergExchangeRatesQuery, GetManyBloombergExchangeRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyBloombergExchangeRatesQuery, GetManyBloombergExchangeRatesQueryVariables>(GetManyBloombergExchangeRatesDocument, options);
      }
export function useGetManyBloombergExchangeRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyBloombergExchangeRatesQuery, GetManyBloombergExchangeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyBloombergExchangeRatesQuery, GetManyBloombergExchangeRatesQueryVariables>(GetManyBloombergExchangeRatesDocument, options);
        }
export function useGetManyBloombergExchangeRatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyBloombergExchangeRatesQuery, GetManyBloombergExchangeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyBloombergExchangeRatesQuery, GetManyBloombergExchangeRatesQueryVariables>(GetManyBloombergExchangeRatesDocument, options);
        }
export type GetManyBloombergExchangeRatesQueryHookResult = ReturnType<typeof useGetManyBloombergExchangeRatesQuery>;
export type GetManyBloombergExchangeRatesLazyQueryHookResult = ReturnType<typeof useGetManyBloombergExchangeRatesLazyQuery>;
export type GetManyBloombergExchangeRatesSuspenseQueryHookResult = ReturnType<typeof useGetManyBloombergExchangeRatesSuspenseQuery>;
export type GetManyBloombergExchangeRatesQueryResult = Apollo.QueryResult<GetManyBloombergExchangeRatesQuery, GetManyBloombergExchangeRatesQueryVariables>;
export const ExportManyBloombergExchangeRatesDocument = gql`
    query ExportManyBloombergExchangeRates($keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  exportManyBloombergExchangeRates(
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    ...exportedFileFields
  }
}
    ${ExportedFileFieldsFragmentDoc}`;

/**
 * __useExportManyBloombergExchangeRatesQuery__
 *
 * To run a query within a React component, call `useExportManyBloombergExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportManyBloombergExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportManyBloombergExchangeRatesQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useExportManyBloombergExchangeRatesQuery(baseOptions: Apollo.QueryHookOptions<ExportManyBloombergExchangeRatesQuery, ExportManyBloombergExchangeRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportManyBloombergExchangeRatesQuery, ExportManyBloombergExchangeRatesQueryVariables>(ExportManyBloombergExchangeRatesDocument, options);
      }
export function useExportManyBloombergExchangeRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportManyBloombergExchangeRatesQuery, ExportManyBloombergExchangeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportManyBloombergExchangeRatesQuery, ExportManyBloombergExchangeRatesQueryVariables>(ExportManyBloombergExchangeRatesDocument, options);
        }
export function useExportManyBloombergExchangeRatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExportManyBloombergExchangeRatesQuery, ExportManyBloombergExchangeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportManyBloombergExchangeRatesQuery, ExportManyBloombergExchangeRatesQueryVariables>(ExportManyBloombergExchangeRatesDocument, options);
        }
export type ExportManyBloombergExchangeRatesQueryHookResult = ReturnType<typeof useExportManyBloombergExchangeRatesQuery>;
export type ExportManyBloombergExchangeRatesLazyQueryHookResult = ReturnType<typeof useExportManyBloombergExchangeRatesLazyQuery>;
export type ExportManyBloombergExchangeRatesSuspenseQueryHookResult = ReturnType<typeof useExportManyBloombergExchangeRatesSuspenseQuery>;
export type ExportManyBloombergExchangeRatesQueryResult = Apollo.QueryResult<ExportManyBloombergExchangeRatesQuery, ExportManyBloombergExchangeRatesQueryVariables>;
export const GetManyBloombergBondYieldsDocument = gql`
    query GetManyBloombergBondYields($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!, $valuationDateFilter: String, $ratingFromFilter: String, $ratingToFilter: String, $timeToMaturityMinFilter: Float, $timeToMaturityMaxFilter: Float) {
  getManyBloombergBondYields(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
    valuationDateFilter: $valuationDateFilter
    ratingFromFilter: $ratingFromFilter
    ratingToFilter: $ratingToFilter
    timeToMaturityMinFilter: $timeToMaturityMinFilter
    timeToMaturityMaxFilter: $timeToMaturityMaxFilter
  ) {
    total
    data {
      ...bloombergBondYieldFields
    }
  }
}
    ${BloombergBondYieldFieldsFragmentDoc}`;

/**
 * __useGetManyBloombergBondYieldsQuery__
 *
 * To run a query within a React component, call `useGetManyBloombergBondYieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyBloombergBondYieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyBloombergBondYieldsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *      valuationDateFilter: // value for 'valuationDateFilter'
 *      ratingFromFilter: // value for 'ratingFromFilter'
 *      ratingToFilter: // value for 'ratingToFilter'
 *      timeToMaturityMinFilter: // value for 'timeToMaturityMinFilter'
 *      timeToMaturityMaxFilter: // value for 'timeToMaturityMaxFilter'
 *   },
 * });
 */
export function useGetManyBloombergBondYieldsQuery(baseOptions: Apollo.QueryHookOptions<GetManyBloombergBondYieldsQuery, GetManyBloombergBondYieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyBloombergBondYieldsQuery, GetManyBloombergBondYieldsQueryVariables>(GetManyBloombergBondYieldsDocument, options);
      }
export function useGetManyBloombergBondYieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyBloombergBondYieldsQuery, GetManyBloombergBondYieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyBloombergBondYieldsQuery, GetManyBloombergBondYieldsQueryVariables>(GetManyBloombergBondYieldsDocument, options);
        }
export function useGetManyBloombergBondYieldsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyBloombergBondYieldsQuery, GetManyBloombergBondYieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyBloombergBondYieldsQuery, GetManyBloombergBondYieldsQueryVariables>(GetManyBloombergBondYieldsDocument, options);
        }
export type GetManyBloombergBondYieldsQueryHookResult = ReturnType<typeof useGetManyBloombergBondYieldsQuery>;
export type GetManyBloombergBondYieldsLazyQueryHookResult = ReturnType<typeof useGetManyBloombergBondYieldsLazyQuery>;
export type GetManyBloombergBondYieldsSuspenseQueryHookResult = ReturnType<typeof useGetManyBloombergBondYieldsSuspenseQuery>;
export type GetManyBloombergBondYieldsQueryResult = Apollo.QueryResult<GetManyBloombergBondYieldsQuery, GetManyBloombergBondYieldsQueryVariables>;
export const ExportManyBloombergBondYieldsDocument = gql`
    query ExportManyBloombergBondYields($keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!, $valuationDateFilter: String, $ratingFromFilter: String, $ratingToFilter: String, $timeToMaturityMinFilter: Float, $timeToMaturityMaxFilter: Float) {
  exportManyBloombergBondYields(
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
    valuationDateFilter: $valuationDateFilter
    ratingFromFilter: $ratingFromFilter
    ratingToFilter: $ratingToFilter
    timeToMaturityMinFilter: $timeToMaturityMinFilter
    timeToMaturityMaxFilter: $timeToMaturityMaxFilter
  ) {
    ...exportedFileFields
  }
}
    ${ExportedFileFieldsFragmentDoc}`;

/**
 * __useExportManyBloombergBondYieldsQuery__
 *
 * To run a query within a React component, call `useExportManyBloombergBondYieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportManyBloombergBondYieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportManyBloombergBondYieldsQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *      valuationDateFilter: // value for 'valuationDateFilter'
 *      ratingFromFilter: // value for 'ratingFromFilter'
 *      ratingToFilter: // value for 'ratingToFilter'
 *      timeToMaturityMinFilter: // value for 'timeToMaturityMinFilter'
 *      timeToMaturityMaxFilter: // value for 'timeToMaturityMaxFilter'
 *   },
 * });
 */
export function useExportManyBloombergBondYieldsQuery(baseOptions: Apollo.QueryHookOptions<ExportManyBloombergBondYieldsQuery, ExportManyBloombergBondYieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportManyBloombergBondYieldsQuery, ExportManyBloombergBondYieldsQueryVariables>(ExportManyBloombergBondYieldsDocument, options);
      }
export function useExportManyBloombergBondYieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportManyBloombergBondYieldsQuery, ExportManyBloombergBondYieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportManyBloombergBondYieldsQuery, ExportManyBloombergBondYieldsQueryVariables>(ExportManyBloombergBondYieldsDocument, options);
        }
export function useExportManyBloombergBondYieldsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExportManyBloombergBondYieldsQuery, ExportManyBloombergBondYieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExportManyBloombergBondYieldsQuery, ExportManyBloombergBondYieldsQueryVariables>(ExportManyBloombergBondYieldsDocument, options);
        }
export type ExportManyBloombergBondYieldsQueryHookResult = ReturnType<typeof useExportManyBloombergBondYieldsQuery>;
export type ExportManyBloombergBondYieldsLazyQueryHookResult = ReturnType<typeof useExportManyBloombergBondYieldsLazyQuery>;
export type ExportManyBloombergBondYieldsSuspenseQueryHookResult = ReturnType<typeof useExportManyBloombergBondYieldsSuspenseQuery>;
export type ExportManyBloombergBondYieldsQueryResult = Apollo.QueryResult<ExportManyBloombergBondYieldsQuery, ExportManyBloombergBondYieldsQueryVariables>;
export const CreateOneIssueDocument = gql`
    mutation CreateOneIssue($title: String!, $description: String) {
  createOneIssue(title: $title, description: $description) {
    ...issueFields
  }
}
    ${IssueFieldsFragmentDoc}`;
export type CreateOneIssueMutationFn = Apollo.MutationFunction<CreateOneIssueMutation, CreateOneIssueMutationVariables>;

/**
 * __useCreateOneIssueMutation__
 *
 * To run a mutation, you first call `useCreateOneIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneIssueMutation, { data, loading, error }] = useCreateOneIssueMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateOneIssueMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneIssueMutation, CreateOneIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneIssueMutation, CreateOneIssueMutationVariables>(CreateOneIssueDocument, options);
      }
export type CreateOneIssueMutationHookResult = ReturnType<typeof useCreateOneIssueMutation>;
export type CreateOneIssueMutationResult = Apollo.MutationResult<CreateOneIssueMutation>;
export type CreateOneIssueMutationOptions = Apollo.BaseMutationOptions<CreateOneIssueMutation, CreateOneIssueMutationVariables>;
export const GetManyResearchReportsDocument = gql`
    query GetManyResearchReports($pageSize: Int!, $pageIndex: Int!, $keyword: String!, $orderBy: String!, $order: String!, $columnsData: [ColumnsData!]!, $selectedFields: [String]!) {
  getManyResearchReports(
    pageSize: $pageSize
    pageIndex: $pageIndex
    keyword: $keyword
    orderBy: $orderBy
    order: $order
    columnsData: $columnsData
    selectedFields: $selectedFields
  ) {
    total
    data {
      ...researchReportFields
    }
  }
}
    ${ResearchReportFieldsFragmentDoc}`;

/**
 * __useGetManyResearchReportsQuery__
 *
 * To run a query within a React component, call `useGetManyResearchReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyResearchReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyResearchReportsQuery({
 *   variables: {
 *      pageSize: // value for 'pageSize'
 *      pageIndex: // value for 'pageIndex'
 *      keyword: // value for 'keyword'
 *      orderBy: // value for 'orderBy'
 *      order: // value for 'order'
 *      columnsData: // value for 'columnsData'
 *      selectedFields: // value for 'selectedFields'
 *   },
 * });
 */
export function useGetManyResearchReportsQuery(baseOptions: Apollo.QueryHookOptions<GetManyResearchReportsQuery, GetManyResearchReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyResearchReportsQuery, GetManyResearchReportsQueryVariables>(GetManyResearchReportsDocument, options);
      }
export function useGetManyResearchReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyResearchReportsQuery, GetManyResearchReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyResearchReportsQuery, GetManyResearchReportsQueryVariables>(GetManyResearchReportsDocument, options);
        }
export function useGetManyResearchReportsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyResearchReportsQuery, GetManyResearchReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyResearchReportsQuery, GetManyResearchReportsQueryVariables>(GetManyResearchReportsDocument, options);
        }
export type GetManyResearchReportsQueryHookResult = ReturnType<typeof useGetManyResearchReportsQuery>;
export type GetManyResearchReportsLazyQueryHookResult = ReturnType<typeof useGetManyResearchReportsLazyQuery>;
export type GetManyResearchReportsSuspenseQueryHookResult = ReturnType<typeof useGetManyResearchReportsSuspenseQuery>;
export type GetManyResearchReportsQueryResult = Apollo.QueryResult<GetManyResearchReportsQuery, GetManyResearchReportsQueryVariables>;
export const UpdateOneResearchReportDocument = gql`
    mutation UpdateOneResearchReport($data: ResearchReportUpdateOneInput!) {
  updateOneResearchReport(data: $data) {
    ...researchReportFields
  }
}
    ${ResearchReportFieldsFragmentDoc}`;
export type UpdateOneResearchReportMutationFn = Apollo.MutationFunction<UpdateOneResearchReportMutation, UpdateOneResearchReportMutationVariables>;

/**
 * __useUpdateOneResearchReportMutation__
 *
 * To run a mutation, you first call `useUpdateOneResearchReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneResearchReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneResearchReportMutation, { data, loading, error }] = useUpdateOneResearchReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneResearchReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneResearchReportMutation, UpdateOneResearchReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneResearchReportMutation, UpdateOneResearchReportMutationVariables>(UpdateOneResearchReportDocument, options);
      }
export type UpdateOneResearchReportMutationHookResult = ReturnType<typeof useUpdateOneResearchReportMutation>;
export type UpdateOneResearchReportMutationResult = Apollo.MutationResult<UpdateOneResearchReportMutation>;
export type UpdateOneResearchReportMutationOptions = Apollo.BaseMutationOptions<UpdateOneResearchReportMutation, UpdateOneResearchReportMutationVariables>;
export const DeleteOneResearchReportDocument = gql`
    mutation DeleteOneResearchReport($id: ID!) {
  deleteOneResearchReport(id: $id) {
    id
  }
}
    `;
export type DeleteOneResearchReportMutationFn = Apollo.MutationFunction<DeleteOneResearchReportMutation, DeleteOneResearchReportMutationVariables>;

/**
 * __useDeleteOneResearchReportMutation__
 *
 * To run a mutation, you first call `useDeleteOneResearchReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneResearchReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneResearchReportMutation, { data, loading, error }] = useDeleteOneResearchReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOneResearchReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneResearchReportMutation, DeleteOneResearchReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneResearchReportMutation, DeleteOneResearchReportMutationVariables>(DeleteOneResearchReportDocument, options);
      }
export type DeleteOneResearchReportMutationHookResult = ReturnType<typeof useDeleteOneResearchReportMutation>;
export type DeleteOneResearchReportMutationResult = Apollo.MutationResult<DeleteOneResearchReportMutation>;
export type DeleteOneResearchReportMutationOptions = Apollo.BaseMutationOptions<DeleteOneResearchReportMutation, DeleteOneResearchReportMutationVariables>;
export const CreateOneResearchReportDocument = gql`
    mutation CreateOneResearchReport($data: ResearchReportCreateOneInput!) {
  createOneResearchReport(data: $data) {
    ...researchReportFields
  }
}
    ${ResearchReportFieldsFragmentDoc}`;
export type CreateOneResearchReportMutationFn = Apollo.MutationFunction<CreateOneResearchReportMutation, CreateOneResearchReportMutationVariables>;

/**
 * __useCreateOneResearchReportMutation__
 *
 * To run a mutation, you first call `useCreateOneResearchReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneResearchReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneResearchReportMutation, { data, loading, error }] = useCreateOneResearchReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneResearchReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneResearchReportMutation, CreateOneResearchReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneResearchReportMutation, CreateOneResearchReportMutationVariables>(CreateOneResearchReportDocument, options);
      }
export type CreateOneResearchReportMutationHookResult = ReturnType<typeof useCreateOneResearchReportMutation>;
export type CreateOneResearchReportMutationResult = Apollo.MutationResult<CreateOneResearchReportMutation>;
export type CreateOneResearchReportMutationOptions = Apollo.BaseMutationOptions<CreateOneResearchReportMutation, CreateOneResearchReportMutationVariables>;
export const GetManyListedCompaniesByKeywordDocument = gql`
    query GetManyListedCompaniesByKeyword($keyword: String!) {
  getManyListedCompaniesByKeyword(keyword: $keyword) {
    total
    data {
      ...listedCompanyFields
    }
  }
}
    ${ListedCompanyFieldsFragmentDoc}`;

/**
 * __useGetManyListedCompaniesByKeywordQuery__
 *
 * To run a query within a React component, call `useGetManyListedCompaniesByKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyListedCompaniesByKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyListedCompaniesByKeywordQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGetManyListedCompaniesByKeywordQuery(baseOptions: Apollo.QueryHookOptions<GetManyListedCompaniesByKeywordQuery, GetManyListedCompaniesByKeywordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManyListedCompaniesByKeywordQuery, GetManyListedCompaniesByKeywordQueryVariables>(GetManyListedCompaniesByKeywordDocument, options);
      }
export function useGetManyListedCompaniesByKeywordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyListedCompaniesByKeywordQuery, GetManyListedCompaniesByKeywordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManyListedCompaniesByKeywordQuery, GetManyListedCompaniesByKeywordQueryVariables>(GetManyListedCompaniesByKeywordDocument, options);
        }
export function useGetManyListedCompaniesByKeywordSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetManyListedCompaniesByKeywordQuery, GetManyListedCompaniesByKeywordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetManyListedCompaniesByKeywordQuery, GetManyListedCompaniesByKeywordQueryVariables>(GetManyListedCompaniesByKeywordDocument, options);
        }
export type GetManyListedCompaniesByKeywordQueryHookResult = ReturnType<typeof useGetManyListedCompaniesByKeywordQuery>;
export type GetManyListedCompaniesByKeywordLazyQueryHookResult = ReturnType<typeof useGetManyListedCompaniesByKeywordLazyQuery>;
export type GetManyListedCompaniesByKeywordSuspenseQueryHookResult = ReturnType<typeof useGetManyListedCompaniesByKeywordSuspenseQuery>;
export type GetManyListedCompaniesByKeywordQueryResult = Apollo.QueryResult<GetManyListedCompaniesByKeywordQuery, GetManyListedCompaniesByKeywordQueryVariables>;
export const ExtractKeywordsDocument = gql`
    query ExtractKeywords($text: String!) {
  extractKeywords(text: $text)
}
    `;

/**
 * __useExtractKeywordsQuery__
 *
 * To run a query within a React component, call `useExtractKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExtractKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExtractKeywordsQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useExtractKeywordsQuery(baseOptions: Apollo.QueryHookOptions<ExtractKeywordsQuery, ExtractKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExtractKeywordsQuery, ExtractKeywordsQueryVariables>(ExtractKeywordsDocument, options);
      }
export function useExtractKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExtractKeywordsQuery, ExtractKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExtractKeywordsQuery, ExtractKeywordsQueryVariables>(ExtractKeywordsDocument, options);
        }
export function useExtractKeywordsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ExtractKeywordsQuery, ExtractKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ExtractKeywordsQuery, ExtractKeywordsQueryVariables>(ExtractKeywordsDocument, options);
        }
export type ExtractKeywordsQueryHookResult = ReturnType<typeof useExtractKeywordsQuery>;
export type ExtractKeywordsLazyQueryHookResult = ReturnType<typeof useExtractKeywordsLazyQuery>;
export type ExtractKeywordsSuspenseQueryHookResult = ReturnType<typeof useExtractKeywordsSuspenseQuery>;
export type ExtractKeywordsQueryResult = Apollo.QueryResult<ExtractKeywordsQuery, ExtractKeywordsQueryVariables>;