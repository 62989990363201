import React from "react";

import UploadOutlined from "@mui/icons-material/UploadOutlined";
import Button from "@mui/material/Button";
import { Upload, UploadProps, message } from "antd";
import {
  useUpdateManyComparablesByImportFileMutation,
  useUpdateManyProprietariesByImportFileMutation,
} from "generated/graphql";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { ITableType } from "utils/interface";

const props: UploadProps = {
  name: "file",
  accept: ".xlsx,.xls",
  maxCount: 1,
  beforeUpload: (file, fileList) => {
    const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      message.error("You can only upload Excel file!");
    }

    return isExcel;
  },
  onChange(info) {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed`);
    }
  },
};

type UploadFileProps = {
  tableType: ITableType;
  comparableRefefetchQueries;
  proprietaryRefefetchQueries;
  handleFormClose: () => void;
};

const UploadFile: React.FC<UploadFileProps> = ({
  tableType,
  comparableRefefetchQueries,
  proprietaryRefefetchQueries,
  handleFormClose,
}) => {
  const [updateComparableListByImportedFile] = useUpdateManyComparablesByImportFileMutation({
    refetchQueries: comparableRefefetchQueries,
    awaitRefetchQueries: true,
  });
  const [updateProprietaryListByImportedFile] = useUpdateManyProprietariesByImportFileMutation({
    refetchQueries: proprietaryRefefetchQueries,
    awaitRefetchQueries: true,
  });

  const customRequestFunc = ({ file, onSuccess, onError }: UploadRequestOption) => {
    switch (tableType) {
      case ITableType.COMPARABLE_PPA:
        updateComparableListByImportedFile({
          variables: {
            file,
          },
        })
          .then((response) => {
            onSuccess(response);
            handleFormClose();
          })
          .catch((error) => {
            onError(error);
          });

        break;
      case ITableType.PROPRIETARY_PPA:
        updateProprietaryListByImportedFile({
          variables: {
            file,
          },
        })
          .then((response) => {
            onSuccess(response);
            handleFormClose();
          })
          .catch((error) => {
            onError(error);
          });

        break;
      default:
        break;
    }
  };
  return (
    <Upload {...props} customRequest={customRequestFunc}>
      <Button>
        <UploadOutlined />
        Click to Upload
      </Button>
    </Upload>
  );
};

export default UploadFile;
