import { decodeToken } from "@avista/jwt-client";
import Cookies from "js-cookie";
import moment from "moment";
import { SERVER } from "utils/const";
import { IRefetchQueries, ITableType } from "utils/interface";

export const formatNumber = (num, maximumFractionDigits = 5) => {
  // If not set maximumFractionDigits, default show 3 FractionDigits only.
  return num.toLocaleString("en-US", { maximumFractionDigits });
};

export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};

export const getCurrentDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  return yyyy + mm + dd;
};

export const timezoneDateTimeToDateString = (date) => {
  const dateInMoment = moment(date);
  if (dateInMoment.isValid()) return dateInMoment.format(SERVER.API_DATE_FORMAT);
  return null;
};

export const dateStringToTimezoneDateTime = (dateString) => {
  const dateInMoment = moment(dateString, SERVER.API_DATE_FORMAT);
  if (dateInMoment.isValid()) return dateInMoment.toDate();
  return null;
};

export const roundUpNumber = (value) => {
  return Number(Number(value).toFixed(2));
};

export const roundUpPercentage = (value) => {
  let output = value * 100;
  return roundUpNumber(output);
};

export const getQueryVariablesBase = ({
  keyword,
  rowsPerPage,
  page,
  orderBy,
  order,
  columnsData,
  selectedFields,
  extraFilter,
}: IRefetchQueries) => {
  return {
    keyword: keyword,
    pageSize: rowsPerPage,
    pageIndex: page,
    orderBy: typeof orderBy === "undefined" ? "" : orderBy,
    order,
    columnsData: columnsData,
    selectedFields: selectedFields,
    // extraFilter
    regionFilter: extraFilter?.regionFilter,
    valuationDateFilter: extraFilter?.valuationDateFilter,
    ratingFromFilter: extraFilter?.ratingFromFilter,
    ratingToFilter: extraFilter?.ratingToFilter,
    timeToMaturityMinFilter: extraFilter?.timeToMaturityMinFilter,
    timeToMaturityMaxFilter: extraFilter?.timeToMaturityMaxFilter,
  };
};

export const nullToEmpty = (object) => {
  const newObj = JSON.parse(JSON.stringify(object));
  for (const property in object) {
    if (object[property] === null) {
      newObj[property] = undefined;
    }

    if (property === "GoodwillFull" && (object["GoodwillFull"] === null || object["GoodwillFull"] === "undefined")) {
      newObj["GoodwillFull"] = 0;
    }
    if (
      property === "IntangibleAssets" &&
      (object["IntangibleAssets"] === null || object["IntangibleAssets"] === "undefined")
    ) {
      newObj["IntangibleAssets"] = 0;
    }
  }
  return newObj;
};

export const BloombergDataTableType = [
  ITableType.BLOOMBERG_INDEXES_LENDING_RATE,
  ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE,
  ITableType.BLOOMBERG_CPI,
  ITableType.BLOOMBERG_CPI_AVERAGE,
  ITableType.BLOOMBERG_EXCHANGE_RATE,
  ITableType.BLOOMBERG_BOND_YIELD,
];

export const ImportDataTableType = [ITableType.COMPARABLE_PPA, ITableType.PROPRIETARY_PPA];

export enum BloombergTaskUrlText {
  bondYield = "bond-yield",
  cpi = "cpi",
  cpiAverage = "cpi-average",
  exchangeRate = "exchange-rate",
  index = "index",
}

export const getBloombergTaskUrlText = (tableType: ITableType) => {
  switch (tableType) {
    case ITableType.BLOOMBERG_INDEXES_LENDING_RATE:
      return BloombergTaskUrlText.index;
    case ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE:
      return BloombergTaskUrlText.index;
    case ITableType.BLOOMBERG_CPI:
      return BloombergTaskUrlText.cpi;
    case ITableType.BLOOMBERG_CPI_AVERAGE:
      return BloombergTaskUrlText.cpiAverage;
    case ITableType.BLOOMBERG_EXCHANGE_RATE:
      return BloombergTaskUrlText.exchangeRate;
    case ITableType.BLOOMBERG_BOND_YIELD:
      return BloombergTaskUrlText.bondYield;
    default:
      return null;
  }
};

export const isAllowEdit = (tableType: ITableType) => {
  return !BloombergDataTableType.includes(tableType);
};

export const isAllowImport = (tableType: ITableType) => {
  return ImportDataTableType.includes(tableType);
};

export const isAllowScreenshotDownloadByRow = (tableType: ITableType) => {
  return [
    ITableType.BLOOMBERG_INDEXES_LENDING_RATE,
    ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE,
    ITableType.BLOOMBERG_BOND_YIELD,
  ].includes(tableType);
};

export const isAllowDataExport = (tableType: ITableType) => {
  return BloombergDataTableType.includes(tableType);
};

export const isAllowScreenshotDownloadByCell = (tableType: ITableType) => {
  return [ITableType.BLOOMBERG_CPI, ITableType.BLOOMBERG_CPI_AVERAGE, ITableType.BLOOMBERG_EXCHANGE_RATE].includes(
    tableType
  );
};

export const getCpiScreenshotId = (tableType: ITableType, columnId: string, data: any): string => {
  switch (tableType) {
    case ITableType.BLOOMBERG_CPI:
    case ITableType.BLOOMBERG_EXCHANGE_RATE:
      return data[`${columnId}_screenshot`];
    case ITableType.BLOOMBERG_CPI_AVERAGE:
      return data[`${columnId}_id`];
    default:
      break;
  }
  return "";
};

export const isHideFirstColumn = (tableType: ITableType) => {
  return [ITableType.BLOOMBERG_CPI, ITableType.BLOOMBERG_CPI_AVERAGE, ITableType.BLOOMBERG_EXCHANGE_RATE].includes(
    tableType
  );
};

export const getDefaultRowsPerPage = (tableType: ITableType) => {
  return BloombergDataTableType.includes(tableType) ? 100 : null;
};

export const getResearchReportFileUrl = (id: string, filename: string): string => {
  return `${SERVER.BACKEND_HOST}/api/research-report/${id}/file/${filename}`;
};

export const login = () => {
  const appUrl = window.location.href;
  window.location.replace(`${SERVER.LOGIN_HOST}/login?redirectUrl=${appUrl}`);
};

export const getLoginData = () => {
  const token = Cookies.get(SERVER.COOKIE_NAME);
  return token ? decodeToken(token) : null;
};
