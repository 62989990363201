import { Moment } from "moment";

export const SERVER = {
  APP_URL: process.env.REACT_APP_URL,
  BACKEND_HOST: process.env.REACT_APP_BACKEND_HOST,
  GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL,
  LOGIN_HOST: process.env.REACT_APP_LOGIN_HOST,
  COOKIE_NAME: process.env.REACT_APP_COOKIE_NAME,
  DATE_FORMAT: process.env.REACT_APP_DATE_FORMAT ?? "DD/MM/YYYY",
  URL_DATE_FORMAT: process.env.REACT_APP_URL_DATE_FORMAT ?? "YYYYMMDD",
  API_DATE_FORMAT: process.env.REACT_APP_API_DATE_FORMAT ?? "YYYY-MM-DD",
  BOND_YIELD_RATING_RANK: process.env.REACT_APP_BOND_YIELD_RATING_RANK
    ? process.env.REACT_APP_BOND_YIELD_RATING_RANK.split(",").map((v) => v.trim())
    : [],
};

export const toUrlDate = (date: Moment) => date.format(SERVER.URL_DATE_FORMAT);

export enum BloombergRiskFreeRateRegions {
  "Hong Kong" = "Hong Kong",
  "China" = "China",
  "Indonesia" = "Indonesia",
  "Japan" = "Japan",
  "US" = "US",
}
