import { ColumnsData, ComparableCompanyUpdateOneInput, IntangibleAssetUpdateOneInput } from "../../generated/graphql";
import { AlertColor } from "@mui/material/Alert";
interface ExcelWidth {
  wpx: number;
}

export enum IValueDisplayMode {
  LISTING,
  EDIT,
  EXCEL,
}

export interface IRefetchQueries {
  keyword: string;
  rowsPerPage: number;
  page: number;
  orderBy: string;
  order: IOrder;
  columnsData: ColumnsData[];
  selectedFields: string[];
  extraFilter?: ExtraFilter;
}

export interface IAllSearchFields {
  id: string;
  label: string;
}

export interface ISearchState {
  allSearchFields: IAllSearchFields[];
  selectedSearchField: string[];
  tmpSelectedSearchField: string[];
  keyword: string;
  tmpKeyword: string;
  extraFilter?: ExtraFilter;
}

export interface ExtraFilter {
  regionFilter?: string;
  valuationDateFilter?: string;
  ratingFromFilter?: string;
  ratingToFilter?: string;
  timeToMaturityMinFilter?: number;
  timeToMaturityMaxFilter?: number;
}

export interface IFormData {
  id: string;
  value: string[];
}
export interface IFormState {
  initValues: Object;
  isSubmitting: boolean;
  data: IFormData;
  editMode: IEditMode;
  open: boolean;
  editId: string;
  deleteId: string;
}
export interface IEnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ISortableData) => void;
  order: IOrder;
  orderBy: string;
  columns: ColumnsData[];
  viewFields: ColumnsData[];
  tableType: ITableType;
}
export interface ITableState {
  page: number;
  year: string;
  industrialFactorYear: number;
  rowsPerPage: number;
  order: IOrder;
  orderBy: string;
  columnData: IObjectColumn;
  isDataNotFound: boolean;
  alertOpen: boolean;
}

export enum IEditMode {
  ADD,
  EDIT,
}
export interface IReactTableProps {
  columns: ITableColumns[];
}
export interface IColumn {
  id: string;
  label?: string;
  minWidth?: number;
  align?: "right";
  // format?: (value: number) => string;
  sortable?: boolean;
  type?: string;
  width?: ExcelWidth;
  visible?: boolean;
  hidden?: boolean;
}

export interface IObjectColumn {
  total?: number;
}

export interface IResearch {
  id?: string;
  ListCo: string;
  StockCode: string;
  Target: string;
  TargetSector: string;
  TargetIndustry: string;
  TargetBusiness: string;
  Location: string;
  CircularDate: string;
  TransactionType: string;
  ValuationReport: string;
  Valuer: string;
  ValuationApproach1: string;
  ValuationApproach2: string;
  ComparableCompany: string;
  WACC: string;
  CompanySpecificRiskPremium: string;
  LoMD1: string;
  LoMD1Source: string;
  LoMD2: string;
  LoMD2Source: string;
  LoCD: string;
  LoCDSource: string;
  ControlPremium: string;
  ControlPremiumSource: string;
  Adoptedstatisticaltype: string;
  MarketApproachMultiple1: string;
  MarketApproachMultipleResult1: string;
  MarketApproachMultiple2: string;
  MarketApproachMultipleResult2: string;
  MarketApproachMultiple3: string;
  MarketApproachMultipleResult3: string;
  MarketApproachMultiple4: string;
  MarketApproachMultipleResult4: string;
  SelectedComparableCompaniesStockCode: string;
  FinancialAdvisor: string;
  IndependentFinancialAdvisor: string;
  Remark: string;
  Link: string;
}

export interface ISortableData {
  id?: string;
  ListCo: number;
  StockCode: number;
  Location: number;
  CircularDate: number;
  TransactionType: number;
  Valuer: number;
  ValuationApproach1: number;
  ValuationApproach2: number;
  ComparableCompany: number;
  WACC: number;
  LoMD1: number;
  LoMD2: number;
  LoCD: number;
  ControlPremium: number;
  MarketApproachMultiple1: number;
  MarketApproachMultiple2: number;
  MarketApproachMultiple3: number;
  FinancialAdvisor: number;
  IndependentFinancialAdvisor: number;
}

export enum ITableType {
  CIRCULAR_RESEARCH,
  ROYALTY_RATE,
  COMPARABLE_PPA,
  PROPRIETARY_PPA,
  // MARCO_ECON_FACTOR,
  // INDUSTRIAL_FACTOR,
  // RECOVERY_RATES,
  // EFFECTIVE_INTEREST_RATE,
  BLOOMBERG_INDEXES_LENDING_RATE,
  BLOOMBERG_INDEXES_RISK_FREE_RATE,
  BLOOMBERG_CPI,
  BLOOMBERG_CPI_AVERAGE,
  BLOOMBERG_EXCHANGE_RATE,
  BLOOMBERG_BOND_YIELD,
  RESEARCH_REPORT,
}

export interface ITableColumns {
  key: ITableType;
  name: string;
  data: ColumnsData[];
}

export interface IDataSetState {
  columnData: Object[];
  isDataNotFound: boolean;
}

export interface IKeywordState {
  keyword: string;
  tmpKeyword: string;
}

export interface IExportState {
  open: boolean;
  loading: boolean;
  url: string;
  error: string;
}

export interface IImportState {
  open: boolean;
  tableType: Partial<ITableType>;
}

export interface ISnackbar {
  open: boolean;
  message: string;
  type: AlertColor;
}

export type IOrder = "asc" | "desc";

export interface IReactTableState {
  table: Partial<ITableState>;
  form: Partial<IFormState>;
  search: Partial<ISearchState>;
  export: Partial<IExportState>;
  snackbar: Partial<ISnackbar>;
  import: Partial<IImportState>;
}

export interface IComparableCompaniesRow {
  company: string;
  code: string;
  parentId: string;
  id?: string;
}

export interface IIntangibleAssetsRow {
  type: string;
  amount: number;
  rul: string;
  parentId: string;
  id?: string;
}

export interface IComparableCompaniesTableData extends ComparableCompanyUpdateOneInput {
  tableData?: IComparableCompaniesTableContent;
}

export interface IIntangibleAssetsTableData extends IntangibleAssetUpdateOneInput {
  tableData?: IIntangibleAssetsTableContent;
}

export interface IComparableCompaniesTableContent {
  editing: boolean | undefined;
  id: number;
}

export interface IIntangibleAssetsTableContent {
  editing: boolean | undefined;
  id: number;
}
