import { ColumnsData } from "../../generated/graphql";

export const bloombergBondYieldColumns: ColumnsData[] = [
  {
    id: "id",
    label: "Id",
    hidden: true,
  },
  { id: "valuationDate", label: "Valuation Date (YYYY-MM-DD)", type: "date", sortable: true, minWidth: 180 },
  { id: "issuerName", label: "Issuer Name", minWidth: 200, sortable: true },
  { id: "spIssuerRating", label: "S&P Issuer Rating", minWidth: 200, sortable: true },
  { id: "yieldToMaturityMid", label: "Yield to Maturity (Mid)", sortable: true },
  { id: "currency", label: "Currency", sortable: true },
  { id: "cpn", label: "Cpn", sortable: true },
  { id: "ticker", label: "Ticker", minWidth: 100, sortable: true },
  { id: "mtyType", label: "Mty Type", minWidth: 100, sortable: true },
  { id: "maturity", label: "Maturity (MM/DD/YYYY)", sortable: true },
  { id: "timeToMaturity", label: "Time to Maturity (Years)", type: "number", sortable: true },
  { id: "isin", label: "ISIN", sortable: true },
  { id: "series", label: "Series", sortable: true },
  { id: "bbg", label: "BBG Composite", sortable: true },
  { id: "biscLevel2", label: "BICS Level2", sortable: true },
  { id: "issueDate", label: "Issue Date (MM/DD/YYYY)", sortable: true },
];
