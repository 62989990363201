import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from "@mui/material/CircularProgress";
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  progressBlockWrap: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 2200
  },
  progressBlock: {
    transform: "translate(-50%,-50%)",
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 10
  }
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <div className={classes.progressBlockWrap}>
      <div className={classes.progressBlock}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default Loading;
