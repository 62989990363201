import React from "react";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MuiDialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ColumnsData } from "../generated/graphql";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    // margin: "0 40px",
    width: "100%",
    position: "relative",
  },
  clearBtn: {
    position: "absolute",
    fontSize: "10px",
    padding: "2px 8px",
    minWidth: "auto",
    right: "25px",
    top: "18px",
  },
  selectInput: {
    "& .MuiSelect-selectMenu": {
      paddingRight: "85px",
    },
  },
  button: {
    "& span": {
      fontSize: "12px",
      fontWeight: "700",
      color: "#555",
    },
  },
  error: {
    color: "red",
  },
  inputLabel: {
    fontSize: "14px",
  },
  icon: {
    borderRadius: 3,
    width: 18,
    height: 18,
    boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#3f51b5",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#3f51b5",
    },
  },
  menuItem: {
    padding: "2px 15px",
  },
  selectDeselectBtn: {
    padding: "4px",
    fontSize: "12px",
    marginRight: "10px",
  },
  btnContent: {
    padding: "5px 16px !important",
  },
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
          style={{ float: "right", position: "absolute" }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const View = ({ handleSave, value, fields }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [errorVisible, setErrorVisible] = React.useState(false);
  const [view, setView] = React.useState<ColumnsData[]>([]);

  React.useEffect(() => {
    setView(value);
  }, [setView, value]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleChange = React.useCallback(
    (item: ColumnsData) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (view) {
        const newView = view.map((item) => {
          if (item.id === event.target.value) {
            return {
              id: item.id,
              visible: typeof item.visible === "undefined" ? false : !item.visible,
              label: item.label,
            };
          } else {
            return {
              id: item.id,
              visible: item.visible,
              label: item.label,
            };
          }
        });
        setView(newView);
      }
    },
    [setView, view]
  );

  const renderList = React.useMemo(() => {
    if (view) {
      return fields.map((fieldsItem, fieldsIndex) => {
        if (fieldsItem.id !== "id") {
          const isChecked =
            view.length <= 0 ||
            typeof view[fieldsIndex] === "undefined" ||
            typeof view[fieldsIndex].visible === "undefined"
              ? true
              : view[fieldsIndex].visible;
          return (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={isChecked ? isChecked : false}
                  onChange={handleChange(fieldsItem)}
                  value={fieldsItem.id}
                  color="primary"
                  checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
                  icon={<span className={classes.icon} />}
                />
              }
              label={fieldsItem.label}
              key={`form${fieldsItem.id}`}
            />
          );
          // } else {
          //   return null;
          // }
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
    // } else {
    //   return null;
    // }
  }, [handleChange, fields, view, classes]);

  const handlePreSave = React.useCallback(() => {
    // only filter id and visible
    const filteredView = view.map((item) => {
      return {
        id: item.id,
        visible: item.visible,
      };
    });
    const hasVisible = filteredView.some((item) => item.visible === true || typeof item.visible === "undefined");
    if (!hasVisible) {
      setErrorVisible(true);
    } else {
      setErrorVisible(false);
      handleClose();
      handleSave(filteredView);
    }
  }, [handleClose, handleSave, view, setErrorVisible]);

  return (
    <>
      <Button onClick={handleClickOpen} className={classes.button}>
        <FilterListIcon style={{ color: "#3f51b5" }} />
        <span>Show/Hide Columns</span>
      </Button>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          SHOW / HIDE COLUMNS
        </DialogTitle>
        {errorVisible && <DialogContent className={classes.error}>Please select at least one column.</DialogContent>}
        {/* <DialogContent className={classes.btnContent}>
          <Button color="primary" className={classes.selectDeselectBtn} onClick={handleSelectAll}>
            SELECT ALL
          </Button>
          <Button color="primary" className={classes.selectDeselectBtn} onClick={handleDeselectAll}>
            DESELECT ALL
          </Button>
        </DialogContent> */}
        <DialogContent dividers>{renderList}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handlePreSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { View, useStyles };
