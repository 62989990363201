import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from '@mui/styles/makeStyles';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { columns } from "../columns";
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "200px",
    marginRight: "40px",
  },
  select: {
    // fontSize: "14px"
  },
}));

const TableSelect = ({ onChange, value }) => {
  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label" variant="standard">Table</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          value={value}
          id="demo-simple-select"
          onChange={onChange}
          className={classes.select}
          variant="standard"
        >
          {columns.map((item) => (
            <MenuItem key={item.key} value={item.key}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default TableSelect;
