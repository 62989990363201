import { ColumnsData } from "../generated/graphql";
export const proprietaryPPA: ColumnsData[] = [
  {
    id: "id",
    label: "Id",
    hidden: true,
  },
  {
    id: "AcquisitionDate",
    label: "Acquisition date",
    minWidth: 100,
    sortable: true,
    type: "date",
  },
  // {
  //   id: "TargetSector",
  //   label: "Target's Sector",
  //   minWidth: 100,
  //   sortable: true,
  // },
  // {
  //   id: "TargetIndustry",
  //   label: "Target's Industry",
  //   minWidth: 100,
  //   sortable: true,
  // },
  {
    id: "Industry",
    label: "Industry",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "TargetName",
    label: "Target's Name",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "BusinessDescription",
    label: "Business Description",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "TargetLocation",
    label: "Target's Location",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "Currency",
    label: "Currency",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "Link",
    label: "Link",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "Acquirer",
    label: "Acquirer",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "AcquirerStockCode",
    label: "Acquirer's Stock code",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "IntangibleAssets",
    label: "Intangible assets",
    minWidth: 100,
    sortable: true,
    type: "number",
  },
  {
    id: "IntangibleAssetsList",
    label: "Intangible Assets List (Type - Amount - RUL)",
    minWidth: 300,
  },
  {
    id: "Goodwill",
    label: "Goodwill (100%)",
    minWidth: 100,
    sortable: true,
    type: "number",
  },
  {
    id: "IAIAGW",
    label: "% of IA / (IA + GW)",
    minWidth: 100,
    sortable: true,
    type: "percentage",
  },
  {
    id: "GWIAGW",
    label: "% of GW / (IA + GW)",
    minWidth: 100,
    sortable: true,
    type: "percentage",
  },
  {
    id: "Consideration",
    label: "Purchase Consideration",
    minWidth: 100,
    sortable: true,
    type: "number",
  },
  {
    id: "Sought",
    label: "% of Equity Interest Acquired",
    minWidth: 100,
    sortable: true,
    type: "percentage",
  },
  {
    id: "Consideration100",
    label: "Implied 100% Consideration",
    minWidth: 100,
    sortable: true,
    type: "number",
  },
  {
    id: "Valuer",
    label: "Valuer",
    minWidth: 100,
  },
  {
    id: "ManagerInCharge",
    label: "Manager-in-charge",
    minWidth: 100,
  },
  {
    id: "RoyaltyRate",
    label: "Royalty Rate(%)",
    minWidth: 100,
    type: "percentage",
  },
  {
    id: "ComparableCompanies",
    label: "Comparable Companies",
    minWidth: 400,
  },
  {
    id: "WACC",
    label: "WACC(%)",
    minWidth: 100,
    type: "percentage",
  },
  {
    id: "CompanySpecificRisk",
    label: "Company Specific Risk(%)",
    minWidth: 100,
    type: "percentage",
  },
  {
    id: "EnterpriseValue100",
    label: "100% Enterprise Value by DCF Method",
    minWidth: 100,
    type: "number",
  },
  {
    id: "EquityValue100",
    label: "100% Equity Value by DCF Method",
    minWidth: 100,
    sortable: true,
    type: "number",
  },
  {
    id: "LatestFullYearNetProfit",
    label: "Latest Full Year Net Profit",
    minWidth: 100,
    type: "number",
  },
  {
    id: "LatestFullYearEBITDA",
    label: "Latest Full Year EBITDA",
    minWidth: 100,
    type: "number",
  },
  {
    id: "LatestFullYearSales",
    label: "Latest Full Year Sales",
    minWidth: 100,
    type: "number",
  },
  {
    id: "PE",
    label: "P/E",
    minWidth: 100,
    sortable: true,
    type: "number",
  },
  {
    id: "EVEBITDA",
    label: "EV/EBITDA",
    minWidth: 100,
    sortable: true,
    type: "number",
  },
  {
    id: "EVS",
    label: "EV/S",
    minWidth: 100,
    sortable: true,
    type: "number",
  },
  {
    id: "Remark",
    label: "Remark",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "InCharge",
    label: "In Charge ",
    minWidth: 120,
    sortable: true,
  },
  {
    id: "CheckingBy",
    label: "Checking By",
    minWidth: 150,
    sortable: true,
  },
];
