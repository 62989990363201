import React from "react";
import ShowMore from "react-show-more-button";

const CellShowMore = ({ children }) => {
  return (
    <ShowMore
      maxHeight={120}
      backgroundColor="none"
      styleButton={{
        color: "black",
        border: "none",
        width: "100%",
        background: "rgba(255,255,255,0.9)",
        borderRadius: 0,
        padding: 0,
        height: "20px",
      }}
      styleButtonDiv={{
        padding: "0px",
      }}
    >
      {children}
    </ShowMore>
  );
};

export { CellShowMore };
