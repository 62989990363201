import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AlertDialog = ({ handleDeleteItem, openFromParent, handleAlertClose }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(openFromParent);
  }, [openFromParent]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleAlertClose(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Confirm to delete item ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleAlertClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDeleteItem(true)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>{" "}
    </>
  );
};

export default AlertDialog;
