import { ColumnsData } from "../generated/graphql";
export const researchReport: ColumnsData[] = [
  {
    id: "id",
    label: "Id",
    hidden: true,
  },
  {
    id: "companyName",
    label: "Company Name",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "files",
    label: "files",
    type: "array",
    minWidth: 150,
    sortable: false,
  },
  {
    id: "description",
    label: "Description",
    minWidth: 300,
    sortable: true,
  },
  {
    id: "industries",
    label: "Industries",
    type: "industries",
    minWidth: 200,
    sortable: false,
  },
  {
    id: "keywords",
    label: "Keywords",
    type: "array",
    minWidth: 200,
    sortable: false,
  },
  {
    id: "comparableCompanies",
    label: "comparableCompanies",
    type: "listedCompanies",
    minWidth: 100,
    sortable: false,
  },
  {
    id: "updatedAt",
    label: "Last Update Date",
    type: "date",
    minWidth: 100,
    sortable: true,
  },
];
