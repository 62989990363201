import { ITableType } from "../../utils/interface";

export const shouldFieldDisabled = (tableType: ITableType, columnId: string) => {
  if (
    tableType === ITableType.COMPARABLE_PPA &&
    (columnId === "IAIAGW" ||
      columnId === "GWIAGW" ||
      columnId === "PurchaseConsiderationFull" ||
      columnId === "IntangibleAssets")
  ) {
    return true;
  }

  if (
    tableType === ITableType.PROPRIETARY_PPA &&
    (columnId === "IAIAGW" ||
      columnId === "GWIAGW" ||
      columnId === "Consideration100" ||
      columnId === "EVEBITDA" ||
      columnId === "EVS" ||
      columnId === "IntangibleAssets" ||
      columnId === "PE")
  ) {
    return true;
  }
  return false;
};
