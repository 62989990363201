import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import notFoundImg from "../static/images/not-found.png";
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  notFound: {
    fontSize: "14px",
    textAlign: "center",
    margin: "150px auto",
    "& *": {},
  },
}));

const DataNotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.notFound}>
      <img src={notFoundImg} alt="" />

      <p>No Result Found</p>
    </div>
  );
};

export default DataNotFound;
