import { useState } from "react";

import Alert, { AlertColor } from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { useCreateOneIssueMutation } from "generated/graphql";
import * as yup from "yup";

const ReportForm = ({ open, handleClose }) => {
  const [createIssue] = useCreateOneIssueMutation();
  const initialValues = { title: "", description: "" };
  const [snackbarState, setSnackbarState] = useState<{ open: boolean; message: string; type: AlertColor }>({
    open: false,
    message: "",
    type: "success",
  });

  const submitReportForm = async (values: { title: string; description: string }) => {
    const result = await createIssue({
      variables: values,
    }).catch((error) => {
      console.log(error);
    });

    if (result && result.data?.createOneIssue?.id) {
      formik.resetForm();
      handleClose();
      setSnackbarState({
        open: true,
        message: "Issue created",
        type: "success",
      });
    } else {
      setSnackbarState({
        open: true,
        message: "Failed to create issue",
        type: "error",
      });
    }
  };

  const validationSchema = yup.object({
    title: yup.string().required("Title is required"),
    description: yup.string(),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: submitReportForm,
    validationSchema,
  });

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  return (
    <div>
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleSnackbarClose}
      >
        <Alert severity={snackbarState.type} onClose={handleSnackbarClose}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Report issue</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              id="title"
              name="title"
              label="Issue Title"
              type="text"
              fullWidth
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              variant="standard"
            />
            <TextField
              id="description"
              name="description"
              label="Issue description"
              type="text"
              fullWidth
              multiline
              minRows="3"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={formik.isSubmitting}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export { ReportForm };
