import React from "react";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { IReactTableState } from "utils/interface";
import { IActions } from "./ReactTable/reducer/interface";

const ReactTableSnackbar = ({ state, dispatch }: { state: IReactTableState; dispatch: React.Dispatch<IActions> }) => {
  const handleSnackbarClose = () => {
    dispatch({
      type: "setSnackbar",
      payload: {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      },
    });
  };
  return (
    <Snackbar
      open={state.snackbar.open}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleSnackbarClose}
    >
      <Alert severity={state.snackbar.type} onClose={handleSnackbarClose}>
        {state.snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export { ReactTableSnackbar };
