import { IReactTableState } from "../../../utils/interface";
import { initState } from "./init-state";
import { IActions } from "./interface";

export const reducer = (state: IReactTableState, action: IActions): IReactTableState => {
  switch (action.type) {
    case "setColumnData":
      return {
        ...state,
        table: {
          ...state.table,
          columnData: action.payload.table.columnData,
          isDataNotFound: false,
        },
      };
    case "noColumnData":
      return {
        ...state,
        table: {
          ...state.table,
          isDataNotFound: true,
        },
      };
    case "handleTabChange":
      return {
        ...state,
        table: {
          ...state.table,
          year: action.payload.table.year,
        },
        search: {
          ...state.search,
          keyword: action.payload.search.keyword,
        },
      };
    case "handleIndustrialFactorTabChange":
      return {
        ...state,
        table: {
          ...state.table,
          industrialFactorYear: action.payload.table.industrialFactorYear,
        },
        search: {
          ...state.search,
          keyword: "",
        },
      };
    case "handleSearch":
      return {
        ...state,
        table: {
          ...state.table,
          page: 0,
        },
        search: {
          ...state.search,
          selectedSearchField: action.payload.search.selectedSearchField,
          keyword: action.payload.search.keyword,
        },
      };
    case "keywordChange":
      return {
        ...state,
        search: {
          ...state.search,
          tmpKeyword: action.payload.search.tmpKeyword,
        },
      };
    case "setDeleteId":
      return {
        ...state,
        form: {
          ...state.form,
          deleteId: action.payload.form.deleteId,
        },
        table: {
          ...state.table,
          alertOpen: true,
        },
      };
    case "closeAlertBox":
      return {
        ...state,
        table: {
          ...state.table,
          alertOpen: false,
        },
      };
    case "closeForm":
      return {
        ...state,
        form: {
          ...state.form,
          open: false,
        },
      };
    case "editRow":
      return {
        ...state,
        form: {
          ...state.form,
          open: true,
          data: action.payload.form.data,
          editMode: action.payload.form.editMode,
          editId: action.payload.form.editId,
        },
      };
    case "importFile":
      return {
        ...state,
        import: {
          open: true,
          tableType: action.payload.import.tableType,
        },
      };
    case "setProprietaryFormId":
      return {
        ...state,
        form: {
          ...state.form,
          editId: action.payload.form.editId,
        },
      };
    case "sort":
      return {
        ...state,
        table: {
          ...state.table,
          order: action.payload.table.order,
          orderBy: action.payload.table.orderBy,
        },
      };
    case "sortToPage1":
      return {
        ...state,
        table: {
          ...state.table,
          order: action.payload.table.order,
          orderBy: action.payload.table.orderBy,
          page: 0,
        },
      };
    case "changePage":
      return {
        ...state,
        table: {
          ...state.table,
          page: action.payload.table.page,
        },
      };
    case "changeRowsPerPage":
      return {
        ...state,
        table: {
          ...state.table,
          rowsPerPage: action.payload.table.rowsPerPage,
        },
      };
    case "handleTableSelectChange":
      return {
        ...state,
        table: {
          ...state.table,
          page: 0,
          rowsPerPage: action.payload.table.rowsPerPage ?? state.table.rowsPerPage,
        },
        search: {
          ...state.search,
          keyword: "",
          tmpSelectedSearchField: [],
          selectedSearchField: [],
          extraFilter: { ...initState.search.extraFilter, ...action.payload.search?.extraFilter },
        },
      };
    case "handleFieldSelectChange":
      return {
        ...state,
        search: {
          ...state.search,
          tmpSelectedSearchField: action.payload.search.tmpSelectedSearchField,
        },
      };
    case "handleSearchFieldsClear":
      return {
        ...state,
        search: {
          ...state.search,
          tmpSelectedSearchField: [],
        },
      };
    case "setSearchFields":
      return {
        ...state,
        search: {
          ...state.search,
          allSearchFields: action.payload.search.allSearchFields,
        },
      };
    case "setExtraFilter":
      return {
        ...state,
        search: {
          ...state.search,
          extraFilter: action.payload.search.extraFilter,
        },
      };
    case "initColumnValue":
      return {
        ...state,
        form: {
          ...state.form,
          initValues: action.payload.form.initValues,
        },
      };
    case "startExport":
      return {
        ...state,
        export: {
          open: true,
          loading: false,
          url: "",
          error: "",
        },
      };
    case "updateExport":
      return {
        ...state,
        export: {
          ...action.payload.export,
          open: true,
        },
      };
    case "closeExport":
      return {
        ...state,
        export: {
          open: false,
          loading: false,
          url: "",
          error: "",
        },
      };
    case "closeImport":
      return {
        ...state,
        import: {
          open: false,
          tableType: null,
        },
      };
    case "setSnackbar":
      return {
        ...state,
        snackbar: action.payload.snackbar,
      };
    default:
      throw new Error();
  }
};
