import { ColumnsData } from "../generated/graphql";
export const royaltyRate: ColumnsData[] = [
  {
    id: "id",
    label: "Id",
    hidden: true,
  },
  {
    id: "JobCode",
    label: "JobCode",
    minWidth: 120,
    sortable: true,
  },
  // {
  //   id: "TargetsSector",
  //   label: "Target's Sector",
  //   minWidth: 120,
  //   sortable: true,
  // },
  // {
  //   id: "TargetsIndustry",
  //   label: "Target's Industry",
  //   minWidth: 120,
  //   sortable: true,
  // },
  {
    id: "TargetsName",
    label: "Target's Name",
    minWidth: 120,
    sortable: true,
  },
  {
    id: "TargetsBusinessDescription",
    label: "Target's Business Description",
    minWidth: 300,
    sortable: true,
  },
  {
    id: "IntangibleAssetType",
    label: "Intangible Asset Type",
    sortable: true,
    minWidth: 150,
  },
  {
    id: "Industry",
    label: "Industry",
    minWidth: 250,
    sortable: true,
  },
  {
    id: "DataSource",
    label: "Data Source",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "Licensee",
    label: "Licensee",
    sortable: true,
    minWidth: 150,
  },
  {
    id: "Licensor",
    label: "Licensor",
    minWidth: 300,
    sortable: true,
  },
  {
    id: "RoyaltyRateLowRange",
    label: "Royalty Rate, % (low range)",
    type: "percentage",
    sortable: true,
    minWidth: 150,
  },
  {
    id: "RoyaltyRateHighRange",
    label: "Royalty Rate, % (high range)",
    type: "percentage",
    sortable: true,
    minWidth: 200,
  },
  {
    id: "LicensedPropertyDescription",
    label: "Licensed Property/ Description",
    minWidth: 500,
  },
  {
    id: "CompensationDetail",
    label: "Compensation Detail",
    minWidth: 500,
  },
  {
    id: "Source",
    label: "Source",
    minWidth: 80,
  },
  {
    id: "Year",
    label: "Year",
    sortable: true,
    minWidth: 80,
  },
];
