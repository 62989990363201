import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  reactTableWrap: {
    margin: "30px 0 20px 0",
  },
  container: {
    padding: "0 15px",
    maxWidth: "100% !important",
  },
  tableContainer: {
    height: "70vh",
    "& thead": {
      "& tr": {
        "& th": {
          "&:first-child": {
            position: "sticky",
            left: 0,
            zIndex: 3,
          },
          "&:nth-child(2)": {
            position: "sticky",
            // left: "90px",
            zIndex: 3,
          },
        },
      },
    },
    "& tbody": {
      "& tr": {
        "& td": {
          "&:first-child": {
            position: "sticky",
            left: 0,
            background: "#fff",
          },
          "&:nth-child(2)": {
            position: "sticky",
            // left: "90px",
            background: "#fff",
          },
        },
      },
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },

  wrap: {
    marginTop: "10px",
  },
  loader: {
    textAlign: "center",
    padding: "80px 0",
  },
  tableCell: {
    fontSize: "12px",
  },
  tableHeadCell: {
    fontSize: "12px",
    fontWeight: 700,
  },
  lastUpdate: {
    fontSize: "12px",
    lineHeight: "36px",
    marginLeft: "100px",
    color: "#999",
  },
  rightWrap: {
    position: "relative",
  },

  showmore: {
    color: "#888",
  },
  topMenu: {
    display: "inline-block",
    alignItems: "flex-end",
    padding: "12px 12px 0 12px",
    width: "100%",
  },
  menuItem: {
    minWidth: "auto",
    marginRight: "10px",
    display: "inline-block",
    verticalAlign: "middle",
    "& span": {
      fontSize: "12px",
      fontWeight: "700",
      color: "#555",
    },
  },
  editIcon: {
    fontSize: "18px",
    marginRight: "8px",
    cursor: "pointer",
  },
  deleteIcon: {
    fontSize: "18px",
    cursor: "pointer",
  },
  downloadIcon: {
    fontSize: "18px",
    cursor: "pointer",
  },
  tableCellDownloadIcon: {
    fontSize: "18px",
    cursor: "pointer",
    marginLeft: "10px",
    verticalAlign: "middle",
  },
  addItemContainer: {
    float: "right",
    paddingTop: "20px",
    "& span": {
      color: "#FFFFFF",
    },
  },
  dataContentWrap: {
    position: "relative",
  },
  formContent: {
    // paddingBottom: "70px!important",
  },
  comparableCompanies: {
    padding: 0,
    margin: 0,
    listStyle: "none",
    maxHeight: "150px",
    overflow: "auto",
    fontSize: "13px",
    "& li": {
      marginBottom: "6px",
    },
  },
  exportDialogContent: {
    textAlign: "center",
    marginBottom: "20px",
  },
  chip: {
    margin: "1px",
  },
}));
