import { ITableColumns, ITableType } from "../utils/interface";
import { bloombergBondYieldColumns } from "./bloomberg/bond-yield";
import { bloombergCpiColumns } from "./bloomberg/cpi";
import { bloombergCpiAverageColumns } from "./bloomberg/cpi-average";
import { bloombergExchangeRateColumns } from "./bloomberg/exchange-rate";
import { bloombergIndexLendingRateColumns } from "./bloomberg/index-lending-rate";
import { bloombergIndexRiskFreeRateColumns } from "./bloomberg/index-risk-free-rate";
import { circularResearch } from "./circularResearch";
import { comparablePPA } from "./comparablePPA";
import { proprietaryPPA } from "./proprietaryPPA";
import { researchReport } from "./researchReport";
import { royaltyRate } from "./royaltyRate";

export const columns: ITableColumns[] = [
  {
    key: ITableType.CIRCULAR_RESEARCH,
    name: "Circular Research",
    data: [...circularResearch],
  },
  {
    key: ITableType.ROYALTY_RATE,
    name: "Royalty Rate",
    data: [...royaltyRate],
  },
  {
    key: ITableType.COMPARABLE_PPA,
    name: "Comparable PPA",
    data: [...comparablePPA],
  },
  {
    key: ITableType.PROPRIETARY_PPA,
    name: "Proprietary PPA",
    data: [...proprietaryPPA],
  },
  // {
  //   key: ITableType.MARCO_ECON_FACTOR,
  //   name: "Marco Econ Factor",
  //   data: marcoEconFactor,
  // },
  // {
  //   key: ITableType.INDUSTRIAL_FACTOR,
  //   name: "Industrial Factor",
  //   data: industrialFactor,
  // },
  // {
  //   key: ITableType.RECOVERY_RATES,
  //   name: "Recovery Rate",
  //   data: recoveryRate,
  // },
  // {
  //   key: ITableType.EFFECTIVE_INTEREST_RATE,
  //   name: "Effective Interest Rate",
  //   data: effectiveInterestRate,
  // },
  {
    key: ITableType.BLOOMBERG_INDEXES_LENDING_RATE,
    name: "Bloomberg - Lending Rate",
    data: bloombergIndexLendingRateColumns,
  },
  {
    key: ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE,
    name: "Bloomberg - Risk Free Rate",
    data: bloombergIndexRiskFreeRateColumns,
  },
  {
    key: ITableType.BLOOMBERG_CPI,
    name: "Bloomberg - CPI",
    data: bloombergCpiColumns,
  },
  {
    key: ITableType.BLOOMBERG_CPI_AVERAGE,
    name: "Bloomberg - CPI Average",
    data: bloombergCpiAverageColumns,
  },
  {
    key: ITableType.BLOOMBERG_EXCHANGE_RATE,
    name: "Bloomberg - Exchange Rate",
    data: bloombergExchangeRateColumns,
  },
  {
    key: ITableType.BLOOMBERG_BOND_YIELD,
    name: "Bloomberg - Bond Yield Rate",
    data: bloombergBondYieldColumns,
  },
  {
    key: ITableType.RESEARCH_REPORT,
    name: "Research Report",
    data: researchReport,
  },
];
