import React, { FunctionComponent } from "react";

import SortIcon from "@mui/icons-material/Sort";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { isHideFirstColumn } from "utils/helper";
import { IEnhancedTableProps } from "../../utils/interface";

const useStyles = makeStyles((theme: Theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  stickyHeader: {
    lineHeight: "inherit",
  },
}));

const EnhancedTableHead: FunctionComponent<IEnhancedTableProps> = ({
  order,
  orderBy,
  onRequestSort,
  columns,
  viewFields,
  tableType,
}) => {
  const createSortHandler = (property) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {!isHideFirstColumn(tableType) && <TableCell key={"edit"} style={{ minWidth: "50px" }}></TableCell>}
        {columns.map((column, index) => {
          if (
            (viewFields && viewFields.length > 0 && viewFields[index].visible !== false) ||
            typeof viewFields === "undefined"
          ) {
            if (!column.hidden) {
              return (
                <TableCell
                  key={column.id}
                  // align={column.numeric ? 'right' : 'left'}
                  // padding={column.disablePadding ? 'none' : 'default'}
                  style={{ minWidth: column.minWidth ? column.minWidth : "auto" }}
                  sortDirection={orderBy === column.id ? order : false}
                  className={classes.stickyHeader}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={createSortHandler(column.id)}
                    >
                      <SortIcon style={{ fontSize: 18 }} />
                      {column.label}
                      {orderBy === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc" ? "sorted descending" : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <>{column.label}</>
                  )}
                </TableCell>
              );
            } else {
              return null;
            }
          } else {
            return null;
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
