import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    margin: "5px 20px 0 0px",
    backgroundColor: "#f6f6f6",
    color: "#000",
  },
  tab: {
    backgroundColor: "#fafafa",
    fontWeight: "bold",
    "&.Mui-selected": {
      background: "#fff",
    },
  },
}));

const getYearsFrom2015 = (): string[] => {
  const currentYear = new Date().getFullYear();
  return [...new Array(currentYear - 2014).keys()].map((y) => (currentYear - y).toString());
};

const HeaderTabs = ({ handleTabChange, value }) => {
  const classes = useStyles();
  const years: string[] = React.useMemo(getYearsFrom2015, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    handleTabChange(newValue);
  };

  return (
    <Tabs value={value} onChange={handleChange} className={classes.tabs} textColor="primary" indicatorColor="primary">
      {years.map((year) => (
        <Tab label={year} value={year} className={classes.tab} key={year} />
      ))}
    </Tabs>
  );
};

export default HeaderTabs;
