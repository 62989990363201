import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import { useStyles } from "./View";

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FieldSelect = ({ onChange, fields, value, clear }) => {
  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label" className={classes.inputLabel} variant="standard">
          Columns
        </InputLabel>
        <Select
          className={classes.selectInput}
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={value}
          onChange={onChange}
          input={<Input />}
          renderValue={(selected) => (selected as string[]).join(", ")}
          MenuProps={MenuProps}
          variant="standard"
        >
          {fields.map((item) => {
            if (item.id !== "id") {
              return (
                <MenuItem key={item.id} value={item.id} className={classes.menuItem}>
                  <Checkbox
                    checked={value.indexOf(item.id) > -1}
                    checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
                    icon={<span className={classes.icon} />}
                  />
                  <ListItemText primary={item.label} />
                </MenuItem>
              );
            } else {
              return null;
            }
          })}
        </Select>
        {value.length > 0 && (
          <Button onClick={clear} variant="contained" color="primary" size="small" className={classes.clearBtn}>
            Clear
          </Button>
        )}
      </FormControl>
    </>
  );
};

export default FieldSelect;
