import { Autocomplete, Chip, InputLabel } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useFormik } from "formik";
import { clone } from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { Accept } from "react-dropzone";
import { SERVER } from "utils/const";
import { getResearchReportFileUrl, nullToEmpty, roundUpNumber, roundUpPercentage } from "../../utils/helper";
import { IEditMode, ITableType } from "../../utils/interface";
import Companies from "./comparable-companies";
import { FileDropzone } from "./dropzone";
import { IndustryListOptions, targetIndustryOptions } from "./industry-options";
import IntangibleAssets from "./intangible-assets";
import { KeywordsField } from "./keywords-field";
import { CompaniesField } from "./listed-companies";
import { MultiSelectField } from "./multi-select-field";
import { shouldFieldDisabled } from "./utils";

export const useStyles = makeStyles((theme: Theme) => ({
  numberField: {
    "& .Mui-disabled": {
      color: "#999",
    },
  },
  chip: {
    margin: "1px",
  },
}));

const Form = ({
  tableType,
  year,
  form,
  columns,
  formRef,
  handleFormClose,
  researchRefetchQueries,
  comparableRefefetchQueries,
  royaltyRefefetchQueries,
  proprietaryRefefetchQueries,
  researchReportRefefetchQueries,
  useCreateResearchMutation,
  useUpdateResearchMutation,
  useCreateComparableMutation,
  useUpdateComparableMutation,
  useCreateRoyaltyRateMutation,
  useUpdateRoyaltyRateMutation,
  useCreateProprietaryMutation,
  useUpdateProprietaryMutation,
  useCreateResearchReportMutation,
  useUpdateResearchReportMutation,
  updateComparableCompanies,
  updateIntangibleAssets,
  comparableCompanies,
  intangibleAssetsList,
  handleUpdateComparableCompanies,
  handleUpdateIntangibleAssetsList,
  formSubmit,
  setFormSubmit,
  setProprietaryFormId,
  setSnackbar,
}) => {
  const classes = useStyles();
  // RESEARCH
  const [createResearch] = useCreateResearchMutation({
    refetchQueries: researchRefetchQueries,
    awaitRefetchQueries: true,
  });
  const [updateResearch] = useUpdateResearchMutation({
    refetchQueries: researchRefetchQueries,
    awaitRefetchQueries: true,
  });

  // // COMPARABLE
  const [createComparable] = useCreateComparableMutation({
    refetchQueries: comparableRefefetchQueries,
    awaitRefetchQueries: true,
  });
  const [updateComparable] = useUpdateComparableMutation({
    refetchQueries: comparableRefefetchQueries,
    awaitRefetchQueries: true,
  });

  // // ROYALTY RATE
  const [createRoyaltyRate] = useCreateRoyaltyRateMutation({
    refetchQueries: royaltyRefefetchQueries,
    awaitRefetchQueries: true,
  });
  const [updateRoyaltyRate] = useUpdateRoyaltyRateMutation({
    refetchQueries: royaltyRefefetchQueries,
    awaitRefetchQueries: true,
  });

  // // PROPRIETARY
  const [createProprietary] = useCreateProprietaryMutation({
    refetchQueries: proprietaryRefefetchQueries,
    awaitRefetchQueries: true,
  });
  const [updateProprietary] = useUpdateProprietaryMutation({
    refetchQueries: proprietaryRefefetchQueries,
    awaitRefetchQueries: true,
  });

  // RESEARCH REPORT
  const [createResearchReport] = useCreateResearchReportMutation({
    refetchQueries: researchReportRefefetchQueries,
    awaitRefetchQueries: true,
  });
  const [updateResearchReport] = useUpdateResearchReportMutation({
    refetchQueries: researchReportRefefetchQueries,
    awaitRefetchQueries: true,
  });

  const [intangibleAssets, setIntangibleAssets] = React.useState<number>(0);

  const formik = useFormik({
    enableReinitialize: true,
    innerRef: formRef,
    initialValues: form.editMode === IEditMode.ADD ? form.initValues : nullToEmpty(form.data.value),
    onSubmit: async (values) => {
      const formValues = clone(values);

      // return;

      delete formValues["__typename"];
      if (form.editMode === IEditMode.ADD) {
        delete formValues["id"];
        switch (tableType) {
          case ITableType.COMPARABLE_PPA:
            createComparable({
              variables: {
                data: { ...formValues, IntangibleAssetsList: intangibleAssetsList },
              },
            });
            break;
          case ITableType.CIRCULAR_RESEARCH:
            createResearch({
              variables: {
                data: {
                  ...formValues,
                  year,
                },
              },
            });
            break;

          case ITableType.PROPRIETARY_PPA:
            await createProprietary({
              variables: {
                data: {
                  ...formValues,
                  ComparableCompanies: comparableCompanies,
                  IntangibleAssetsList: intangibleAssetsList,
                },
              },
            });
            break;
          case ITableType.ROYALTY_RATE:
            createRoyaltyRate({
              variables: {
                data: formValues,
              },
            });
            break;
          case ITableType.RESEARCH_REPORT:
            delete formValues["updatedAt"];
            if (values.comparableCompanies.length)
              formValues.comparableCompanies = values.comparableCompanies.map((c) => c.id);
            createResearchReport({
              variables: {
                data: formValues,
              },
            });
            break;
        }
      } else
        switch (tableType) {
          case ITableType.COMPARABLE_PPA:
            delete formValues["IntangibleAssetsList"];

            updateIntangibleAssets({
              variables: {
                data: intangibleAssetsList,
                id: form.data.id,
              },
            });

            updateComparable({
              variables: {
                data: { ...formValues },
                id: form.data.id,
              },
            });
            break;
          case ITableType.CIRCULAR_RESEARCH:
            updateResearch({
              variables: {
                year,
                data: formValues,
                id: form.data.id,
              },
            });

            break;
          case ITableType.PROPRIETARY_PPA:
            updateComparableCompanies({
              variables: {
                data: comparableCompanies,
                id: form.data.id,
              },
            });
            updateIntangibleAssets({
              variables: {
                data: intangibleAssetsList,
                id: form.data.id,
              },
            });
            delete formValues["ComparableCompanies"];
            delete formValues["IntangibleAssetsList"];
            updateProprietary({
              variables: {
                data: formValues,
                id: form.data.id,
              },
            });

            break;
          case ITableType.ROYALTY_RATE:
            updateRoyaltyRate({
              variables: {
                data: formValues,
                id: form.data.id,
              },
            });

            break;

          case ITableType.RESEARCH_REPORT:
            delete formValues["updatedAt"];
            if (values.comparableCompanies.length)
              formValues.comparableCompanies = formValues.comparableCompanies.map((c) => c.id);
            updateResearchReport({
              variables: {
                data: formValues,
                id: form.data.id,
              },
            }).catch((err) =>
              setSnackbar({
                message: err.message,
                type: "error",
                open: true,
              })
            );

            break;
        }

      handleFormClose();
    },
  });

  useEffect(() => {
    if (tableType !== ITableType.COMPARABLE_PPA && tableType !== ITableType.PROPRIETARY_PPA) {
      return;
    }
    formik.setFieldValue("IntangibleAssets", intangibleAssets);
  }, [intangibleAssets]);

  const formNumberOnChange = React.useCallback(
    (columnId, e) => {
      let name = e.target.id;
      let value = e.target.value;
      if (value !== "") {
        value = Number(value);
      }

      // set current field
      formik.setFieldValue(name, value);

      // automation
      if (tableType === ITableType.COMPARABLE_PPA) {
        const GoodwillFull = formik.values.GoodwillFull;
        setIntangibleAssets(intangibleAssetsList.reduce((acc, item) => acc + Number(item.amount), 0));
        const EquityinterestAcquired = formik.values.EquityinterestAcquired;
        const PurchaseConsiderationRaw = formik.values.PurchaseConsiderationRaw;

        // IAIAGW
        if (columnId === "GoodwillFull") {
          formik.setFieldValue("IAIAGW", roundUpPercentage(intangibleAssets / (intangibleAssets + value)));
        }

        // GWIAGW
        if (columnId === "GoodwillFull") {
          formik.setFieldValue("GWIAGW", roundUpPercentage(value / (intangibleAssets + value)));
        }

        // PurchaseConsiderationFull
        if (columnId === "PurchaseConsiderationRaw") {
          formik.setFieldValue("PurchaseConsiderationFull", roundUpPercentage(value / EquityinterestAcquired));
        }
        if (columnId === "EquityinterestAcquired") {
          formik.setFieldValue("PurchaseConsiderationFull", roundUpPercentage(PurchaseConsiderationRaw / value));
        }

        // IntangibleAssets

        formik.setFieldValue("IntangibleAssets", intangibleAssets);

        // IAIAGW
        formik.setFieldValue("IAIAGW", roundUpPercentage(intangibleAssets / (intangibleAssets + GoodwillFull)));
        // GWIAGW
        formik.setFieldValue("GWIAGW", roundUpPercentage(GoodwillFull / (intangibleAssets + GoodwillFull)));
      }

      if (tableType === ITableType.PROPRIETARY_PPA) {
        const Goodwill = formik.values.Goodwill;
        const IntangibleAssets = formik.values.IntangibleAssets;
        const EquityValue100 = formik.values.EquityValue100;
        const Consideration = formik.values.Consideration;
        const LatestFullYearNetProfit = formik.values.LatestFullYearNetProfit;
        const LatestFullYearEBITDA = formik.values.LatestFullYearEBITDA;
        const EnterpriseValue100 = formik.values.EnterpriseValue100;
        const LatestFullYearSales = formik.values.LatestFullYearSales;
        const Sought = formik.values.Sought;

        // IAIAGW
        // if (columnId === "IntangibleAssets") {
        //   formik.setFieldValue("IAIAGW", roundUpPercentage(value / (value + Goodwill)));
        // }
        if (columnId === "Goodwill") {
          formik.setFieldValue("IAIAGW", roundUpPercentage(IntangibleAssets / (IntangibleAssets + value)));
        }

        // GWIAGW
        // if (columnId === "IntangibleAssets") {
        //   formik.setFieldValue("GWIAGW", roundUpPercentage(Goodwill / (value + Goodwill)));
        // }
        if (columnId === "Goodwill") {
          formik.setFieldValue("GWIAGW", roundUpPercentage(value / (IntangibleAssets + value)));
        }

        // Consideration100
        if (columnId === "Consideration") {
          formik.setFieldValue("Consideration100", roundUpPercentage(value / Sought));
        }
        if (columnId === "Sought") {
          formik.setFieldValue("Consideration100", roundUpPercentage(Consideration / value));
        }

        // PE
        if (columnId === "EquityValue100") {
          formik.setFieldValue("PE", roundUpNumber(value / LatestFullYearNetProfit));
        }
        if (columnId === "LatestFullYearNetProfit") {
          formik.setFieldValue("PE", roundUpNumber(EquityValue100 / value));
        }

        // EVEBITDA
        if (columnId === "EnterpriseValue100") {
          formik.setFieldValue("EVEBITDA", roundUpNumber(value / LatestFullYearEBITDA));
        }
        if (columnId === "LatestFullYearEBITDA") {
          formik.setFieldValue("EVEBITDA", roundUpNumber(EnterpriseValue100 / value));
        }

        // EVS
        if (columnId === "EnterpriseValue100") {
          formik.setFieldValue("EVS", roundUpNumber(value / LatestFullYearSales));
        }
        if (columnId === "LatestFullYearSales") {
          formik.setFieldValue("EVS", roundUpNumber(EnterpriseValue100 / value));
        }

        // IntangibleAssets
        if (columnId === "IntangibleAssetsList") {
          setIntangibleAssets(intangibleAssetsList.reduce((acc, item) => acc + Number(item.amount), 0));

          formik.setFieldValue("IntangibleAssets", intangibleAssets);

          // IAIAGW
          formik.setFieldValue("IAIAGW", roundUpPercentage(intangibleAssets / (intangibleAssets + Goodwill)));
          // GWIAGW
          formik.setFieldValue("GWIAGW", roundUpPercentage(Goodwill / (intangibleAssets + Goodwill)));
        }
      }
    },
    [formik, intangibleAssets, intangibleAssetsList, tableType]
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {columns[tableType].data.map((column, columnIndex) => {
            if (column.hidden || column.id === "ComparableCompanies" || column.id === "IntangibleAssetsList") {
              return null;
            }

            const fieldValue = column.id in formik.values ? formik.values[column.id] : null;

            switch (column.type) {
              case "number":
              case "percentage": {
                return (
                  <Grid item xs={4} key={column.id}>
                    <TextField
                      placeholder="N/A"
                      InputProps={{
                        style: {
                          fontSize: "14px",
                        },
                      }}
                      type="number"
                      id={column.id}
                      name={column.id}
                      label={column.label}
                      fullWidth
                      className={classes.numberField}
                      size="small"
                      value={fieldValue}
                      disabled={shouldFieldDisabled(tableType, column.id)}
                      onChange={(e) => formNumberOnChange(column.id, e)}
                      variant="standard"
                    />
                  </Grid>
                );
              }
              case "date":
                if (tableType === ITableType.RESEARCH_REPORT && column.id === "updatedAt") return <></>;
                else
                  return (
                    <Grid item xs={4} key={columnIndex}>
                      <TextField
                        value={fieldValue ? moment(fieldValue, SERVER.API_DATE_FORMAT).format("yyyy-MM-DD") : ""}
                        type="date"
                        fullWidth
                        size="small"
                        id={column.id}
                        onChange={formik.handleChange}
                        label={column.label}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="standard"
                      />
                    </Grid>
                  );
              default:
                if (column.id === "TargetIndustry") {
                  return (
                    <Grid item xs={4} key={columnIndex}>
                      <Autocomplete
                        onChange={(e, value) => {
                          formik.setFieldValue(column.id, value);
                        }}
                        disablePortal
                        options={targetIndustryOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id={column.id}
                            label={column.label}
                            fullWidth
                            size="small"
                            value={fieldValue ?? ""}
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                  );
                } else if (column.id === "Industry") {
                  return (
                    <Grid item xs={4} key={columnIndex}>
                      <Autocomplete
                        onChange={(e, value) => {
                          formik.setFieldValue(column.id, value.label);
                        }}
                        disablePortal
                        options={IndustryListOptions}
                        value={fieldValue ?? ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id={column.id}
                            label={column.label}
                            fullWidth
                            size="small"
                            value={fieldValue ?? ""}
                            variant="standard"
                          />
                        )}
                      />
                    </Grid>
                  );
                } else if (tableType === ITableType.RESEARCH_REPORT) {
                  switch (column.id) {
                    case "files":
                      return (
                        <Grid item xs={12} key={columnIndex}>
                          <InputLabel>Files (Accept pdf, docx, xlsx)</InputLabel>
                          {fieldValue.length ? (
                            fieldValue
                              .filter((v) => typeof v === "string")
                              .map((v) => (
                                <Chip
                                  onDelete={() => {
                                    const newFieldValue = fieldValue.filter((file) => file !== v);
                                    formik.setFieldValue(column.id, newFieldValue);
                                  }}
                                  onClick={() => {
                                    window.open(getResearchReportFileUrl(formik.values["id"], v), "_blank");
                                  }}
                                  label={v}
                                  className={classes.chip}
                                />
                              ))
                          ) : (
                            <></>
                          )}
                          <FileDropzone
                            name="files"
                            disabled={formik.isSubmitting}
                            error={false}
                            setFieldValue={(name, value) => {
                              formik.setFieldValue(column.id, [...fieldValue, ...value]);
                            }}
                            accept={
                              {
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ["xlsx"],
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ["docx"],
                                "application/pdf": ["pdf"],
                              } as Accept
                            }
                            multiple={true}
                          />
                        </Grid>
                      );
                    case "description":
                      return (
                        <Grid item xs={12} key={columnIndex}>
                          <TextField
                            InputProps={{
                              style: {
                                fontSize: "14px",
                              },
                            }}
                            multiline
                            maxRows={8}
                            id={column.id}
                            label={column.label}
                            fullWidth
                            size="small"
                            value={fieldValue ?? ""}
                            onChange={formik.handleChange}
                            variant="standard"
                            inputProps={{ maxLength: 5120 }}
                          />
                        </Grid>
                      );
                    case "industries":
                      return (
                        <Grid item xs={12} key={columnIndex}>
                          <MultiSelectField
                            options={IndustryListOptions}
                            defaultValues={fieldValue.map((value) =>
                              IndustryListOptions.find((option) => option.value === value)
                            )}
                            formEditMode={form.editMode}
                            handleUpdate={(value) => {
                              formik.setFieldValue(column.id, value);
                            }}
                            name="industries"
                            label="Industries"
                          />
                        </Grid>
                      );
                    case "keywords":
                      return (
                        <Grid item xs={12} key={columnIndex}>
                          <KeywordsField
                            defaultValues={
                              fieldValue.length ? fieldValue.map((value) => ({ value, label: value })) : []
                            }
                            formEditMode={form.editMode}
                            handleUpdate={(value) => {
                              formik.setFieldValue(column.id, value);
                            }}
                            id={"keywords"}
                            label="Keywords"
                            description={formik.values["description"]}
                            setSnackbar={setSnackbar}
                          />
                        </Grid>
                      );
                    case "comparableCompanies":
                      return (
                        <Grid item xs={12} key={columnIndex}>
                          <CompaniesField
                            defaultValues={fieldValue}
                            formEditMode={form.editMode}
                            handleUpdate={(value) => {
                              formik.setFieldValue(column.id, value);
                            }}
                            id={"companies"}
                            label="Comparable Companies"
                          />
                        </Grid>
                      );
                  }
                }

                return (
                  <Grid item xs={4} key={columnIndex}>
                    <TextField
                      InputProps={{
                        style: {
                          fontSize: "14px",
                        },
                      }}
                      multiline
                      maxRows={8}
                      id={column.id}
                      label={column.label}
                      fullWidth
                      size="small"
                      value={fieldValue ?? ""}
                      onChange={formik.handleChange}
                      variant="standard"
                    />
                  </Grid>
                );
            }
          })}
          {tableType === ITableType.PROPRIETARY_PPA && (
            <Grid item xs={12} key={"companies"}>
              Comparable Companies
              {(form.editMode === IEditMode.ADD || comparableCompanies !== null) && (
                <Companies
                  parentId={form.data.id}
                  initList={comparableCompanies}
                  handleUpdate={handleUpdateComparableCompanies}
                  formEditMode={form.editMode}
                />
              )}
            </Grid>
          )}
          {(tableType === ITableType.PROPRIETARY_PPA || tableType === ITableType.COMPARABLE_PPA) && (
            <Grid item xs={12} key={"intangibleAssetsList"}>
              Intangible Assets
              {(form.editMode === IEditMode.ADD || intangibleAssetsList !== null) && (
                <IntangibleAssets
                  parentId={form.data.id}
                  initList={intangibleAssetsList}
                  handleUpdate={(value) => {
                    handleUpdateIntangibleAssetsList(value);
                    setIntangibleAssets(value.reduce((acc, item) => acc + Number(item.amount), 0));
                  }}
                  formEditMode={form.editMode}
                />
              )}
            </Grid>
          )}
        </Grid>
        <DialogActions style={{ position: "sticky", bottom: "-10px", background: "#fff", zIndex: 10 }}>
          <Button onClick={handleFormClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" disabled={formik.isSubmitting} color="primary">
            SAVE
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default Form;
