import { useCallback, useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import makeStyles from "@mui/styles/makeStyles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { debounce } from "lodash";
import { BloombergRiskFreeRateRegions, SERVER } from "utils/const";
import { dateStringToTimezoneDateTime, timezoneDateTimeToDateString } from "utils/helper";
import { ExtraFilter, ITableType } from "utils/interface";

const useStyles = makeStyles(() => ({
  datePicker: {
    zIndex: 999,
    "& .react-date-picker__wrapper": {
      borderTop: "0px",
      borderRight: "0px",
      borderLeft: "0px",
    },
  },
  container: {
    margin: "0px !important",
  },
}));

interface ExtraFilterArgs {
  tableType: ITableType;
  extraFilter: ExtraFilter;
  extraFilterChanged: any;
}

export const ExtraFilterSection = ({ tableType, extraFilter, extraFilterChanged }: ExtraFilterArgs) => {
  const classes = useStyles();

  const [extraFilterTemp, setExtraFilterTemp] = useState(extraFilter);

  useEffect(() => {
    setExtraFilterTemp(extraFilter);
  }, [extraFilter]);

  const extraFilterUpdated = useCallback(
    debounce((extraFilterInput) => {
      const timeToMaturityMinFilter = extraFilterInput?.timeToMaturityMinFilter;
      const timeToMaturityMaxFilter = extraFilterInput?.timeToMaturityMaxFilter;
      if (timeToMaturityMinFilter && timeToMaturityMaxFilter && timeToMaturityMaxFilter < timeToMaturityMinFilter)
        extraFilterInput.timeToMaturityMaxFilter = timeToMaturityMinFilter;

      extraFilterChanged(extraFilterInput);
    }, 800),
    []
  );

  useEffect(() => {
    extraFilterUpdated(extraFilterTemp);
  }, [extraFilterTemp, extraFilterUpdated]);

  return (
    <>
      {(tableType === ITableType.BLOOMBERG_INDEXES_LENDING_RATE ||
        tableType === ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE ||
        tableType === ITableType.BLOOMBERG_BOND_YIELD) && (
        <Grid container xs={12} spacing={3} className={classes.container}>
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <label>Valuation Date</label>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className={classes.datePicker}
                  format="yyyy-MM-dd"
                  maxDate={new Date()}
                  slotProps={{
                    textField: { variant: "standard" },
                  }}
                  value={dateStringToTimezoneDateTime(extraFilter?.valuationDateFilter)}
                  onChange={(date) => {
                    setExtraFilterTemp({
                      ...extraFilter,
                      valuationDateFilter: timezoneDateTimeToDateString(date),
                    });
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {tableType === ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE && (
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <InputLabel id="region-select-label" variant="standard">
                  Region
                </InputLabel>
                <Select
                  labelId="region-select-label"
                  id="region-select"
                  value={extraFilter?.regionFilter}
                  label="Region"
                  onChange={(event) => {
                    const region = event.target.value.toString();
                    setExtraFilterTemp({ ...extraFilter, regionFilter: region });
                  }}
                  variant="standard"
                >
                  <MenuItem value="">N/A</MenuItem>
                  {Object.keys(BloombergRiskFreeRateRegions).map((key) => (
                    <MenuItem value={key}>{BloombergRiskFreeRateRegions[key]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {tableType === ITableType.BLOOMBERG_BOND_YIELD && (
            <>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="region-select-label" variant="standard">
                    S&P Issuer Rating from
                  </InputLabel>
                  <Select
                    labelId="region-select-label"
                    id="region-select"
                    value={extraFilter?.ratingFromFilter}
                    label="Region"
                    onChange={(event) => {
                      const value = event.target.value.toString();
                      setExtraFilterTemp({ ...extraFilter, ratingFromFilter: value });
                    }}
                    variant="standard"
                  >
                    <MenuItem value="">N/A</MenuItem>
                    {SERVER.BOND_YIELD_RATING_RANK?.map((value, index) => (
                      <MenuItem value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="region-select-label" variant="standard">
                    S&P Issuer Rating to
                  </InputLabel>
                  <Select
                    labelId="region-select-label"
                    id="region-select"
                    value={extraFilter?.ratingToFilter}
                    label="Region"
                    onChange={(event) => {
                      const value = event.target.value.toString();
                      setExtraFilterTemp({ ...extraFilter, ratingToFilter: value });
                    }}
                    variant="standard"
                  >
                    <MenuItem value="">N/A</MenuItem>
                    {SERVER.BOND_YIELD_RATING_RANK?.map((value, index) => (
                      <MenuItem value={value}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="region-select-label">Minimum Time to Maturity (Years)</InputLabel>
                  <Input
                    type="number"
                    value={extraFilterTemp.timeToMaturityMinFilter}
                    onChange={(event) => {
                      const value = parseFloat(event.target.value);
                      setExtraFilterTemp({ ...extraFilterTemp, timeToMaturityMinFilter: value });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <FormControl fullWidth>
                  <InputLabel id="region-select-label">Maximum Time to Maturity (Years)</InputLabel>
                  <Input
                    type="number"
                    value={extraFilterTemp.timeToMaturityMaxFilter}
                    onChange={(event) => {
                      const value = parseFloat(event.target.value);
                      setExtraFilterTemp({ ...extraFilterTemp, timeToMaturityMaxFilter: value });
                    }}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};
