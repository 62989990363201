import React, { PropsWithChildren } from "react";

import Flag from "@mui/icons-material/Flag";
import Button from "@mui/material/Button";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ReportForm } from "components/ReportForm";
import { getLoginData, login } from "utils/helper";
import AvistaLogo from "../static/images/avaval.png";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        "background-color": "#F5F5F5",
      },
      "*::-webkit-scrollbar": {
        width: "6px",
        height: "10px",
      },
      "*::-webkit-scrollbar-thumb": {
        "background-color": "#000000",
      },
      a: {
        color: "#333",
      },
      body: {
        background: "#f6f6f6",
        color: "#333",
        fontSize: "14px",
      },
    },
    // root: {
    //   display: "flex"
    // },
    main: {
      display: "block",
      padding: 0,
      margin: 0,
    },
    logo: {
      background: "#fff",
      padding: "5px 15px 0 15px",
      "& img": {
        maxWidth: "220px",
      },
    },
    reportButton: {
      float: "right",
      padding: "18px 8px",
    },
    footer: {
      fontSize: "11px",
      padding: "5px 15px",
    },
  })
);

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const classes = useStyles();

  const thisYear = new Date().getFullYear();
  const [open, setOpen] = React.useState(false);
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const loginData = getLoginData();

  if (!loginData) {
    login();
    return <></>;
  }

  return (
    <>
      <div className={classes.logo}>
        <img src={AvistaLogo} alt="" />
        <Button
          className={classes.reportButton}
          onClick={() => {
            setOpen(true);
          }}
        >
          <Flag></Flag>
        </Button>
      </div>
      <ReportForm open={open} handleClose={handleClose}></ReportForm>
      <main className={classes.main}>{children}</main>
      <div className={classes.footer}>© {thisYear} AVISTA GROUP. ALL RIGHTS RESERVED.</div>
    </>
  );
};

export default Layout;
