import { GetLastUpdateQuery, GetManyBloombergBondYieldsQuery } from "generated/graphql";
import React, { useMemo } from "react";
import { getQueryVariablesBase } from "utils/helper";
import { IExportState, ITableType } from "utils/interface";

const useLastUpdate = ({
  lastUpdateLoading,
  lastUpdatehData,
}: {
  lastUpdateLoading: boolean;
  lastUpdatehData: GetLastUpdateQuery;
}) => {
  const [date, setDate] = React.useState<GetLastUpdateQuery["getLastUpdate"]>();
  React.useEffect(() => {
    if (!lastUpdateLoading && lastUpdatehData?.getLastUpdate) {
      setDate(lastUpdatehData.getLastUpdate);
    }
  }, [lastUpdateLoading, lastUpdatehData]);
  return date;
};

const useLoading = (loadingObj) => {
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    let hasLoading = false;
    for (var key of Object.keys(loadingObj)) {
      if (loadingObj[key]) {
        hasLoading = true;
      }
    }
    if (hasLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [setLoading, loadingObj]);
  return loading;
};

const useSetSearchFields = ({ columns, tableType, dispatch }) => {
  React.useEffect(() => {
    if (typeof tableType != "undefined") {
      const fields = columns[tableType].data.map((item) => {
        return {
          id: item.id,
          label: item.label,
        };
      });
      dispatch({
        type: "setSearchFields",
        payload: {
          search: {
            allSearchFields: fields,
          },
        },
      });
    }
  }, [columns, tableType, dispatch]);
};

const useInitEmptyColumnsValue = ({ columns, tableType, dispatch }) => {
  React.useEffect(() => {
    const values = {};
    columns[tableType].data.forEach((item) => {
      if (
        item.id === "IntangibleAsset1Amount" ||
        item.id === "IntangibleAsset2Amount" ||
        item.id === "IntangibleAsset3Amount" ||
        item.id === "IntangibleAsset4Amount" ||
        item.id === "GoodwillFull" ||
        item.id === "Goodwill" ||
        item.id === "IAIAGW" ||
        item.id === "GWIAGW" ||
        item.id === "IntangibleAssets"
      ) {
        values[item.id] = 0;
      } else if (item.type === "number" || item.type === "percentage") {
        values[item.id] = undefined;
      } else if (item.type === "array" || item.type === "industries" || item.type === "listedCompanies")
        values[item.id] = [];
      else {
        values[item.id] = "";
      }
    });
    dispatch({
      type: "initColumnValue",
      payload: {
        form: {
          initValues: values,
        },
      },
    });
  }, [columns, tableType, dispatch]);
};

const useGetData = ({
  table,
  selectedSearchField,
  tableType,
  researchData,
  getResearch,
  comparableData,
  getComparable,
  extraFilter,
  keyword,
  columns,
  getRoyaltyRate,
  getProprietary,
  getMarcoEconFactor,
  getRecoveryRates,
  getIndustrialFactor,
  getEffectiveInterestRates,
  getBloombergIndexesLendingRates,
  getBloombergIndexesRiskFreeRates,
  getBloombergCpis,
  getBloombergCpiAverages,
  getBloombergExchangeRates,
  getBloombergBondYields,
  getResearchReports,
}) => {
  React.useEffect(
    function getData() {
      const variablesBase = getQueryVariablesBase({
        keyword: keyword,
        rowsPerPage: table.rowsPerPage,
        page: table.page,
        orderBy: table.orderBy,
        order: table.order,
        columnsData: columns[tableType].data,
        selectedFields: selectedSearchField,
        extraFilter: extraFilter,
      });

      if (keyword !== null) {
        if (tableType === ITableType.COMPARABLE_PPA) {
          getComparable({
            variables: {
              ...variablesBase,
            },
          });
        } else if (tableType === ITableType.ROYALTY_RATE) {
          getRoyaltyRate({
            variables: {
              ...variablesBase,
            },
          });
        } else if (tableType === ITableType.PROPRIETARY_PPA) {
          getProprietary({
            variables: {
              ...variablesBase,
            },
          });
        } else if (tableType === ITableType.CIRCULAR_RESEARCH) {
          getResearch({
            variables: {
              ...variablesBase,
              year: table.year,
            },
          });
          // } else if (tableType === ITableType.MARCO_ECON_FACTOR) {
          //   getMarcoEconFactor({
          //     variables: {
          //       ...variablesBase,
          //     },
          //   });
          // } else if (tableType === ITableType.RECOVERY_RATES) {
          //   getRecoveryRates({
          //     variables: {
          //       ...variablesBase,
          //     },
          //   });
          // } else if (tableType === ITableType.INDUSTRIAL_FACTOR) {
          //   getIndustrialFactor({
          //     variables: {
          //       ...variablesBase,
          //       year: table.industrialFactorYear,
          //     },
          //   });
          // } else if (tableType === ITableType.EFFECTIVE_INTEREST_RATE) {
          //   getEffectiveInterestRates({
          //     variables: {
          //       ...variablesBase,
          //     },
          //   });
        } else if (tableType === ITableType.BLOOMBERG_INDEXES_LENDING_RATE) {
          getBloombergIndexesLendingRates({
            variables: {
              ...variablesBase,
            },
          });
        } else if (tableType === ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE) {
          getBloombergIndexesRiskFreeRates({
            variables: {
              ...variablesBase,
            },
          });
        } else if (tableType === ITableType.BLOOMBERG_CPI) {
          getBloombergCpis({
            variables: {
              ...variablesBase,
            },
          });
        } else if (tableType === ITableType.BLOOMBERG_CPI_AVERAGE) {
          getBloombergCpiAverages({
            variables: {
              ...variablesBase,
            },
          });
        } else if (tableType === ITableType.BLOOMBERG_EXCHANGE_RATE) {
          getBloombergExchangeRates({
            variables: {
              ...variablesBase,
            },
          });
        } else if (tableType === ITableType.BLOOMBERG_BOND_YIELD) {
          getBloombergBondYields({
            variables: {
              ...variablesBase,
            },
          });
        } else if (tableType === ITableType.RESEARCH_REPORT) {
          getResearchReports({
            variables: {
              ...variablesBase,
            },
          });
        }
      }
    },
    [
      table.rowsPerPage,
      table.page,
      table.orderBy,
      table.order,
      table.year,
      table.industrialFactorYear,
      selectedSearchField,
      tableType,
      getResearch,
      getComparable,
      keyword,
      columns,
      getRoyaltyRate,
      getProprietary,
      getMarcoEconFactor,
      getRecoveryRates,
      getIndustrialFactor,
      getEffectiveInterestRates,
      getBloombergIndexesLendingRates,
      getBloombergIndexesRiskFreeRates,
      getBloombergCpis,
      getBloombergCpiAverages,
      getBloombergExchangeRates,
      getBloombergBondYields,
      getResearchReports,
      extraFilter,
    ]
  );
};

const useSetData = ({
  marcoEconFactorLoading,
  marcoEconFactorData,
  IndustrialFactorLoading,
  IndustrialFactorData,
  RecoveryRatesLoading,
  RecoveryRatesData,
  royaltyRateLoading,
  royaltyRateData,
  tableType,
  comparableLoading,
  comparableData,
  researchLoading,
  researchData,
  dispatch,
  proprietaryLoading,
  proprietaryData,
  EffectiveInterestRatesLoading,
  EffectiveInterestRatesData,
  BloombergIndexesLendingRatesLoading,
  BloombergIndexesLendingRatesData,
  BloombergIndexesRiskFreeRatesLoading,
  BloombergIndexesRiskFreeRatesData,
  BloombergCpisLoading,
  BloombergCpisData,
  BloombergCpiAveragesLoading,
  BloombergCpiAveragesData,
  BloombergExchangeRatesLoading,
  BloombergExchangeRatesData,
  BloombergBondYieldsLoading,
  BloombergBondYieldsData,
  ResearchReportsLoading,
  ResearchReportsData,
}: {
  BloombergBondYieldsLoading: boolean;
  BloombergBondYieldsData: GetManyBloombergBondYieldsQuery;
} & Record<string, any>) => {
  React.useEffect(
    function setData() {
      switch (tableType) {
        case ITableType.COMPARABLE_PPA:
          if (!comparableLoading && comparableData?.getManyComparables?.total > 0) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: comparableData.getManyComparables,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }

          break;
        case ITableType.ROYALTY_RATE:
          if (!royaltyRateLoading && royaltyRateData?.getManyRoyaltyRates?.total > 0) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: royaltyRateData.getManyRoyaltyRates,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }

          break;
        case ITableType.PROPRIETARY_PPA:
          if (!proprietaryLoading && proprietaryData?.getManyProprietaries?.total > 0) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: proprietaryData.getManyProprietaries,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }

          break;
        case ITableType.CIRCULAR_RESEARCH:
          if (!researchLoading && researchData?.getManyResearches?.total > 0) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: researchData.getManyResearches,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }
          break;
        // case ITableType.MARCO_ECON_FACTOR:
        //   if (!marcoEconFactorLoading && marcoEconFactorData?.marcoEconFactors?.total > 0) {
        //     dispatch({
        //       type: "setColumnData",
        //       payload: {
        //         table: {
        //           columnData: marcoEconFactorData.marcoEconFactors,
        //         },
        //       },
        //     });
        //   } else {
        //     dispatch({
        //       type: "noColumnData",
        //     });
        //   }

        //   break;
        // case ITableType.INDUSTRIAL_FACTOR:
        //   if (!IndustrialFactorLoading && IndustrialFactorData?.industrialFactors?.total > 0) {
        //     dispatch({
        //       type: "setColumnData",
        //       payload: {
        //         table: {
        //           columnData: IndustrialFactorData.industrialFactors,
        //         },
        //       },
        //     });
        //   } else {
        //     dispatch({
        //       type: "noColumnData",
        //     });
        //   }

        //   break;
        // case ITableType.RECOVERY_RATES:
        //   if (!RecoveryRatesLoading && RecoveryRatesData?.recoveryRates?.total > 0) {
        //     dispatch({
        //       type: "setColumnData",
        //       payload: {
        //         table: {
        //           columnData: RecoveryRatesData.recoveryRates,
        //         },
        //       },
        //     });
        //   } else {
        //     dispatch({
        //       type: "noColumnData",
        //     });
        //   }

        //   break;
        // case ITableType.EFFECTIVE_INTEREST_RATE:
        //   if (!EffectiveInterestRatesLoading && EffectiveInterestRatesData) {
        //     if (
        //       EffectiveInterestRatesData.effectiveInterestRates &&
        //       EffectiveInterestRatesData.effectiveInterestRates.total > 0
        //     ) {
        //       dispatch({
        //         type: "setColumnData",
        //         payload: {
        //           table: {
        //             columnData: EffectiveInterestRatesData.effectiveInterestRates,
        //           },
        //         },
        //       });
        //     } else {
        //       dispatch({
        //         type: "noColumnData",
        //       });
        //     }
        //   }
        //   break;
        case ITableType.BLOOMBERG_INDEXES_LENDING_RATE:
          if (
            !BloombergIndexesLendingRatesLoading &&
            BloombergIndexesLendingRatesData?.getManyBloombergIndexesLendingRates?.total > 0
          ) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: BloombergIndexesLendingRatesData.getManyBloombergIndexesLendingRates,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }
          break;
        case ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE:
          if (
            !BloombergIndexesRiskFreeRatesLoading &&
            BloombergIndexesRiskFreeRatesData?.getManyBloombergIndexesRiskFreeRates?.total > 0
          ) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: BloombergIndexesRiskFreeRatesData.getManyBloombergIndexesRiskFreeRates,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }

          break;
        case ITableType.BLOOMBERG_CPI:
          if (!BloombergCpisLoading && BloombergCpisData?.getManyBloombergCpis?.total > 0) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: BloombergCpisData.getManyBloombergCpis,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }

          break;
        case ITableType.BLOOMBERG_CPI_AVERAGE:
          if (!BloombergCpiAveragesLoading && BloombergCpiAveragesData?.getManyBloombergCpiAverages?.total > 0) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: BloombergCpiAveragesData.getManyBloombergCpiAverages,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }

          break;
        case ITableType.BLOOMBERG_EXCHANGE_RATE:
          if (!BloombergExchangeRatesLoading && BloombergExchangeRatesData?.getManyBloombergExchangeRates?.total > 0) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: BloombergExchangeRatesData.getManyBloombergExchangeRates,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }

          break;
        case ITableType.BLOOMBERG_BOND_YIELD:
          if (!BloombergBondYieldsLoading && BloombergBondYieldsData?.getManyBloombergBondYields?.total > 0) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: BloombergBondYieldsData.getManyBloombergBondYields,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }
          break;

        case ITableType.RESEARCH_REPORT:
          if (!ResearchReportsLoading && ResearchReportsData?.getManyResearchReports?.total > 0) {
            dispatch({
              type: "setColumnData",
              payload: {
                table: {
                  columnData: ResearchReportsData?.getManyResearchReports,
                },
              },
            });
          } else {
            dispatch({
              type: "noColumnData",
            });
          }

          break;
        default:
          return;
      }
    },
    [
      marcoEconFactorLoading,
      marcoEconFactorData,
      IndustrialFactorLoading,
      IndustrialFactorData,
      RecoveryRatesLoading,
      RecoveryRatesData,
      royaltyRateLoading,
      royaltyRateData,
      tableType,
      comparableLoading,
      comparableData,
      researchLoading,
      researchData,
      dispatch,
      proprietaryLoading,
      proprietaryData,
      EffectiveInterestRatesLoading,
      EffectiveInterestRatesData,
      BloombergIndexesLendingRatesLoading,
      BloombergIndexesLendingRatesData,
      BloombergIndexesRiskFreeRatesLoading,
      BloombergIndexesRiskFreeRatesData,
      BloombergCpisLoading,
      BloombergCpisData,
      BloombergCpiAveragesLoading,
      BloombergCpiAveragesData,
      BloombergExchangeRatesLoading,
      BloombergExchangeRatesData,
      BloombergBondYieldsLoading,
      BloombergBondYieldsData,
      ResearchReportsLoading,
      ResearchReportsData,
    ]
  );
};

const useExportFile = ({
  state,
  dispatch,
  tableType,
  ExportBloombergIndexesLendingRatesData,
  ExportBloombergIndexesLendingRatesLoading,
  ExportBloombergIndexesRiskFreeRatesData,
  ExportBloombergIndexesRiskFreeRatesLoading,
  ExportBloombergIndexesLendingRatesError,
  ExportBloombergIndexesRiskFreeRatesError,
  ExportBloombergCpisData,
  ExportBloombergCpisLoading,
  ExportBloombergCpisError,
  ExportBloombergCpiAveragesData,
  ExportBloombergCpiAveragesLoading,
  ExportBloombergCpiAveragesError,
  ExportBloombergExchangeRatesData,
  ExportBloombergExchangeRatesLoading,
  ExportBloombergExchangeRatesError,
  ExportBloombergBondYieldsData,
  ExportBloombergBondYieldsLoading,
  ExportBloombergBondYieldsError,
}) => {
  useMemo(
    function getExportedFile() {
      if (!state.export.open) return;
      const exportState: IExportState = {
        open: true,
        loading: false,
        url: "",
        error: "",
      };

      switch (tableType) {
        case ITableType.BLOOMBERG_INDEXES_LENDING_RATE:
          if (ExportBloombergIndexesLendingRatesLoading) exportState.loading = true;
          else if (ExportBloombergIndexesLendingRatesError)
            exportState.error = ExportBloombergIndexesLendingRatesError.message;
          else if (ExportBloombergIndexesLendingRatesData) {
            const url = ExportBloombergIndexesLendingRatesData.exportManyBloombergIndexesLendingRates?.url;
            if (url) exportState.url = url;
          }
          break;
        case ITableType.BLOOMBERG_INDEXES_RISK_FREE_RATE:
          if (ExportBloombergIndexesRiskFreeRatesLoading) exportState.loading = true;
          else if (ExportBloombergIndexesRiskFreeRatesError)
            exportState.error = ExportBloombergIndexesRiskFreeRatesError.message;
          else if (ExportBloombergIndexesRiskFreeRatesData) {
            const url = ExportBloombergIndexesRiskFreeRatesData.exportManyBloombergIndexesRiskFreeRates?.url;
            if (url) exportState.url = url;
          }
          break;
        case ITableType.BLOOMBERG_CPI:
          if (ExportBloombergCpisLoading) exportState.loading = true;
          else if (ExportBloombergCpisError) exportState.error = ExportBloombergCpisError.message;
          else if (ExportBloombergCpisData) {
            const url = ExportBloombergCpisData.exportManyBloombergCpis?.url;
            if (url) exportState.url = url;
          }
          break;
        case ITableType.BLOOMBERG_CPI_AVERAGE:
          if (ExportBloombergCpiAveragesLoading) exportState.loading = true;
          else if (ExportBloombergCpiAveragesError) exportState.error = ExportBloombergCpiAveragesError.message;
          else if (ExportBloombergCpiAveragesData) {
            const url = ExportBloombergCpiAveragesData.exportManyBloombergCpiAverages?.url;
            if (url) exportState.url = url;
          }
          break;
        case ITableType.BLOOMBERG_EXCHANGE_RATE:
          if (ExportBloombergExchangeRatesLoading) exportState.loading = true;
          else if (ExportBloombergExchangeRatesError) exportState.error = ExportBloombergExchangeRatesError.message;
          else if (ExportBloombergExchangeRatesData) {
            const url = ExportBloombergExchangeRatesData.exportManyBloombergExchangeRates?.url;
            if (url) exportState.url = url;
          }
          break;
        case ITableType.BLOOMBERG_BOND_YIELD:
          if (ExportBloombergBondYieldsLoading) exportState.loading = true;
          else if (ExportBloombergBondYieldsError) exportState.error = ExportBloombergBondYieldsError.message;
          else if (ExportBloombergBondYieldsData) {
            const url = ExportBloombergBondYieldsData.exportManyBloombergBondYields?.url;
            if (url) exportState.url = url;
          }
          break;
        default:
          break;
      }
      dispatch({
        type: "updateExport",
        payload: {
          export: exportState,
        },
      });
    },
    [
      state.export.open,
      tableType,
      dispatch,
      ExportBloombergIndexesLendingRatesLoading,
      ExportBloombergIndexesLendingRatesError,
      ExportBloombergIndexesLendingRatesData,
      ExportBloombergIndexesRiskFreeRatesLoading,
      ExportBloombergIndexesRiskFreeRatesError,
      ExportBloombergIndexesRiskFreeRatesData,
      ExportBloombergCpisLoading,
      ExportBloombergCpisError,
      ExportBloombergCpisData,
      ExportBloombergCpiAveragesLoading,
      ExportBloombergCpiAveragesError,
      ExportBloombergCpiAveragesData,
      ExportBloombergExchangeRatesLoading,
      ExportBloombergExchangeRatesError,
      ExportBloombergExchangeRatesData,
      ExportBloombergBondYieldsLoading,
      ExportBloombergBondYieldsError,
      ExportBloombergBondYieldsData,
    ]
  );
};

export {
  useExportFile,
  useGetData,
  useInitEmptyColumnsValue,
  useLastUpdate,
  useLoading,
  useSetData,
  useSetSearchFields,
};
