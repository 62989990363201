const targetIndustryOptions = [
  "10101010_Oil & Gas Drillin",
  "10101020_Oil & Gas Equipment & Service",
  "10102010_Integrated Oil & Ga",
  "10102020_Oil & Gas Exploration & Productio",
  "10102030_Oil & Gas Refining & Marketin",
  "10102040_Oil & Gas Storage & Transportatio",
  "10102050_Coal & Consumable Fuel",
  "15101010_Commodity Chemical",
  "15101020_Diversified Chemical",
  "15101030_Fertilizers & Agricultural Chemical",
  "15101040_Industrial Gase",
  "15101050_Specialty Chemical",
  "15102010_Construction Material",
  "15103010_Metal & Glass Container",
  "15103020_Paper Packagin",
  "15104010_Aluminu",
  "15104020_Diversified Metals & Minin",
  "15104025_Coppe",
  "15104030_Gol",
  "15104040_Precious Metals & Mineral",
  "15104045_Silve",
  "15104050_Stee",
  "15105010_Forest Product",
  "15105020_Paper Product",
  "20101010_Aerospace & Defens",
  "20102010_Building Product",
  "20103010_Construction & Engineerin",
  "20104010_Electrical Components & Equipmen",
  "20104020_Heavy Electrical Equipmen",
  "20105010_Industrial Conglomerate",
  "20106010_Construction Machinery & Heavy Truck",
  "20106015_Agricultural & Farm Machiner",
  "20106020_Industrial Machiner",
  "20107010_Trading Companies & Distributor",
  "20201010_Commercial Printin",
  "20201050_Environmental & Facilities Service",
  "20201060_Office Services & Supplie",
  "20201070_Diversified Support Service",
  "20201080_Security & Alarm Service",
  "20202010_Human Resource & Employment Service",
  "20202020_Research & Consulting Service",
  "20301010_Air Freight & Logistic",
  "20302010_Airline",
  "20303010_Marin",
  "20304010_Railroad",
  "20304020_Truckin",
  "20305010_Airport Service",
  "20305020_Highways & Railtrack",
  "20305030_Marine Ports & Service",
  "25101010_Auto Parts & Equipmen",
  "25101020_Tires & Rubbe",
  "25102010_Automobile Manufacturer",
  "25102020_Motorcycle Manufacturer",
  "25201010_Consumer Electronic",
  "25201020_Home Furnishing",
  "25201030_Homebuildin",
  "25201040_Household Appliance",
  "25201050_Housewares & Specialtie",
  "25202010_Leisure Product",
  "25203010_Apparel, Accessories & Luxury Good",
  "25203020_Footwea",
  "25203030_Textile",
  "25301010_Casinos & Gamin",
  "25301020_Hotels, Resorts & Cruise Line",
  "25301030_Leisure Facilitie",
  "25301040_Restaurant",
  "25302010_Education Service",
  "25302020_Specialized Consumer Service",
  "25401010_Advertising (discontinued effective close of September 28, 2018",
  "25401020_Broadcasting (discontinued effective close of September 28, 2018",
  "25401025_Cable & Satellite (discontinued effective close of September 28, 2018",
  "25401030_Movies & Entertainment (discontinued effective close of September 28, 2018",
  "25401040_Publishing (discontinued effective close of September 28, 2018",
  "25501010_Distributor",
  "25502020_Internet & Direct Marketing Retai",
  "25503010_Department Store",
  "25503020_General Merchandise Store",
  "25504010_Apparel Retai",
  "25504020_Computer & Electronics Retai",
  "25504030_Home Improvement Retai",
  "25504040_Specialty Store",
  "25504050_Automotive Retai",
  "25504060_Homefurnishing Retai",
  "30101010_Drug Retai",
  "30101020_Food Distributor",
  "30101030_Food Retai",
  "30101040_Hypermarkets & Super Center",
  "30201010_Brewer",
  "30201020_Distillers & Vintner",
  "30201030_Soft Drink",
  "30202010_Agricultural Product",
  "30202030_Packaged Foods & Meat",
  "30203010_Tobacc",
  "30301010_Household Product",
  "30302010_Personal Product",
  "35101010_Health Care Equipmen",
  "35101020_Health Care Supplie",
  "35102010_Health Care Distributor",
  "35102015_Health Care  Service",
  "35102020_Health Care Facilitie",
  "35102030_Managed Health Car",
  "35103010_Health Care Technolog",
  "35201010_Biotechnolog",
  "35202010_Pharmaceutical",
  "35203010_Life Sciences Tools & Service",
  "40101010_Diversified Bank",
  "40101015_Regional Bank",
  "40102010_Thrifts & Mortgage Financ",
  "40201020_Other Diversified Financial Service",
  "40201030_Multi-Sector Holding",
  "40201040_Specialized Financ",
  "40202010_Consumer Financ",
  "40203010_Asset Management & Custody Bank",
  "40203020_Investment Banking & Brokerag",
  "40203030_Diversified Capital Market",
  "40203040_Financial Exchanges & Dat",
  "40204010_Mortgage REIT",
  "40301010_Insurance Broker",
  "40301020_Life & Health Insuranc",
  "40301030_Multi-line Insuranc",
  "40301040_Property & Casualty Insuranc",
  "40301050_Reinsuranc",
  "45101010_Internet Software & Services (discontinued effective close of September 28, 2018",
  "45102010_IT Consulting & Other Service",
  "45102020_Data Processing & Outsourced Service",
  "45102030_Internet Services & Infrastructur",
  "45103010_Application Softwar",
  "45103020_Systems Softwar",
  "45103030_Home Entertainment Software (discontinued effective close of September 28, 2018",
  "45201020_Communications Equipmen",
  "45202030_Technology Hardware, Storage & Peripheral",
  "45203010_Electronic Equipment & Instruments",
  "45203015_Electronic Component",
  "45203020_Electronic Manufacturing Service",
  "45203030_Technology Distributor",
  "45301010_Semiconductor Equipment",
  "45301020_Semiconductor",
  "50101010_Alternative Carrier",
  "50101020_Integrated Telecommunication Service",
  "50102010_Wireless Telecommunication Service",
  "50201010_Advertisin",
  "50201020_Broadcastin",
  "50201030_Cable & Satellit",
  "50201040_Publishin",
  "50202010_Movies & Entertainmen",
  "50202020_Interactive Home Entertainmen",
  "50203010_Interactive Media & Service",
  "55101010_Electric Utilitie",
  "55102010_Gas Utilitie",
  "55103010_Multi-Utilitie",
  "55104010_Water Utilitie",
  "55105010_Independent Power Producers & Energy Trader",
  "55105020_Renewable Electricity",
  "60101010_Diversified REIT",
  "60101020_Industrial REIT",
  "60101030_Hotel & Resort REITs",
  "60101040_Office REITs",
  "60101050_Health Care REITs",
  "60101060_Residential REIT",
  "60101070_Retail REIT",
  "60101080_Specialized REITs",
  "60102010_Diversified Real Estate Activities",
  "60102020_Real Estate Operating Companie",
  "60102030_Real Estate Development",
  "60102040_Real Estate Services",
];

interface IIndustry {
  code: string;
  name: string;
}

interface IIndustryOption {
  label: string;
  value: string;
  data: IIndustry;
}

const IndustryList: IIndustry[] = [
  {
    code: "01",
    name: "Information Technology - Key Player",
  },
  {
    code: "02",
    name: "Information Technology - SaaS",
  },
  {
    code: "03",
    name: "Information Technology - Cloud",
  },
  {
    code: "04",
    name: "Information Technology - Platform",
  },
  {
    code: "05",
    name: "Information Technology - E-commerce",
  },
  {
    code: "06",
    name: "Information Technology - Others",
  },
  {
    code: "07",
    name: "Media & Entertainment",
  },
  {
    code: "08",
    name: "Advanced Technology ",
  },
  {
    code: "09",
    name: "Communication Services",
  },
  {
    code: "10",
    name: "Biotechnology",
  },
  {
    code: "11",
    name: "Pharmaceutical & Healthcare",
  },
  {
    code: "12",
    name: "Utilities & Energy ",
  },
  {
    code: "13",
    name: "Real Estate Development & Property Management",
  },
  {
    code: "14",
    name: "Financial Services",
  },
  {
    code: "15",
    name: "Funds",
  },
  {
    code: "16",
    name: "Fintech",
  },
  {
    code: "17",
    name: "Travel & Tourism",
  },
  {
    code: "18",
    name: "Logistics & Transportation",
  },
  {
    code: "19",
    name: "Retail & Consumer",
  },
  {
    code: "20",
    name: "Food & Beverage",
  },
  {
    code: "21",
    name: "Industrials Materials & Construction",
  },
  {
    code: "22",
    name: "Automotive",
  },
  {
    code: "23",
    name: "Education",
  },
  {
    code: "24",
    name: "Professional Services",
  },
  {
    code: "25",
    name: "Unclassified",
  },
];

const IndustryListOptions = IndustryList.map<IIndustryOption>((industry) => ({
  value: `${industry.code} ${industry.name}`,
  label: `${industry.code} ${industry.name}`,
  data: industry,
}));

export { IndustryList, IndustryListOptions, targetIndustryOptions };
export type { IIndustry, IIndustryOption };
