import { ColumnsData } from "../../generated/graphql";

export const bloombergIndexLendingRateColumns: ColumnsData[] = [
  {
    id: "id",
    label: "Id",
    hidden: true,
  },
  {
    id: "description",
    label: "Description",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "ticker",
    label: "Ticker",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "dataValue",
    label: "Previous Value (%)",
    minWidth: 100,
    sortable: true,
    type: "number",
  },
  {
    id: "dataDate",
    label: "Previous Date (YYYY-MM-DD)",
    type: "date",
    minWidth: 150,
    sortable: true,
  },
];
