import { useCallback, useEffect, useState } from "react";

import { useApolloClient } from "@apollo/client";
import { AvistaOptionTypeBase } from "@avista/avista-select";
import { Button, InputLabel } from "@mui/material";
import { ExtractKeywordsDocument, ExtractKeywordsQuery, ExtractKeywordsQueryVariables } from "generated/graphql";
import AsyncCreatableSelect from "react-select/async-creatable";
import { IEditMode } from "../../utils/interface";

const KeywordsField = ({ formEditMode, defaultValues, handleUpdate, id, label, description, setSnackbar }) => {
  const client = useApolloClient();
  const [options, setOptions] = useState<AvistaOptionTypeBase<string>[]>([]);
  const [values, setValues] = useState<AvistaOptionTypeBase<string>[]>([]);

  useEffect(
    function initValues() {
      if (!values.length) setValues(formEditMode === IEditMode.ADD ? [] : defaultValues);
    },
    [defaultValues, formEditMode, values.length]
  );

  const onChange = useCallback(
    (selectedOptions) => {
      setValues(selectedOptions);

      let valuesInStringArray = optionToValues(selectedOptions);
      // Remove repeated
      valuesInStringArray = valuesInStringArray.filter((v, i) => valuesInStringArray.indexOf(v) === i);
      handleUpdate(valuesInStringArray);
    },
    [handleUpdate]
  );

  const optionToValues = (options: AvistaOptionTypeBase<string>[]) => {
    return options.map((option) => option.value);
  };

  const generateKeywordsButtonOnClick = useCallback(
    async (event) => {
      const ExtractKeywordsResult = await client
        .query<ExtractKeywordsQuery, ExtractKeywordsQueryVariables>({
          query: ExtractKeywordsDocument,
          variables: {
            text: description,
          },
        })
        .catch((err) =>
          setSnackbar({
            message:
              err.message === "MaxLengthExceed"
                ? "Description maximum length exceed. Maximum length is 5120 text."
                : err.message,
            type: "error",
            open: true,
          })
        );

      if (!ExtractKeywordsResult) return;

      const keywordsOptions = ExtractKeywordsResult.data.extractKeywords.map((keyword) => ({
        label: keyword,
        value: keyword,
      }));

      let newOptions = [...values, ...keywordsOptions];

      // Remove repeated
      newOptions = newOptions.filter((v, i) => newOptions.findIndex((v2) => v2.value === v.value) === i);

      setOptions(newOptions);
      onChange(newOptions);
    },
    [client, description, onChange]
  );

  const loadOptions = useCallback(async () => {
    return options;
  }, [options]);

  return (
    <>
      <InputLabel id={id + "-label"}>{label}</InputLabel>
      <Button size="small" variant="outlined" onClick={generateKeywordsButtonOnClick}>
        Auto-generate base on description
      </Button>
      <AsyncCreatableSelect
        isMulti
        name={id}
        loadOptions={loadOptions}
        value={values}
        onChange={onChange}
        placeholder={``}
        styles={{
          control: (css) => ({
            ...css,
            border: 0,
            borderBottom: "solid 1px",
            borderRadius: 0,
            boxShadow: "none",
          }),
          menu: (css) => ({
            ...css,
            zIndex: 999,
          }),
        }}
      />
    </>
  );
};

export { KeywordsField };
