import React from "react";
import { DropzoneOptions, useDropzone, Accept } from "react-dropzone";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

interface DropzoneProps {
  disabled: boolean;
  name: string;
  error: boolean;
  setFieldValue?: (name: string, value: any) => void;
  accept?: Accept;
  multiple?: boolean;
}

const useStyles = makeStyles<Theme, DropzoneProps>(({ spacing, palette }: Theme) => ({
  dropzone: {
    backgroundColor: "#FFF",
    justifyContent: "center",
    border: "dashed",
    borderColor: ({ error }) => {
      if (error) {
        return palette.error.main;
      }
      return "grey";
    },
    borderRadius: "15px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    minHeight: "100px",
    marginTop: spacing(1),
    cursor: "pointer",
    outline: 0,
  },
}));

const FileDropzone: React.FC<DropzoneProps> = (props) => {
  const { setFieldValue, disabled, name, accept, multiple } = props;
  const styles = useStyles(props);
  const [acceptedFiles, setAcceptedFiles] = React.useState<File[]>([]);
  const dropAccepted = React.useCallback<DropzoneOptions["onDropAccepted"]>((acceptFile, e) => {
    let inputFiles = acceptFile;
    setFieldValue(name, inputFiles);
    setAcceptedFiles(inputFiles);
  }, [name, setFieldValue]);
  const resetField = React.useCallback(() => {
    setFieldValue(name, []);
    setAcceptedFiles([]);
  }, [name, setFieldValue]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept || { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
    onDropAccepted: dropAccepted,
    multiple,
    disabled,
    onFileDialogCancel: resetField,
  });

  return (
    <div {...getRootProps()} className={styles.dropzone}>
      <input {...getInputProps()} name={name} />
      {!acceptedFiles.length && <Typography>Click to upload</Typography>}
      {acceptedFiles.length > 0 && (
        <Box display="flex" alignItems="center" width="100%">
          <Typography align="center" style={{ flexGrow: 1, marginRight: "-48px" }}>
            {acceptedFiles.map(file=>file.name).join(', ')}
          </Typography>
          <Box mr={1}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                resetField();
              }}
              size="large"
            >
              <ClearIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </div>
  );
};

export { FileDropzone };
