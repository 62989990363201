import React from "react";
import MaterialTable, { Icons } from "material-table";
import { forwardRef } from "react";
import { ComparableCompanyUpdateOneInput } from "../../generated/graphql";
import { IComparableCompaniesTableData, IEditMode } from "../../utils/interface";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const EditableTable = ({ formEditMode, initList, handleUpdate, parentId }) => {
  const initValues = formEditMode === IEditMode.ADD ? [] : initList;
  const columns = [
    { title: "Company", field: "company" },
    { title: "Code", field: "code" },
  ];

  const [data, setData] = React.useState<ComparableCompanyUpdateOneInput[]>(initValues);
  return (
    <MaterialTable
      icons={tableIcons}
      title=""
      columns={columns}
      data={data}
      style={{
        boxShadow: "0 0 0 0 #fff",
      }}
      options={{
        search: false,
        paging: false,
        addRowPosition: "first",
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise<void>((resolve, reject) => {
            const latestData = [
              ...data,
              {
                ...newData,
                parentId,
              },
            ];
            setData(latestData);
            handleUpdate(latestData);
            resolve();
          }),
        onRowUpdate: (newData, oldData: IComparableCompaniesTableData) =>
          new Promise<void>((resolve, reject) => {
            const dataUpdate = [...data];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            const latestData = [...dataUpdate];
            setData(latestData);
            handleUpdate(latestData);
            resolve();
          }),
        onRowDelete: (oldData: IComparableCompaniesTableData) =>
          new Promise<void>((resolve, reject) => {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            const latestData = [...dataDelete];
            setData(latestData);
            handleUpdate(latestData);
            resolve();
          }),
      }}
    />
  );
};

export default EditableTable;
