import React, { PropsWithChildren } from "react";

import { ApolloClient, ApolloProvider, InMemoryCache, from } from "@apollo/client";
import { AvistaAuthLink } from "@avista/client-auth-link";
import { createUploadLink } from "apollo-upload-client";
import Cookies from "js-cookie";
import { SERVER } from "../utils/const";

const avistaAuthLink = new AvistaAuthLink(`${SERVER.LOGIN_HOST}`, `${SERVER.APP_URL}`);

const getHttpLinkOption = () => {
  const authToken = Cookies.get(SERVER.COOKIE_NAME);
  if (authToken)
    return {
      uri: SERVER.GRAPHQL_URL,
      headers: {
        authorization: `Bearer ${authToken}`,
        "Apollo-Require-Preflight": "true",
      },
    };
  return {
    uri: SERVER.GRAPHQL_URL,
  };
};

const apolloHttpLink = createUploadLink(getHttpLinkOption());

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([avistaAuthLink.avistaLink, apolloHttpLink]),
});

const AvistaApolloProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default AvistaApolloProvider;
