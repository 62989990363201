import { useCallback } from "react";

import { useApolloClient } from "@apollo/client/react/hooks";
import InputLabel from "@mui/material/InputLabel";
import {
  GetManyListedCompaniesByKeywordDocument,
  GetManyListedCompaniesByKeywordQuery,
  GetManyListedCompaniesByKeywordQueryVariables,
} from "generated/graphql";
import AsyncSelect from "react-select/async";
import { IEditMode } from "utils/interface";

const CompaniesField = ({ formEditMode, defaultValues, handleUpdate, id, label }) => {
  const listedCompaniesToOption = (data) => {
    if (!data) return [];
    return data.map((item) => ({
      value: item.id,
      label: item.name,
      data: item,
    }));
  };

  const initValues = formEditMode === IEditMode.ADD ? [] : listedCompaniesToOption(defaultValues);

  const minimumWordsToSearch = 3;

  const client = useApolloClient();

  const onChange = (selectedOptions) => {
    handleUpdate(selectedOptions.map((opt) => opt.data));
  };

  const loadOptions = useCallback(
    async (inputValues: string) => {
      if (inputValues.length < minimumWordsToSearch) return;
      const findListedCompanyQuery = await client.query<
        GetManyListedCompaniesByKeywordQuery,
        GetManyListedCompaniesByKeywordQueryVariables
      >({
        query: GetManyListedCompaniesByKeywordDocument,
        variables: {
          keyword: inputValues,
        },
      });

      if (!findListedCompanyQuery.data?.getManyListedCompaniesByKeyword) return [];
      return listedCompaniesToOption(findListedCompanyQuery.data.getManyListedCompaniesByKeyword.data);
    },
    [client]
  );
  return (
    <>
      <InputLabel id={id + "-label"}>{label}</InputLabel>
      <AsyncSelect
        defaultValue={initValues}
        isMulti
        name={id}
        loadOptions={loadOptions}
        onChange={onChange}
        placeholder={`Please type more than ${minimumWordsToSearch} words to search`}
        styles={{
          control: (css) => ({
            ...css,
            border: 0,
            borderBottom: "solid 1px",
            borderRadius: 0,
            boxShadow: "none",
          }),
          menu: (css) => ({
            ...css,
            zIndex: 999,
          }),
        }}
      />
    </>
  );
};

export { CompaniesField };
