import { ColumnsData } from "../generated/graphql";
export const circularResearch: ColumnsData[] = [
  {
    id: "id",
    label: "Id",
    hidden: true,
  },
  {
    id: "ListCo",
    label: "ListCo",
    minWidth: 250,
    sortable: true,
  },
  {
    id: "StockCode",
    label: "Stock Code",
    minWidth: 80,
    sortable: true,
  },
  {
    id: "Target",
    label: "Target",
    minWidth: 300,
    sortable: true,
  },
  // {
  //   id: "TargetSector",
  //   label: "Target Sector",
  //   minWidth: 300,
  //   sortable: true,
  // },
  // {
  //   id: "TargetIndustry",
  //   label: "Target Industry",
  //   minWidth: 150,
  //   sortable: true,
  // },
  {
    id: "Industry",
    label: "Industry",
    minWidth: 300,
    sortable: true,
  },
  {
    id: "TargetBusiness",
    label: "Target Business",
    minWidth: 400,
  },
  {
    id: "Location",
    label: "Location",
    minWidth: 70,
    sortable: true,
  },
  {
    id: "CircularDate",
    label: "Circular Date",
    minWidth: 70,
    sortable: true,
    type: "date",
  },
  {
    id: "TransactionType",
    label: "Transaction Type",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "ValuationReport",
    label: "Valuation Report (Page of Circular)",
    minWidth: 110,
  },
  {
    id: "Valuer",
    label: "Valuer",
    minWidth: 160,
    sortable: true,
  },
  {
    id: "ValuationApproach1",
    label: "Valuation Approach1",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "ValuationApproach2",
    label: "Valuation Approach2",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "ComparableCompany",
    label: "Comparable Company",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "WACC",
    label: "WACC",
    minWidth: 60,
    sortable: true,
    type: "percentage",
  },
  {
    id: "CompanySpecificRiskPremium",
    label: "CompanySpecificRiskPremium ",
    type: "number",
    minWidth: 60,
    sortable: true,
  },

  {
    id: "LoMD1",
    label: "LoMD1",
    minWidth: 60,
    sortable: true,
    type: "percentage",
  },
  {
    id: "LoMD1Source",
    label: "LoMD1Source",
    minWidth: 60,
    sortable: true,
  },
  {
    id: "LoMD2",
    label: "LoMD2",
    minWidth: 60,
    sortable: true,
    type: "percentage",
  },
  {
    id: "LoMD2Source",
    label: "LoMD2Source",
    minWidth: 60,
    sortable: true,
  },
  {
    id: "LoCD",
    label: "LoCD",
    minWidth: 60,
    sortable: true,
    type: "percentage",
  },
  {
    id: "LoCDSource",
    label: "LoCDSource",
    minWidth: 60,
    sortable: true,
  },
  {
    id: "ControlPremium",
    label: "Control Premium",
    minWidth: 80,
    sortable: true,
    type: "percentage",
  },
  {
    id: "ControlPremiumSource",
    label: "Control Premium Source",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "Adoptedstatisticaltype",
    label: "Adopted statistical type",
    minWidth: 80,
    sortable: true,
  },

  {
    id: "MarketApproachMultiple1",
    label: "Market Approach Multiple1",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "MarketApproachMultipleResult1",
    label: "Market Approach Multiple Result 1",
    type: "number",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "MarketApproachMultiple2",
    label: "Market Approach Multiple2",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "MarketApproachMultipleResult2",
    label: "Market Approach Multiple Result 2",
    type: "number",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "MarketApproachMultiple3",
    label: "Market Approach Multiple3",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "MarketApproachMultipleResult3",
    label: "Market Approach Multiple Result 3",
    type: "number",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "MarketApproachMultiple4",
    label: "Market Approach Multiple4",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "MarketApproachMultipleResult4",
    label: "Market Approach Multiple Result 4",
    type: "number",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "SelectedComparableCompaniesStockCode",
    label: "Selected Comparable Companies  & Stock Code",
    minWidth: 280,
    sortable: true,
  },
  {
    id: "FinancialAdvisor",
    label: "Financial Advisor",
    minWidth: 120,
    sortable: true,
  },
  {
    id: "IndependentFinancialAdvisor",
    label: "Independent Financial Advisor",
    minWidth: 180,
    sortable: true,
  },
  {
    id: "Remark",
    label: "Remark",
    minWidth: 180,
  },
  {
    id: "Link",
    label: "Link",
    type: "link",
  },
];
